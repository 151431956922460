import gql from 'graphql-tag';
import {
  MESSAGE_FILE_CONTENT_FRAGMENT,
  MESSAGE_FRAGMENT,
  QUOTE_MESSAGE_FRAGMENT,
} from '../message/fragment';
import { USER_BASIC_FRAGMENT } from '../User/fragment';
import {
  TOPIC_FRAGMENT,
  TOPIC_NOTIFICATIONS_FRAGMENT,
  TOPIC_PARTICIPANT_FRAGMENT,
  TOPIC_PERMISSION_FRAGMENT,
} from './fragment';

export const GET_TOPIC_UNREAD_COUNT = gql`
  query getTopicUnreadCount($topicId: ID!) {
    topic(id: $topicId) {
      ...TopicNotificationsFragment
    }
  }
  ${TOPIC_NOTIFICATIONS_FRAGMENT}
`;

export const QUERY_GET_PRIVATES_TOPICS = gql`
  query QueryGetPrivatesTopics(
    $placeId: ID!
    $limit: Int
    $offset: Int
    $selectedTopic: ID
    $direction: String
    $since: DateTime
    $isInRecycle: Boolean
    $unreadFilter: Boolean
    $filter: FolderTopicsFilter
  ) {
    privatesDiscussion(
      placeId: $placeId
      limit: $limit
      offset: $offset
      selectedTopic: $selectedTopic
      direction: $direction
      since: $since
      isInRecycle: $isInRecycle
      unreadFilter: $unreadFilter
      filter: $filter
    ) {
      privatesDiscussions {
        ...topicInfos
      }
      total {
        totalBefore
        totalAfter
        total
      }
    }
  }
  ${TOPIC_FRAGMENT}
`;
export const QUERY_GET_FOLLOWS_TOPICS = gql`
  query QueryGetFollowsTopics(
    $placeId: ID!
    $limit: Int
    $offset: Int
    $selectedTopic: ID
    $direction: String
    $since: DateTime
    $isInRecycle: Boolean
    $unreadFilter: Boolean
    $filter: FolderTopicsFilter
  ) {
    followsDiscussions(
      placeId: $placeId
      limit: $limit
      offset: $offset
      selectedTopic: $selectedTopic
      direction: $direction
      since: $since
      isInRecycle: $isInRecycle
      unreadFilter: $unreadFilter
      filter: $filter
    ) {
      followsDiscussions {
        ...topicInfos
      }
      total {
        totalBefore
        totalAfter
        total
      }
    }
  }
  ${TOPIC_FRAGMENT}
`;

export const GET_TOPIC_LOCKED_BY = gql`
  query GetTopicLockedBy($topicId: ID!) {
    topic(id: $topicId) {
      id
      lockedBy {
        ...userBasicInfos
      }
    }
  }
  ${USER_BASIC_FRAGMENT}
`;

export const GET_ALL_VERSIONS_DISCUSSION = gql`
  query GetAllVersionsDiscussion(
    $discussionId: ID!
    $limit: Int
    $offset: Int
  ) {
    allDiscussionsVersions(
      discussionId: $discussionId
      limit: $limit
      offset: $offset
    ) {
      data {
        ...messageFileInfos
        message {
          id
          date
          type
          discussion {
            id
            title
            fileLockedId
            folder {
              id
              name
            }
          }
          user {
            id
            firstName
            lastName
            avatar
            email
          }
        }
        version
      }
      recordsTotal
    }
  }
  ${MESSAGE_FILE_CONTENT_FRAGMENT}
`;
export const GET_TOPIC_MESSAGES = gql`
  query GetTopicMessages(
    $topicId: ID!
    $since: DateTime
    $limit: Int
    $offset: Int
    $lastVersionOnly: Boolean
  ) {
    topic(id: $topicId, since: $since) {
      id
      updatedAt
      nbMessages
      hasTextMessage
      owner {
        #TODO: move this in another consumer
        id
        firstName
        lastName
        email
        avatar
      }
      lastMessage {
        ...messageInfos
      }
      messages(
        since: $since
        limit: $limit
        offset: $offset
        lastVersionOnly: $lastVersionOnly
      ) {
        ...messageInfos
        ...quoteMessageInfos
      }
    }
  }
  ${MESSAGE_FRAGMENT}
  ${QUOTE_MESSAGE_FRAGMENT}
`;

export const GET_TOPIC_PARTICIPANTS_SEEN = gql`
  query GetTopicParticipantsSeen($topicId: ID!, $since: DateTime) {
    topic(id: $topicId, since: $since) {
      id
      participantsSeen {
        ...topicParticipantInfos
      }
    }
  }
  ${TOPIC_PARTICIPANT_FRAGMENT}
`;

export const GET_ORPHAN_TOPIC_PARTICIPANTS = gql`
  query ORPHAN_TOPIC_PARTICIPANTS($topicId: ID!, $since: DateTime) {
    topic(id: $topicId, since: $since) {
      id
      title
      removed
      myTopicRole
      topicPermissions {
        ...topicPermissionInfos
      }
    }
  }
  ${TOPIC_PERMISSION_FRAGMENT}
`;

export const GET_TOPIC_USERS_TYPING = gql`
  query getTopicUsersTyping($topicId: ID!) {
    topic(id: $topicId) {
      id
      usersTyping {
        ...userBasicInfos
      }
    }
  }
  ${USER_BASIC_FRAGMENT}
`;

export const GET_SHARED_TOPIC = gql`
  query GetSharedTopic($id: ID!) {
    getSharedTopic(id: $id)
  }
`;

export const QUERY_GET_TOPIC_INFOS = gql`
  query QueryGetTopicInfos($topicId: ID!) {
    topic(id: $topicId) {
      ...topicInfos
      # Dont add field here
    }
  }
  ${TOPIC_FRAGMENT}
`;
export const QUERY_GET_TOPIC_LAST_FILE = gql`
  query QueryGetTopicLastFile($topicId: ID!) {
    topic(id: $topicId) {
      id
      lastFile {
        ...messageFileInfos
      }
    }
  }
  ${MESSAGE_FILE_CONTENT_FRAGMENT}
`;

export const QUERY_GET_FAVORITES_TOPICS = gql`
  query QueryGetFavoritesTopics(
    $placeId: ID!
    $limit: Int
    $offset: Int
    $selectedTopic: ID
    $direction: String
    $since: DateTime
    $unreadFilter: Boolean
    $filter: FolderTopicsFilter
  ) {
    favoritesDiscussion(
      placeId: $placeId
      limit: $limit
      offset: $offset
      selectedTopic: $selectedTopic
      direction: $direction
      since: $since
      unreadFilter: $unreadFilter
      filter: $filter
    ) {
      favoritesDiscussions {
        ...topicInfos
      }
      total {
        totalBefore
        totalAfter
        total
      }
    }
  }
  ${TOPIC_FRAGMENT}
`;
