import { DataProxy } from 'apollo-cache';
import { compareAsc } from 'date-fns';
import {
  GET_FOLDER_TOPICS_BY_PAGE,
  GET_FOLDER_TOPICS_REMOVED_BY_PAGE,
} from '../gql/folder/queries';
import { QueryGetFolderTopicsByPage } from '../gql/folder/types/QueryGetFolderTopicsByPage';
import { QueryGetFolderTopicsRemovedByPage } from '../gql/folder/types/QueryGetFolderTopicsRemovedByPage';
import { GET_FIRST_SELECTED_TOPIC } from '../gql/local/location/query';
import { TOPIC_FRAGMENT } from '../gql/topic/fragment';
import { GET_TOPIC_MESSAGES } from '../gql/topic/query';
import { GetTopicMessagesVariables } from '../gql/topic/types/GetTopicMessages';
import { topicPermissionInfos } from '../gql/topic/types/topicPermissionInfos';
import FilterTopicType from '../types/FilterTopicType';
import FirstSelectedTopicInterface, {
  FirstSelectedTopicQuery,
} from '../types/FirstSelectedTopicInterface';
import { PermissionType } from '../types/graphql-global-types';
import { TopicInfos } from '../types/TopicInterface';
import { UserBasicInfos } from '../types/UserInterface';
import { limit as wLimit } from './constUtils';
import { getLocationPath } from './location/locationUtils';

export const getTopicPermissionNotReadonlyUsers = (
  topicPermissions: topicPermissionInfos[]
): UserBasicInfos[] => {
  const notReadOnlyPermissions = topicPermissions.filter(
    (permission) =>
      permission.role &&
      permission.role.name &&
      permission.role.name !== PermissionType.reader &&
      permission.user
  );
  return notReadOnlyPermissions.map((permission) => permission.user);
};

export const getTopicParticipants = (
  topicPermissions: topicPermissionInfos[]
): UserBasicInfos[] => {
  if (!topicPermissions || !Array.isArray(topicPermissions)) {
    return [];
  }
  const notReadOnlyPermissions = topicPermissions.filter(
    (permission) => permission.user
  );
  return notReadOnlyPermissions.map((permission) => permission.user);
};

export const discussionDataQueryVariable = (
  topicId: number | string,
  limit?: number,
  offset?: number
) => {
  const queryVariable: DataProxy.Query<GetTopicMessagesVariables> = {
    query: GET_TOPIC_MESSAGES,
    variables: {
      topicId: topicId.toString(),
      limit: limit || 12,
      offset: offset || 0,
    },
  };
  return queryVariable;
};

/**
 * Get GET_DISCUSSION_DATA query variable
 * @param param0
 */
export const getDiscussionDataQueryVariableWithMessageByVersionFn = ({
  topicId,
  limit,
  offset,
}: GetTopicMessagesVariables) => {
  const queryVariable: DataProxy.Query<GetTopicMessagesVariables> = {
    query: GET_TOPIC_MESSAGES,
    variables: {
      topicId: topicId.toString(),
      limit,
      offset,
    },
  };
  return queryVariable;
};

// Get first selected topic
export const getFirstSelectedTopicIdFn = (
  cache: DataProxy,
  folderId: 'favorite' | number | null,
  isInRecycle?: boolean
): string | null => {
  const firstSelectedData = cache.readQuery<FirstSelectedTopicQuery>({
    query: GET_FIRST_SELECTED_TOPIC,
  });

  if (firstSelectedData && firstSelectedData.firstSelectedTopic) {
    const { folderTopics } = firstSelectedData.firstSelectedTopic;
    const selectedTopic = folderTopics.find(
      (t) =>
        t.folderId === folderId &&
        Boolean(isInRecycle) === Boolean(t.isInRecycle)
    );
    if (selectedTopic) {
      return selectedTopic.topicId.toString();
    }
  }
  return null;
};

// Get first selected topic
export const setFirstSelectedTopicIdFn = (
  cache: DataProxy,
  folderId: 'favorite' | number,
  topicId: number,
  isInRecycle?: boolean
) => {
  const firstSelectedTopicData = cache.readQuery<FirstSelectedTopicQuery>({
    query: GET_FIRST_SELECTED_TOPIC,
  });

  if (firstSelectedTopicData) {
    const {
      firstSelectedTopic: { folderTopics },
    } = firstSelectedTopicData;

    const diff = folderTopics.filter(
      (f) =>
        !(
          f.folderId === folderId &&
          Boolean(isInRecycle) === Boolean(f.isInRecycle)
        )
    );

    cache.writeQuery<FirstSelectedTopicQuery>({
      query: GET_FIRST_SELECTED_TOPIC,
      data: {
        firstSelectedTopic: {
          folderTopics: [
            {
              __typename: 'folderTopicSelected',
              folderId,
              topicId,
              isInRecycle: Boolean(isInRecycle),
            },
            ...diff,
          ],
          __typename: 'firstSelectedTopic',
        },
      },
    });
  }
};

/**
 * Check if topic is already loaded in query
 */
export const checkIfTopicIsAlreadyLoadedFn = (
  folderId: number,
  topicId: number,
  isInRecycle: boolean,
  cache: DataProxy
): boolean => {
  const selectedTopic = getFirstSelectedTopicIdFn(
    cache,
    +folderId,
    isInRecycle
  );
  if (!isInRecycle) {
    const folderTopicData = cache.readQuery<QueryGetFolderTopicsByPage>({
      query: GET_FOLDER_TOPICS_BY_PAGE,
      variables: {
        folderId: folderId.toString(),
        limit: wLimit(),
        offset: 0,
        selectedTopic,
      },
    });
    return Boolean(
      folderTopicData &&
        folderTopicData.folder &&
        folderTopicData.folder.topics.some((t) => +t.id === +topicId)
    );
  }
  const folderTopicData = cache.readQuery<QueryGetFolderTopicsRemovedByPage>({
    query: GET_FOLDER_TOPICS_REMOVED_BY_PAGE,
    variables: {
      folderId: folderId.toString(),
      limit: wLimit(),
      offset: 0,
      selectedTopic,
    },
  });
  return Boolean(
    folderTopicData &&
      folderTopicData.folder &&
      folderTopicData.folder.removedTopics.some((t) => +t.id === +topicId)
  );
};

/**
 * Force topic to be loaded
 */
export const ensureTopicToBeLoadedByFirstTopicFn = (
  cache: DataProxy,
  folderId: number,
  topicId: number,
  isInRecycle?: boolean
) => {
  if (
    !checkIfTopicIsAlreadyLoadedFn(
      folderId,
      topicId,
      isInRecycle || false,
      cache
    )
  ) {
    setFirstSelectedTopicIdFn(cache, folderId, topicId, isInRecycle);
  }
};

/**
 * Get first folder selected topic on load
 */
export const getFirstSelectedFolderTopicOnLoadFn =
  (): FirstSelectedTopicInterface => {
    const { folderId, isInRecycle, topicId, isFavorite } = getLocationPath();
    // TODO: favorite folder
    if ((folderId || isFavorite) && topicId) {
      return {
        folderTopics: [
          {
            folderId: folderId ? +folderId : 'favorite',
            topicId,
            isInRecycle: Boolean(isInRecycle),
            __typename: 'folderTopicSelected',
          },
        ],
        __typename: 'firstSelectedTopic',
      };
    }
    return {
      folderTopics: [],
      __typename: 'firstSelectedTopic',
    };
  };

export const sortTopicFn =
  (filter: FilterTopicType = 'DateDesc', pathname?: string) =>
  (topic1: TopicInfos | any, topic2: TopicInfos | any) => {
    const pin1 = topic1.pined || 0;
    const pin2 = topic2.pined || 0;
    const testPin =
      !pathname ||
      (pathname &&
        !pathname.includes('/activity/') &&
        !pathname.includes('/todo/') &&
        !pathname.includes('/following/'));

    if (testPin && (pin1 || pin2)) {
      return pin2 - pin1;
    }

    const time1 = topic1.updatedAt || topic1.time;
    const time2 = topic2.updatedAt || topic2.time;

    const date1 = new Date(time1);
    const date2 = new Date(time2);

    return (filter === 'DateAsc' ? 1 : -1) * compareAsc(date1, date2);
  };

/**
 * Get topic fragment variable ( user for client.readFragment )
 */
export const getReadFragmentTopicVariableFn = (
  topicId: string
): DataProxy.Fragment<unknown> => ({
  fragment: TOPIC_FRAGMENT,
  id: topicId,
  fragmentName: 'topicInfos',
});
