import gql from 'graphql-tag';

export const EDIT_USER_PROFILE = gql`
  mutation EditUserProfile(
    $firstname: String
    $lastname: String
    $avatar: String
    $skype: String
    $phone: String
    $company: String
  ) {
    editUserProfil(
      firstname: $firstname
      lastname: $lastname
      avatar: $avatar
      skype: $skype
      phone: $phone
      company: $company
    ) {
      id
      firstName
      lastName
      avatar
      email
      skype
      phone
      company
    }
  }
`;

export const UPDATE_USER_LANGAGE = gql`
  mutation UpdateUserLanguage($language: String!, $timezone: String!) {
    updateUserLanguage(language: $language, timezone: $timezone) {
      id
      language
      timezone
    }
  }
`;

export const UPDATE_ON_BOARDING = gql`
  mutation UpdateRobyOnBoarding($nextStep: Int!) {
    updateRobyOnBoarding(nextStep: $nextStep) {
      id
      onBoardingStep
    }
  }
`;

export const UPDATE_USER_THEME = gql`
  mutation MutationUpdateUserTheme($theme: String!) {
    updateUserTheme(theme: $theme) {
      id
      theme
    }
  }
`;

export const UPDATE_ACTIVITY_CARD = gql`
  mutation MutationUpdateActivityLog($step: Int!, $value: Boolean!) {
    updateStepActive(step: $step, value: $value) {
      id
    }
  }
`;

export const UPDATE_DON_T_SHOW_AGAIN_MOVE_TOPIC = gql`
  mutation DontShowAgainMoveTopic($value: Boolean!) {
    dontShowAgainMoveTopicConfirmation(value: $value) {
      success
      action
      message
    }
  }
`;

export const UPDATE_AFTER_SIGN_UP = gql`
  mutation UpdateAfterSignup {
    updateAfterSignup {
      id
      isAfterSignup
    }
  }
`;

export const UPDATE_USER_VIEW = gql`
  mutation UpdateUserView($isSimplifiedViewEnabled: Int!) {
    updateUserView(isSimplifiedViewEnabled: $isSimplifiedViewEnabled) {
      id
      useSimplifiedView
    }
  }
`;

export const UPDATE_USER_FILES_DISPLAY = gql`
  mutation MutationUpdateUserFilesDisplay($isShowAllFilesEnabled: Int!) {
    updateUserFilesDisplay(isShowAllFilesEnabled: $isShowAllFilesEnabled) {
      id
      showAllFiles
    }
  }
`;

export const MUTATION_UPDATE_USER_FAVORITES_DISPLAY = gql`
  mutation MutationUpdateUserFavoritesDisplay($isShowFavoritesEnables: Int!) {
    updateUserFavoritesDisplay(
      isShowFavoritesEnables: $isShowFavoritesEnables
    ) {
      id
      showFavorites
    }
  }
`;

export const MUTATION_UPDATE_USER_PICTO_FOLDERS = gql`
  mutation updateUserPictoFolders($isShowPictoFolders: Int!) {
    userPictoFolders(isShowPictoFolders: $isShowPictoFolders) {
      id
      showPictoFolders
    }
  }
`;

export const MUTATION_UPDATE_SHOW_SEARCH_FOLDERS = gql`
  mutation MutationUpdateShowSearchFolders($isShowSearchFolder: Boolean!) {
    updateUserSearchFolders(isShowSearchFolders: $isShowSearchFolder) {
      id
      showSearchFolders
    }
  }
`;

export const MUTATION_UPDATE_USER_SHOW_FOLDER_IN_TOPICS = gql`
  mutation MutationUpdateUserShowFolderInTopics($showFolderInTopics: Boolean!) {
    updateUserShowFolderInTopics(showFolderInTopics: $showFolderInTopics) {
      id
      showFolderInTopicsColumn
    }
  }
`;

export const MUTATION_SET_FOLDERS_VIEW_TO_FLAT = gql`
  mutation MutationSetFoldersViewToFlat($isFlat: Boolean!) {
    setFoldersViewToFlat(isFlat: $isFlat) {
      id
      isFlat
    }
  }
`;

export const MUTATION_UPDATE_ONLINE_EDITOR = gql`
  mutation MutationUpdateOnlineEditor($value: Boolean!) {
    updateUserOnlineEditor(value: $value) {
      id
      useOnlineEditor
    }
  }
`;

export const MUTATION_AFTER_DESKTOP_APP_DOWNLOAD = gql`
  mutation MutationAfterDesktopAppDownload {
    afterDesktopAppDownload
  }
`;

export const MUTATION_UPDATE_MODAL_HELP_SHARED_VERSION_VIEWED = gql`
  mutation MutationUpdateModalHelpSharedVersionViewed {
    updateModalHelpSharedVersionViewed {
      id
      modalHelpSharedVersionAlreadyViewed
    }
  }
`;

export const MUTATION_SET_FILE_LOCKED = gql`
  mutation MutationSetFileLocked($discussionId: ID!) {
    setFileLocked(discussionId: $discussionId) {
      id
      hasFileLocked
    }
  }
`;

export const MUTATION_CHANGE_ACTIVATION_GDRIVE = gql`
  mutation MutationChangeActivationGDrive($userId: ID!, $value: Int!) {
    changeActivationGDrive(userId: $userId, value: $value) {
      id
      isGoogleDriveActive
    }
  }
`;
export const MUTATION_CHANGE_ACTIVATION_NEW_BUTTON = gql`
  mutation MutationChangeActivationNewButton($userId: ID!, $value: Int!) {
    changeActivationNewButton(userId: $userId, value: $value) {
      id
      isNewButtonActive
    }
  }
`;

export const MUTATION_TOGGLE_WIKI_ENABLED = gql`
  mutation MutationToggleWikiEnabled($userId: ID!, $value: Boolean!) {
    toggleWikiEnabled(userId: $userId, value: $value) {
      id
      isWikiActive
    }
  }
`;

export const MUTATION_UPDATE_MODAL_HELP_SHARE_FILE = gql`
  mutation mutationUpdateModalHelpShareFile($value: Int!) {
    updateHelpSharedFileView(value: $value)
  }
`;

export const MUTATION_ML_ACCOUNT_COMPLETION_DISPLAYED = gql`
  mutation MutationMagicLinkAccountCompletionDisplayed {
    magicLinkAccountCompletionDisplayed {
      id
      magicLinkAccountCompletionDisplayed
    }
  }
`;

export const MUTATION_UPDATE_INVITATION_MESSAGE = gql`
  mutation MutationUpdateInvitationMessage($message: String) {
    updateInvitationMessage(message: $message) {
      id
      customInvitationMessage
    }
  }
`;

export const MUTATION_TOGGLE_NOTIFICATION_REDIS_DISABLED = gql`
  mutation MutationToggleNotificationRedisDisabled(
    $userId: ID!
    $value: Boolean!
  ) {
    toggleNotificationRedisDisabled(userId: $userId, value: $value) {
      id
      notificationsInRedis
    }
  }
`;

export const MUTATION_SET_HELP_TOUR_DONE = gql`
  mutation MutationSetHelpTourDone {
    setHelpTourDone {
      id
      hasDoneHelpTour
    }
  }
`;

export const MUTATION_UPDATE_NOTIFICATION_SOUNDS = gql`
  mutation UpdateNotificationSounds($value: Boolean!) {
    updateNotificationSounds(value: $value) {
      id
      useNotificationSounds
    }
  }
`;
