import React, { FC, memo, Suspense } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { SharePlaceLoading } from '../../commons/SharePlaceLoading';

export interface ProtectedRouteProps extends RouteProps {
  fallbackComponent?: FC;
}

const defaultFallback = (
  <SharePlaceLoading
    style={{
      width: '100vw',
      height: '100vh',
    }}
  />
);

const ProtectedRoute: FC<ProtectedRouteProps> = memo((props) => {
  const { component, fallbackComponent } = props;
  const FallbackComponent = fallbackComponent || defaultFallback;

  return (
    <Suspense fallback={FallbackComponent}>
      <Route {...props} component={component} />
    </Suspense>
  );
});

export default ProtectedRoute;
