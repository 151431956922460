import gql from 'graphql-tag';

export const GET_HELP_TOUR = gql`
  query GetHelpTour {
    currentHelpTourStep @client {
      currentStep
      doneSteps
      helpTourTopicId
      paused
      later
    }
  }
`;
