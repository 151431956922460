import { getTime } from 'date-fns';
import { StepTourType } from '../types/StepTourType';

/**
 * Transform step to int
 */
export const stepTourToIntFn = (step: StepTourType): number => {
  switch (step) {
    case 'start':
      return 0;
    case 'importFile1':
      return 1;
    case 'importFile2':
      return 2;
    case 'fileImportation':
      return 3;
    case 'fileImported':
      return 4;
    case 'shareFile1':
      return 5;
    case 'shareFile2':
      return 6;
    case 'fileShared':
      return 7;
    case 'discussFile':
      return 8;
    case 'fileDiscussion':
      return 9;
    case 'end':
      return 10;
    default:
      return -1;
  }
};

/**
 * Transform int to step
 */
export const stepTourIntToStepFn = (step: number): StepTourType => {
  switch (step) {
    case 0:
      return 'start';
    case 1:
      return 'importFile1';
    case 2:
      return 'importFile2';
    case 3:
      return 'fileImportation';
    case 4:
      return 'fileImported';
    case 5:
      return 'shareFile1';
    case 6:
      return 'shareFile2';
    case 7:
      return 'fileShared';
    case 8:
      return 'discussFile';
    case 9:
      return 'fileDiscussion';
    case 10:
      return 'end';
    default:
      return 'start';
  }
};

// FakePlace
export const helpTourFakePlace = {
  __typename: 'Place',
  id: '0',
  name: 'LMVH',
  unread: 0,
  admin: null,
  adminId: null,
  boxAccessToken: null,
  boxFolderId: null,
  boxStorage: null,
  created_at: getTime(new Date()),
  email: null,
  hasDueDate: false,
  hasMention: false,
  header_color: 'red',
  header_image: null,
  header_image_id: null,
  icon: 'home',
  icon_color: 'blue',
  icon_id: null,
  isFavoriteFolderHidden: false,
  isFavoritePlace: false,
  isFolderMuteHidden: true,
  isNewUser: false,
  isNotifCommentEnable: false,
  isNotifFileEnable: false,
  isNotifOnInviteToFolderEnable: false,
  isNotifOperationEnable: false,
  isNotifTopicEnable: false,
  isOwner: true,
  isTraceable: false,
  onBoardingStep: null,
  orderPlace: null,
  ownerId: null,
  sortDiscussionType: null,
  folders: [],
  isContainsDeletedFolder: false,
};
