import gql from 'graphql-tag';

export const FOLDER_FRAGMENT = gql`
  fragment folderInfos on Folder {
    id
    name
    removed
    isFavorite
    hasLockedFile
    path
    created_at
    updated_at
    isNewUser
    myRole
    placeId
    parentId
    isDefaultFolder
    isMute
    isNewFolder
    description
    unread
    hasMention
  }
`;

export const MOVED_FOLDER_FRAGMENT = gql`
  fragment MovedFolderFragment on Folder {
    id
    parentId
    path
    removed
    updated_at
  }
`;

export const FOLDER_NOTIFICATIONS_FRAGMENT = gql`
  fragment FolderNotificationsFragment on Folder {
    id
    unread
    hasMention
    isNewUser
  }
`;
