export const typeDefs = `
type UploadChatLoading {
    id: Int!
    progress: Int
}
type UploadTopicLoading {
    id: Int!
    progress: Int
    filesToSendCount: Int
    filesSentCount: Int
}
type GetFolderAddDialog {
    open: Boolean!
    parentId: Int
}

type GetFolderAddParticipantDialog {
    open: Boolean!
    parentId: Int
}

type TopicParticipation {
    id: Int!
    isParticipating: Boolean
}

type FetchMoreFolderLoading {
    id: Int!
    isLoading: Boolean!
}

type Mutation {
    addUploadChatLoading(id: Int!, progress: Int, action: String): UploadChatLoading
    addUploadTopicLoading(id: Int!, progress: Int, action: String, filesToSendCount: Int, filesSentCount: Int): UploadTopicLoading
    setSnackbar(open: Boolean!, duration: Int, type: String, message: String, vertical: String, horizontal: String)
    showSidebar(open: Boolean!)
    setFolderInviteParticipantDialog(open: Boolean!, parentId: Int)
    addOrRemoveFoldersLoading: (folderId: Int!, isAdd: Boolean!)
    addTopicParticipation(id: Int!, isParticipating: Boolean)
    setFetchMoreFolderLoading(id: Int!, isInRecycle: Boolean!)
}

type Query {
    uploadTopicLoading(id: Int!): UploadChatLoading
    currentChatUploads: [UploadChatLoading]
    currentTopicUploads: [UploadTopicLoading]
    folderAddDialog: GetFolderAddDialog
    folderInviteParticipantDialog: GetFolderAddParticipantDialog
}
`;
