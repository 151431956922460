import gql from 'graphql-tag';
import { PLACE_FRAGMENT } from '../place/fragment';
import {
  TOPIC_FRAGMENT,
  TOPIC_NOTIFICATIONS_FRAGMENT,
} from '../topic/fragment';
import {
  USER_BASIC_FRAGMENT,
  USER_NAME_BASIC_FRAGMENT,
} from '../User/fragment';
import { FOLDER_FRAGMENT, FOLDER_NOTIFICATIONS_FRAGMENT } from './fragment';

export const GET_PLACE_PARTICIPANTS = gql`
  query GetPlaceParticipants($placeId: ID!) {
    place(id: $placeId) {
      id
      participants {
        isLive
        ...userBasicInfos
      }
    }
  }
  ${USER_BASIC_FRAGMENT}
`;

export const QUERY_GET_FOLDER_INFOS = gql`
  query QueryGetFolderInfos($folderId: ID!) {
    folder(id: $folderId) {
      ...folderInfos
      # Dont add field here
    }
  }
  ${FOLDER_FRAGMENT}
`;

export const QUERY_GET_FOLDER_UTIL_INFOS = gql`
  query QueryGetFolderUtilInfos($folderId: ID!) {
    folder(id: $folderId) {
      id
      name
      path
      isNewUser
      myRole
      description
      created_at
      hasLockedFile
      placeId
      isMute
      removed
      isFavorite
    }
  }
`;

export const GET_FOLDER_UNREAD = gql`
  query GetFolderUnread($folderId: ID!) {
    folder(id: $folderId) {
      ...FolderNotificationsFragment
    }
  }
  ${FOLDER_NOTIFICATIONS_FRAGMENT}
`;

export const GET_FOLDER = gql`
  query GetFolder($folderId: ID!, $since: DateTime) {
    folder(id: $folderId, since: $since) {
      ...folderInfos
      participants {
        ...userNameBasicInfos
      }
    }
  }
  ${FOLDER_FRAGMENT}
  ${USER_NAME_BASIC_FRAGMENT}
`;

export const GET_PUBLIC_FOLDER = gql`
  query GetPublicFolder($folderId: ID!) {
    publicFolder(id: $folderId) {
      id
      owner {
        id
        firstName
        lastName
        email
      }
      name
    }
  }
`;

export const GET_FOLDER_PARTICIPANTS = gql`
  query GetFolderParticipants($folderId: ID!) {
    folder(id: $folderId) {
      id
      myRole
      participants {
        ...userBasicInfos
      }
    }
  }
  ${USER_BASIC_FRAGMENT}
`;

export const GET_FOLDER_TOPICS = gql`
  query GetFolderTopics($folderId: ID!, $since: DateTime) {
    folder(id: $folderId) {
      id
      myRole
      placeId
      topics(since: $since) {
        ...topicInfos
      }
      removedTopics(since: $since) {
        ...topicInfos
      }
    }
  }
  ${TOPIC_FRAGMENT}
`;

export const GET_FOLDER_TOPICS_BY_PAGE = gql`
  query QueryGetFolderTopicsByPage(
    $folderId: ID!
    $offset: Int!
    $limit: Int!
    $selectedTopic: ID
    $direction: String
    $since: DateTime
    $topicsIds: [Int!]
    $disableLoadRecent: Boolean
    $filter: FolderTopicsFilter
  ) {
    folder(id: $folderId) {
      id
      myRole
      placeId
      unread
      hasMention
      totalTopics(selectedTopic: $selectedTopic) {
        totalBefore
        totalAfter
        total
      }
      topics(
        offset: $offset
        limit: $limit
        selectedTopic: $selectedTopic
        direction: $direction
        since: $since
        topicsIds: $topicsIds
        disableLoadRecent: $disableLoadRecent
        filter: $filter
      ) {
        ...topicInfos
      }
    }
  }
  ${TOPIC_FRAGMENT}
`;

export const GET_FOLDER_TOPICS_REMOVED_BY_PAGE = gql`
  query QueryGetFolderTopicsRemovedByPage(
    $folderId: ID!
    $offset: Int!
    $limit: Int!
    $selectedTopic: ID
    $direction: String
    $since: DateTime
    $topicsIds: [Int!]
    $disableLoadRecent: Boolean
  ) {
    folder(id: $folderId) {
      id
      myRole
      placeId
      totalRemovedTopics(selectedTopic: $selectedTopic) {
        totalBefore
        totalAfter
        total
      }
      removedTopics(
        offset: $offset
        limit: $limit
        selectedTopic: $selectedTopic
        direction: $direction
        since: $since
        topicsIds: $topicsIds
        disableLoadRecent: $disableLoadRecent
      ) {
        ...topicInfos
      }
    }
  }
  ${TOPIC_FRAGMENT}
`;

export const GET_TOPICS_UNREAD_NOTIFICATION = gql`
  query GetTopicsUnreadNotification($folderId: ID!) {
    folder(id: $folderId) {
      id
      topics {
        ...TopicNotificationsFragment
      }
    }
  }
  ${TOPIC_NOTIFICATIONS_FRAGMENT}
`;

export const QUERY_PLACE_FOLDERS_REMOVED = gql`
  query QueryPlaceFoldersRemoved($placeId: ID!, $since: Int) {
    place(id: $placeId, since: $since) {
      id
      removedFolders {
        ...folderInfos
      }
    }
  }
  ${FOLDER_FRAGMENT}
`;

export const GET_PLACE_FOLDERS = gql`
  query GetPlaceFolders($placeId: ID!, $since: Int) {
    place(id: $placeId, since: $since) {
      id
      isFolderMuteHidden
      isContainsDeletedFolder
      isTraceable
      myFolderId
      removed
      folders {
        ...folderInfos
      }
    }
  }
  ${FOLDER_FRAGMENT}
`;

export const GET_PLACE_FOLDERS_PARTICIPANTS = gql`
  query GetPlaceBasicFolder($placeId: ID!, $since: Int) {
    place(id: $placeId, since: $since) {
      ...placeInfos
      ownerId
      adminId
      folders {
        id
        name
        description
        parentId
        myRole
        owner {
          id
          firstName
          lastName
          email
        }
      }
      # removedFolders {
      #   id
      #   name
      #   description
      #   parentId
      #   myRole
      #   owner {
      #     id
      #     firstName
      #     lastName
      #     email
      #   }
      # }
      participants {
        ...userNameBasicInfos
      }
      sortDiscussionType {
        key
        value
      }
    }
  }
  ${USER_NAME_BASIC_FRAGMENT}
  ${PLACE_FRAGMENT}
`;

export const GET_PLACE_FOLDERS_WITH_PERMISSION = gql`
  query GetPlaceFoldersWithPermission($placeId: ID!, $since: Int) {
    place(id: $placeId, since: $since) {
      id
      participants {
        ...userNameBasicInfos
      }
      ownerId
      adminId
      # TODO: parentFolders
      folders {
        ...folderInfos
        hasChildren
        permissions {
          userId
          role {
            id
            name
          }
        }
      }
    }
  }
  ${USER_NAME_BASIC_FRAGMENT}
  ${FOLDER_FRAGMENT}
`;

export const GET_UNREAD_NOTIFICATIONS_FOLDERS = gql`
  query GetUnreadNotificationFolders($placeId: ID!) {
    place(id: $placeId) {
      id
      unread
      folders {
        ...FolderNotificationsFragment
      }
    }
  }
  ${FOLDER_NOTIFICATIONS_FRAGMENT}
`;

export const GET_FAVORITE_FOLDERS = gql`
  query GetFavoriteFolders($placeId: ID!, $since: Int) {
    place(id: $placeId, since: $since) {
      id
      favoriteFolders {
        folderAttached {
          id
          name
          description
          unread
          isNewUser
          hasMention
          removed
          myRole
        }
        updatedAt
      }
    }
  }
`;

export const GET_SHARED_FOLDER = gql`
  query SHARED_FOLDER($id: ID!) {
    getSharedFolder(id: $id)
  }
`;

export const GET_FOLDER_PERMISSIONS = gql`
  query GetFolderPermissions($folderId: ID!, $since: DateTime) {
    folder(id: $folderId, since: $since) {
      id
      created_at
      permissions {
        userId
        role {
          id
          name
        }
        modifier_id
        modifiedAt
      }
    }
  }
`;

export const GET_ALL_TOPIC_NAME = gql`
  query GetAllTopicName($folderId: ID) {
    topicNameInFolder(folderId: $folderId) {
      id
      topics {
        id
        title
        removed
      }
    }
  }
`;

export const GET_PLACE_NOT_MUTE_FOLDERS = gql`
  query GetPlaceNotMuteFolders($placeId: ID!, $currentFolderId: ID) {
    filterNotMuteFolders(placeId: $placeId, currentFolderId: $currentFolderId) {
      ...folderInfos
      unread
    }
  }
  ${FOLDER_FRAGMENT}
`;
export const GET_FOLDER_UNREAD_COUNT = gql`
  query getFolderUnreadCount($folderId: ID!) {
    folder(id: $folderId) {
      ...FolderNotificationsFragment
    }
  }
  ${FOLDER_NOTIFICATIONS_FRAGMENT}
`;

export const GET_FOLDER_NAME = gql`
  query GetFolderName($folderId: ID!) {
    folder(id: $folderId) {
      id
      name
    }
  }
`;
export const GET_ALL_FOLDER_NAME = gql`
  query GetAllFolderName($placeId: ID!) {
    place(id: $placeId) @client {
      folders {
        id
        name
      }
    }
  }
`;

export const GET_FOLDER_OWNER = gql`
  query GetFolderOwner($folderId: ID!) {
    publicFolder(id: $folderId) {
      id
      owner {
        ...userNameBasicInfos
      }
    }
  }
  ${USER_NAME_BASIC_FRAGMENT}
`;

export const GET_FOLDER_REMOVER = gql`
  query GetFolderRemover($folderId: ID!) {
    folder(id: $folderId) {
      id
      removedBy {
        ...userNameBasicInfos
      }
    }
  }
  ${USER_NAME_BASIC_FRAGMENT}
`;

export const GET_FOLDER_PATH_LABEL = gql`
  query GetFolderPathLabel($folderId: ID!) {
    folder(id: $folderId) {
      id
      name
      path
      pathLabel
    }
  }
`;
