import { getFirstSelectedFolderTopicOnLoadFn } from '../services/topicUtils';
import { StepTourType } from '../types/StepTourType';

type Apollo<T> = T & { __typename: string };

export const defaults = {
  popin: {
    isOpen: false,
    isPlaceConfigNotifOpen: false,
    __typename: 'popin',
  },
  sidebar: {
    open: false,
    __typename: 'Sidebar',
  },
  activeDropZone: {
    activeDropZone: true,
    __typename: 'activeDropZone',
  },
  currentChatUploads: [],
  location: {
    placeId: null,
    folderId: null,
    topicId: null,
    isInRecycleBin: false,
    __typename: 'location',
  },
  currentTopicUploads: [],
  snackBar: {
    open: false,
    message: null,
    color: null,
    type: 'SIMPLE',
    duration: null,
    horizontal: 'right',
    vertical: 'top',
    __typename: 'snackBar',
  },
  inviteUserModal: {
    isOpen: false,
    __typename: 'inviteUserModal',
  },
  parentFolder: {
    id: null,
    __typename: 'parentFolder',
  },
  redirection: {
    isNeedToOpenHomeFolder: false,
    __typename: 'redirection',
  },
  currentTutorialStep: localStorage.getItem('tutorial_current_step'),
  Place: {
    removedFolders: null,
    __typename: 'Place',
  },
  attachmentPopover: {
    show: false,
    file: null,
    discussionId: null,
    __typename: 'attachmentPopover',
  },
  authorization: {
    canAccessFolder: true,
    __typename: 'authorization',
  },
  folderAddDialog: {
    open: false,
    parentId: null,
    __typename: 'folderAddDialog',
  },
  folderInviteParticipantDialog: {
    open: false,
    parentId: null,
    __typename: 'folderInviteParticipantDialog',
  },
  confirmDialog: {
    open: false,
    __typename: 'confirmDialog',
  },
  onlineStatus: {
    online: false,
    __typename: 'onlineStatus',
  },
  notConnectedUserLang: {
    language: localStorage.getItem('language') || 'en-US',
    isChanged: false,
    __typename: 'notConnectedUserLang',
  },
  foldersLoading: {
    folderMovesLoading: [],
    __typename: 'foldersLoading',
  },
  topicsLoading: {
    topicMovesLoading: [],
    __typename: 'topicsLoading',
  },
  modalAddUserInPlace: {
    open: false,
    placeId: null,
    __typename: 'modalAddUserInPlace',
  },
  firstSelectedTopic: getFirstSelectedFolderTopicOnLoadFn(),
  messagesLoading: {
    messagesFetchLoading: [],
    __typename: 'messagesLoading',
  },
  currentLocation: {
    placeId: null,
    folderId: null,
    topicId: null,
    isInRecycleBin: false,
    __typename: 'currentLocation',
  },
  recentVisitedFolders: {
    folderIds: [],
    __typename: 'recentVisitedFolders',
  },
  recentVisitedTopics: {
    topicIds: [],
    __typename: 'recentVisitedTopics',
  },
  recentVisitedPlaces: {
    placeIds: [],
    __typename: 'recentVisitedPlaces',
  },
  currentHelpTourStep: {
    currentStep: null,
    doneSteps: [],
    helpTourTopicId: null,
    paused: false,
    later: false,
    replayHelpTour: false,
    __typename: 'currentHelpTourStep',
  } as Apollo<{
    currentStep: StepTourType;
    doneSteps: StepTourType[];
    helpTourTopicId: string | null;
  }>,
  fetchingMoreFolders: {
    folderIds: [],
    __typename: 'fetchingMoreFolders',
  },
  fetchingMoreRemovedFolders: {
    folderIds: [],
    __typename: 'fetchingMoreRemovedFolders',
  },
  topicParticipation: {
    id: 0,
    isParticipating: false,
    participations: [],
    __typename: 'TopicParticipation',
  },
  UploadDiscussions: {
    files: [],
    __typename: 'UploadDiscussions',
  },
};
