// import { QueryResult } from '@apollo/react-common';
import { QueryResult } from '@apollo/react-common';
import { DataProxy } from 'apollo-cache';
import ApolloClient from 'apollo-client';
import { compareAsc, parse } from 'date-fns';
import uniqBy from 'lodash/uniqBy';
import { GET_MESSAGES_LOADING } from '../../gql/local/messagesLoading/query';
import {
  GetTopicMessages,
  GetTopicMessagesVariables,
} from '../../gql/topic/types/GetTopicMessages';
import { CallbackFunction } from '../../types/CallbackFunction';
import { MessageType } from '../../types/graphql-global-types';
import { MessageInfos } from '../../types/MessageInterface';

export const updateMessagesLoadingDataFn = (
  topicId: number,
  cache: DataProxy,
  action: 'remove' | 'add' = 'add'
) => {
  const messageLoadingData = cache.readQuery<{
    messagesLoading: { messagesFetchLoading: number[] };
  }>({ query: GET_MESSAGES_LOADING });

  if (messageLoadingData?.messagesLoading) {
    const { messagesFetchLoading } = messageLoadingData.messagesLoading;
    const messagesFetchLoadingData =
      action === 'add'
        ? [topicId, ...messagesFetchLoading]
        : messagesFetchLoading.filter((value) => value !== topicId);
    const data = {
      messagesLoading: {
        messagesFetchLoading: messagesFetchLoadingData,
        __typename: 'messagesLoading',
      },
    };
    cache.writeData({ data });
  }
};

/**
 * FetchMore old messages
 * @param fetchMore
 * @param topicId
 * @param limit
 * @param offset
 * @param setLoadingOld
 */
export const handleFetchMoreOldMessage = (
  fetchMore: QueryResult<GetTopicMessages>['fetchMore'],
  limit: number,
  offset: number,
  setLoadingOld: CallbackFunction<boolean>,
  divContainer: HTMLDivElement | null
) => {
  setLoadingOld(true);

  const currentScrollHeight = divContainer ? divContainer.scrollHeight : null;

  fetchMore({
    variables: {
      limit,
      offset,
      since: undefined,
    },
    updateQuery: (prev, { fetchMoreResult }) => {
      if (
        !fetchMoreResult ||
        !fetchMoreResult.topic ||
        !fetchMoreResult.topic.messages ||
        (prev &&
          prev.topic &&
          prev.topic.id &&
          fetchMoreResult.topic &&
          fetchMoreResult.topic.id &&
          +prev.topic.id !== +fetchMoreResult.topic.id) ||
        (fetchMoreResult && !fetchMoreResult.topic) ||
        (prev && !prev.topic)
      ) {
        setLoadingOld(false);
        return prev;
      }

      if (!prev.topic) {
        return prev;
      }

      const newMessages = uniqBy(
        [...fetchMoreResult.topic.messages, ...prev.topic.messages],
        'id'
      );
      // const newMessages = uniqBy(
      //   [...fetchMoreResult.topic.messages, ...prev.topic.messages],
      //   'id'
      // ).filter((m) => m.type !== MessageType.removeTopic);

      setLoadingOld(false);

      return {
        ...prev,
        topic: {
          ...fetchMoreResult.topic,
          messages: newMessages,
        },
      };
    },
  })
    .then((rep) => {
      if (rep && rep.data && rep.data.topic && rep.data.topic.messages) {
        if (currentScrollHeight && divContainer) {
          // eslint-disable-next-line no-param-reassign
          divContainer.scrollTop =
            divContainer.scrollHeight - currentScrollHeight - 35;
        }
      }
    })
    .finally(() => setLoadingOld(false));
};

/**
 * FetchMore old messages after deleting file
 */
export const handleFetchMoreOldMessageAfterDeletingFile = (
  fetchMore: QueryResult<GetTopicMessages>['fetchMore'],
  currentMessageLength: number
) => {
  fetchMore({
    variables: {
      limit: 4,
      offset: currentMessageLength,
    },
    updateQuery: (prev, { fetchMoreResult }) => {
      if (
        !fetchMoreResult ||
        !fetchMoreResult.topic ||
        !fetchMoreResult.topic.messages ||
        !prev.topic ||
        (prev &&
          prev.topic &&
          prev.topic.id &&
          fetchMoreResult.topic &&
          fetchMoreResult.topic.id &&
          +prev.topic.id !== +fetchMoreResult.topic.id)
      ) {
        return prev;
      }

      const newMessages = uniqBy(
        [...fetchMoreResult.topic.messages, ...prev.topic.messages],
        'id'
      ).filter((m) => m.type !== MessageType.removeTopic);

      return {
        ...prev,
        topic: {
          ...fetchMoreResult.topic,
          messages: newMessages,
        },
      };
    },
  });
};

export const getSinceMessage = (messages: MessageInfos[]) => {
  return messages.reduce<Date | undefined>((currentValue, message) => {
    const messageDate: string = message.updatedAt
      ? compareAsc(message.updatedAt, message.date) < 0
        ? message.date
        : message.updatedAt
      : message.date;
    if (messageDate) {
      if (!currentValue) {
        return new Date(messageDate);
      }
      return compareAsc(currentValue.toISOString(), messageDate) < 0
        ? new Date(messageDate)
        : currentValue;
    }
    return currentValue;
  }, undefined);
};

export const sortMessageByDate = (a: MessageInfos, b: MessageInfos) => {
  const { date, type } = a;
  const { date: dateB, type: typeB } = b;

  if (type === MessageType.file) {
    return -1;
  }

  if (typeB === MessageType.file) {
    return 1;
  }

  return compareAsc(parse(date), parse(dateB));
};

/**
 * FetchMore messages
 */
export const handleFetchMoreDiscussionMessages = (
  fetchMore: QueryResult<
    GetTopicMessages,
    GetTopicMessagesVariables
  >['fetchMore'],
  since: Date | null,
  topicId: number,
  client: ApolloClient<any>
) => {
  fetchMore({
    variables: {
      since,
      limit: 10000,
    },
    updateQuery: (prev, { fetchMoreResult }) => {
      if (
        fetchMoreResult &&
        fetchMoreResult.topic &&
        prev &&
        prev.topic &&
        +prev.topic.id === +fetchMoreResult.topic.id
        // +fetchMoreResult.topic.id === +topicId
      ) {
        return {
          ...prev,
          topic: {
            ...fetchMoreResult.topic,
            updatedAt: prev.topic.updatedAt, // Prevent reorder
            messages: uniqBy(
              [...fetchMoreResult.topic.messages, ...prev.topic.messages],
              'id'
            ),
          },
        };
      }
      return prev;
    },
  }).finally(() => {
    updateMessagesLoadingDataFn(topicId, client, 'remove');
  });
};

/**
 * Check if topic messages is fetching more
 */
export const getIsFetchMoreTopicMessagesLoadingFn = (
  cache: DataProxy,
  topicId: string
) => {
  const messageLoadingData = cache.readQuery<{
    messagesLoading: { messagesFetchLoading: number[] };
  }>({ query: GET_MESSAGES_LOADING });

  if (messageLoadingData?.messagesLoading) {
    const { messagesFetchLoading } = messageLoadingData.messagesLoading;
    return messagesFetchLoading.some((t) => +t === +topicId);
  }
  return false;
};
