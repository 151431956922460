import { StepTourType } from '../../types/StepTourType';
import { HelpTourDataInterface } from './interfaces';

const HELP_TOUR_DATA_KEY = 'help-tour-data';

const SAVEPOINTS = [
  'importFile1',
  'fileImported',
  'shareFile1',
  'fileShared',
  'discussFile',
  null,
] as StepTourType[];

export const persitToLocalStorage = (
  data: HelpTourDataInterface,
  forceSave = false
) => {
  if (
    (data && SAVEPOINTS.includes(data.currentHelpTourStep.currentStep)) ||
    forceSave
  ) {
    localStorage.setItem(HELP_TOUR_DATA_KEY, JSON.stringify(data));
  }
};

export const getHelpTourData = () => {
  const dataString = localStorage.getItem(HELP_TOUR_DATA_KEY);
  if (dataString) {
    const res = JSON.parse(dataString) as HelpTourDataInterface;
    return {
      currentHelpTourStep: {
        ...res.currentHelpTourStep,
        later: res.currentHelpTourStep.later || false,
      },
    } as HelpTourDataInterface;
  }
  return null;
};
