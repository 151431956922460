import '@formatjs/intl-relativetimeformat/dist/locale-data/en';
import '@formatjs/intl-relativetimeformat/dist/locale-data/fr';
import '@formatjs/intl-relativetimeformat/polyfill';
import './fonts/fonts.css';
import './style.scss';
// Fix some navigator compatibility issues
import 'core-js/stable';
import './redirection';
import React from 'react';
import ReactDOM from 'react-dom';
import { AppContent } from './AppContent';

ReactDOM.render(
  <AppContent history={history} />,
  document.getElementById('app')
);
