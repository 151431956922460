import gql from 'graphql-tag';
import { DUE_DATE_FRAGMENT } from '../dueDate/fragment';
import {
  MESSAGE_BASIC_FRAGMENT,
  MESSAGE_FILE_CONTENT_FRAGMENT,
} from '../message/fragment';
import {
  USER_BASIC_FRAGMENT,
  USER_NAME_BASIC_FRAGMENT,
} from '../User/fragment';

export const TOPIC_PERMISSION_FRAGMENT = gql`
  fragment topicPermissionInfos on TopicPermission {
    placeId
    roleId
    role {
      id
      name
    }
    user {
      ...userBasicInfos
    }
  }
  ${USER_BASIC_FRAGMENT}
`;

export const TOPIC_FRAGMENT = gql`
  fragment topicInfos on Discussion {
    id
    title
    description
    version
    time
    fileLockedId
    lockedById
    approved
    updatedAt
    lockedAt
    dueDate {
      ...dueDateInfos
    }
    responsible {
      ...userNameBasicInfos
    }
    folderId
    placeId
    # folder {
    #   id
    #   myRole
    #   name
    #   path
    # }
    removed
    # publicLink; TODO: fix bug
    hasMention
    hasMentionInMessages
    # draftRawData
    # firstMessage {
    #   ...messageBasicInfos
    # }
    # lastMessage {
    #   ...messageBasicInfos
    # }
    lastFile {
      ...messageFileInfos
    }
    unread
    pined
    isLastUpdatedSeen
    discussionType
    isCoeditionEnable
    user_id
    myTopicRole
    topicPermissions {
      ...topicPermissionInfos
    }
    isFavorite
    isFollowing
    isMentioned
    # place {
    #   id
    #   name
    # }
    owner {
      id
      firstName
      lastName
      email
    }
  }
  ${MESSAGE_FILE_CONTENT_FRAGMENT}
  ${USER_NAME_BASIC_FRAGMENT}
  ${DUE_DATE_FRAGMENT}
  ${TOPIC_PERMISSION_FRAGMENT}
`;

export const TOPIC_FRAGMENT_NO_UPDATED = gql`
  fragment topicInfosNoUpdated on Discussion {
    id
    title
    description
    version
    time
    fileLockedId
    lockedById
    approved
    lockedAt
    isFavorite
    dueDate {
      ...dueDateInfos
    }
    responsible {
      ...userNameBasicInfos
    }
    folder {
      id
      myRole
      name
    }
    removed
    # publicLink; TODO: fix bug
    hasMention
    hasMentionInMessages
    draftRawData
    firstMessage {
      ...messageBasicInfos
    }
    # lastMessage {
    #   ...messageBasicInfos
    # }
    lastFile {
      ...messageFileInfos
    }
    unread
    pined
    isLastUpdatedSeen
    discussionType
    isCoeditionEnable
    user_id
    myTopicRole
    topicPermissions {
      ...topicPermissionInfos
    }
    # place {
    #   id
    #   name
    # }
  }
  ${MESSAGE_FILE_CONTENT_FRAGMENT}
  ${USER_NAME_BASIC_FRAGMENT}
  ${DUE_DATE_FRAGMENT}
  ${TOPIC_PERMISSION_FRAGMENT}
  ${MESSAGE_BASIC_FRAGMENT}
`;

export const TOPIC_BASIC_FRAGMENT = gql`
  fragment topicBasicInfos on Discussion {
    id
    title
    description
    version
    time
    fileLockedId
    lockedById
    approved
    lockedAt
    isOrphanTopic
    dueDate {
      ...dueDateInfos
    }
    responsible {
      ...userNameBasicInfos
    }
    folderId
    removed
    # publicLink; TODO: fix bug
    hasMention
    hasMentionInMessages
    unread
  }
  ${USER_NAME_BASIC_FRAGMENT}
  ${DUE_DATE_FRAGMENT}
`;

export const TOPIC_ROLES_FRAGMENT = gql`
  fragment topicRoleInfos on Discussion {
    id
    title
    user_id
    myTopicRole
    topicPermissions {
      ...topicPermissionInfos
    }
  }
  ${TOPIC_PERMISSION_FRAGMENT}
`;

export const TOPIC_PARTICIPANT_FRAGMENT = gql`
  fragment topicParticipantInfos on TopicParticipant {
    topicId
    lastReadTime
    userId
  }
`;

export const ORPHAN_TOPIC_LIST_FRAGMENT = gql`
  fragment orphanTopicListInfos on Discussion {
    id
    title
    hasMention
    hasMentionInMessages
    lockedById
    unread
    pined
    version
    time
    fileLockedId
    approved
    lockedAt
    isFavorite
    updatedAt
    dueDate {
      ...dueDateInfos
    }
    responsible {
      ...userNameBasicInfos
    }
    removed
    hasMention
    hasMentionInMessages
    draftRawData

    lastFile {
      ...messageFileInfos
    }
    unread
    isLastUpdatedSeen
    discussionType
    isCoeditionEnable
    user_id
    myTopicRole
    topicPermissions {
      ...topicPermissionInfos
    }
  }
  ${MESSAGE_FILE_CONTENT_FRAGMENT}
  ${USER_NAME_BASIC_FRAGMENT}
  ${DUE_DATE_FRAGMENT}
  ${TOPIC_PERMISSION_FRAGMENT}
`;

export const TOPIC_NOTIFICATIONS_FRAGMENT = gql`
  fragment TopicNotificationsFragment on Discussion {
    id
    unread
    hasMention
  }
`;
