import { useEffect, useState } from 'react';

const headerHeight = 200;
const itemHeight = 67;

export const limit = () =>
  Math.round(Math.max((window.innerHeight - headerHeight) / itemHeight, 12));

export const useColumn2Limit = () => {
  const [lim, setLimit] = useState(
    Math.round(Math.max((window.innerHeight - headerHeight) / itemHeight, 12))
  );

  useEffect(() => {
    const resizeHandler = () => {
      setLimit(
        Math.round(
          Math.max((window.innerHeight - headerHeight) / itemHeight, 12)
        )
      );
    };
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  return lim;
};
