import Button, { ButtonProps } from '@material-ui/core/Button';
import classnames from 'classnames';
import React, { useEffect } from 'react';
import useStyle from './styles';

const ButtonSubmit = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const { className } = props;
    const classes = useStyle();

    useEffect(() => {
      if ((ref as any)?.current) (ref as any).current.focus();
    }, [ref]);

    return (
      <Button
        ref={ref}
        {...props}
        className={classnames(
          classes.container,
          className,
          'buttonsubmit-btn-button'
        )}
      />
    );
  }
);

export default ButtonSubmit;
