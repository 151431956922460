import gql from 'graphql-tag';

export const GET_UPLOAD_DISCUSSIONS = gql`
  {
    UploadDiscussions @client {
      files {
        id
        folderId
        title
        description
        version
        time
        lockedById
        fileLockedId
        approved
        updatedAt
        lockedAt
        myTopicRole
        folder {
          id
          myRole
          name
          path
        }
        removed
        hasMention
        hasMentionInMessages
        lastFile
        unread
        pined
        isLastUpdatedSeen
        discussionType
        isCoeditionEnable
        user_id
        isFavorite
      }
    }
  }
`;
