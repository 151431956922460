import gql from 'graphql-tag';
import {
  USER_BASIC_FRAGMENT,
  USER_FRAGMENT,
  USER_NAME_BASIC_FRAGMENT,
} from './fragment';

export const GET_USER_PROFIL = gql`
  query GetUserProfile {
    me {
      id
      firstName
      lastName
      company
      avatar
      email
      skype
      phone
      lastConnection
      language
      timezone
      theme
      status
    }
  }
`;

export const GET_ME_QUERY = gql`
  query GetMeQuery {
    me {
      ...userInfos
      modalHelpAlreadyViewed {
        id
        viewed
      }
      places {
        id
      }
      placesNoAccepted {
        id
        name
      }
      dontShowAgainMoveTopicConfirmation
      modalHelpSharedVersionAlreadyViewed
      hasFileLocked
    }
  }
  ${USER_FRAGMENT}
`;
export const GET_USER_WITH_HASHIDS = gql`
  query getUserWithHashids($IdCrypted: String!) {
    userHashIds(IdCrypted: $IdCrypted) {
      userId
      email
      hasPassword
    }
  }
`;

export const GET_USER_THEME = gql`
  query GetUserTheme {
    me {
      id
      theme
    }
  }
`;

export const GET_ROLE = gql`
  query GET_ROLE($folderId: ID, $userId: ID, $topicId: ID) {
    role(folderId: $folderId, userId: $userId, topicId: $topicId) {
      name
    }
  }
`;

export const GET_TIMEZONE = gql`
  query GetTimezone {
    me {
      id
      timezone
    }
  }
`;

export const GET_NUMBER_OF_USER_CONNECTED = gql`
  query GetNumberOfUSerConnected {
    numberOfUsersConnected
  }
`;

export const GET_USER_DONT_SHOW_MOVE_TOPIC_CONFIRMATION = gql`
  query dontShowAgainMoveTopicQuery {
    me {
      id
      dontShowAgainMoveTopicConfirmation
    }
  }
`;

export const GET_ACTIVITY_STEP = gql`
  query getIsStepOneActive {
    me {
      id
      isStepOneActive
      isStepTwoActive
    }
  }
`;

export const GET_MODAL_HELP_ALREADY_VIEWED = gql`
  query getModalHelpAlreadyViewed {
    me {
      id
      modalHelpAlreadyViewed {
        id
        viewed
      }
    }
  }
`;
export const GET_MODAL_HELP_SHARE_FILE = gql`
  query getModalHelpShareFile {
    modalHelpSharedFileView
  }
`;
export const GET_USER_CORE_INFO = gql`
  query UserCoreInfo($userId: ID!) {
    user(id: $userId) {
      id
      firstName
      lastName
      email
      avatar
    }
  }
`;

export const IS_TOKEN_INVITATION_VALID = gql`
  query IS_TOKEN_INVITATION_VALID($token: String!) {
    isTokenInvitationValid(token: $token) {
      user_id
      place {
        id
        name
      }
      inviter {
        id
        firstName
        lastName
        email
        avatar
      }
    }
  }
`;

export const GET_USER_ROLE = gql`
  query USER_ROLE($folderId: ID, $userId: ID, $topicId: ID) {
    role(folderId: $folderId, userId: $userId, topicId: $topicId) {
      name
    }
  }
`;

export const GET_USER_INVITATION_COUNTER = gql`
  query getUserInvitationCounter {
    me {
      id
      counterSentInvitation {
        allSent
        sentToGuest
      }
    }
  }
`;

export const GET_MODAL_HELP_SHARED_VERSION = gql`
  query getModalHelpSharedVersion {
    me {
      id
      modalHelpSharedVersionAlreadyViewed
    }
  }
`;

export const HAS_FILE_LOCKED = gql`
  query hasFileLocked {
    me {
      id
      hasFileLocked
    }
  }
`;

export const FIND_USERS_BY_EMAILS = gql`
  query FindUsersByEmails($emails: [String!]!) {
    findUserByEmails(emails: $emails) {
      ...userNameBasicInfos
    }
  }
  ${USER_NAME_BASIC_FRAGMENT}
`;

export const IS_PASSWORD_SET = gql`
  query IsPasswordSet {
    isPasswordSet
  }
`;

export const GET_WIKI_ACTIVE_SETTING = gql`
  query GetWikiActiveSetting {
    me {
      id
      isWikiActive
    }
  }
`;

export const GET_CUSTOM_INVITATION_MESSAGE = gql`
  query GetCustomInvitationMessage {
    me {
      id
      customInvitationMessage
    }
  }
`;

export const GET_AUTO_SUGGESTION_PARTICIPANTS = gql`
  query getAutoSuggestionParticipants($placeId: ID!) {
    autoSuggestionParticipants(placeId: $placeId) {
      isLive
      ...userBasicInfos
    }
  }
  ${USER_BASIC_FRAGMENT}
`;
