// const rootPath =
//   isElectron() || process.env.TARGET === 'electron'
//     ? process.env.ELECTRON_APP_PATH
//     : '/';

const rootPath = '/';

const isProd = !(process.env.FRONT_HOST || '').match(
  /(localhost|develop|sandbox)/
);

let electronHost = process.env.FRONT_HOST;

if (isProd)
  electronHost = process.env.FRONT_HOST?.replace(/^app\./, 'electron.');
else if (!process.env.FRONT_HOST?.match(/localhost/)) {
  electronHost = `electron-${process.env.FRONT_HOST}`;
}

module.exports.config = {
  accessToHome: 'validated',
  cloudinaryName: `${process.env.CLOUDINARY_NAME}`,
  cloudinaryUploadPreset: `${process.env.CLOUDINARY_UPLOAD_PRESET}`,
  s3Url: `${process.env.S3_URL}/${process.env.S3_BUCKET}/${process.env.S3_FOLDER}`,
  s3UrlExportFolders: `${process.env.S3_URL}/${process.env.S3_BUCKET}/${process.env.S3_EXPORT_FOLDER}`,
  dbxPathFolder: `${process.env.DBX_BUCKET}/${process.env.DBX_FOLDER}`,
  dbxClientIdKey: `${process.env.DBX_CLIENT_ID_KEY}`,
  dbxClientSecretKey: `${process.env.DBX_CLIENT_SECRET_KEY}`,
  dbxRefreshToken: `${process.env.DBX_REFRESH_TOKEN}`,
  dbxUrlDefault: 'www.dropbox.com',
  dbxUrlToReplace: 'dl.dropboxusercontent.com',
  wikiEndpoint: `${process.env.WIKI_ENDPOINT}/upload`,
  onlyofficeHandlerUrl: `${process.env.ONLYOFFICE_HANDLER_ENDPOINT}/track`,
  froalaKey: `${process.env.FROALA_KEY}`,
  imageResizedURL: `https://s60c45pvel.execute-api.eu-west-3.amazonaws.com/production/resize`, // `${process.env.API_GATEWAY_RESIZE_URL}`,
  maxFileSize: 2147483648,
  previewableExtensions: [
    'jpg',
    'png',
    'jpeg',
    'gif',
    'doc',
    'docx',
    'pptx',
    'ppt',
    'xlsx',
    'xls',
    'pdf',
    'txt',
    'svg',
  ],
  officeExtensions: ['doc', 'docx', 'pptx', 'ppt', 'xlsx', 'xls'],
  previewableNativeExtensions: ['pdf', 'txt'],
  isDev: process.env.NODE_ENV !== 'production',
  onlyOfficeText: ['doc', 'docx', 'txt'],
  onlyOfficeSpreadsheet: ['xlsx', 'xls'],
  onlyOfficePresentation: ['pptx', 'ppt'],
  gDriveAPIKey: process.env.GDRIVE_API_KEY,
  gDriveScope: process.env.GDRIVE_SCOPE,
  googleOAuthClientId: process.env.GOOGLE_OAUTH_CLIENT_ID,
  urlPreviewEndpoint: process.env.URL_PREVIEW_ENDPOINT,
  servers: {
    graphqlUrl: `http${process.env.GRAPHQL_USE_TLS}://${process.env.GRAPHQL_HOST}:${process.env.GRAPHQL_PORT}${process.env.GRAPHQL_PATH}`,
    subscriptionUrl: `ws${process.env.GRAPHQL_USE_TLS}://${process.env.GRAPHQL_HOST}:${process.env.GRAPHQL_PORT}${process.env.GRAPHQL_PATH}`,
  },
  appUrl: `http${process.env.FRONT_USE_TLS}://${process.env.FRONT_HOST}:${process.env.FRONT_PORT}${rootPath}`,
  isProd,
  maxFolderTitleLength: 100,
  maxTopicTitleLength: 100,
  routes: {
    rootPath,
    loginPageFr: '/connexion',
    loginPageEn: '/login',
    signupPageFr: '/inscription',
    signupPageEn: '/signup',
    resetPasswordPage: '/resetPassword',
    forgotPasswordPageEn: '/forgotPassword',
    forgotPasswordPageFr: '/mot-de-passe-oublie',
    setNewPasswordPage: '/password/reset/:token',
    invitationPage: '/invitation/:token',
    magicLinkPage: '/mgl/:token',
    allTodoSection: '/place/:place(\\d+)/todo',
    todoPage: '/place/:place(\\d+)/todo/:todo(\\d+)',
    activityTopicsDiscussionSection:
      '/place/:place(\\d+)/activity/topics/:discussion(\\d+)?',
    activityMentionDiscussionSection:
      '/place/:place(\\d+)/activity/mention/:discussion(\\d+)?',
    followingTopicsDiscussionSection:
      '/place/:place(\\d+)/following/(topics)?/:discussion(\\d+)?',
    placeGdrivePage: '/place/:place(\\d+)/gdrive/:gdrive',
    placeGdriveFilePage: '/place/:place(\\d+)/gdrive/:gdrive/file/:gdrivefile',
    placeFavoritesTopicsDiscussionPage:
      '/place/:place(\\d+)/favorites/(topics)?/:discussion(\\d+)?',
    placePrivatesOptionnalTopicsDiscussionPage:
      '/place/:place(\\d+)/privates/(topics)?/:discussion(\\d+)?',
    placeFilesPage: '/place/:place(\\d+)/files/:files?',
    placeFolderPage: '/place/:place(\\d+)/folder/:folder(\\d+)',
    placePage: '/place/:place(\\d+)',
    placeFolderTopicsDiscussionPage:
      '/place/:place/folder/:folder(\\d+)/topics/:discussion(\\d+)',
    placeFolderWithoutTopic: '/place/:place/folder/:folder(\\d+)/topics/',
    placeTopicDiscussionPage: '/place/:place(\\d+)/topic/:discussion(\\d+)?',
    placePrivatesTopicsDiscussionPage:
      '/place/:place(\\d+)/privates/topics/:discussion(\\d+)?',
  },
  windowsAppUrl: process.env.WINDOWS_APP_DOWNLOAD_LINK,
  macAppUrl: process.env.MAC_APP_DOWNLOAD_LINK,
  electronDevPort: 8085,
  frontDomain: `http${process.env.FRONT_USE_TLS}://${process.env.FRONT_HOST}`,
  electronDomain: `http${process.env.FRONT_USE_TLS}://${electronHost}`,
};
