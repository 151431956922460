import { useQuery } from '@apollo/react-hooks';
import classnames from 'classnames';
import React, { CSSProperties, FC, memo } from 'react';
import { GET_USER_THEME } from '../../gql/User/query';
import logo from '../../images/loading.svg';
import useStyles from './styles';

interface SharePlaceLoadingProps {
  size?: number;
  className?: string;
  style?: CSSProperties;
}

const SharePlaceLoading: FC<SharePlaceLoadingProps> = memo((props) => {
  const { className, style } = props;
  const { data } = useQuery(GET_USER_THEME, {
    fetchPolicy: 'cache-only',
  });
  const isDark = !!(data && data.me && data.me.theme === 'dark');
  const classes = useStyles({ isDark });

  return (
    <div
      className={classnames(
        classes.containerSharePlaceLoading,
        isDark && classes.containerDarkLoading,
        className
      )}
      style={style}
    >
      <img width="30" height="30" src={logo} alt="SharePlaceLoading" />
    </div>
  );
});

export default SharePlaceLoading;
