const color = {
  dialogBackground: '#F3F3F2',
  white: '#FFFFFF',
  blue: '#008BCE',
  blueGradient: 'linear-gradient(161.21deg, #002081 0%, #00A3E0 100%)',
  blueLinearGradient: 'linear-gradient(90deg, #002081 -10%, #00A3E0 180%)',
  darkAndLightBlueLinearGradient:
    'linear-gradient(90deg, #002081 0%, #00A3E0 100%)',
  darkAndLightBlueLinearGradientReverse:
    'linear-gradient(90deg, #00A3E0 0%, #002081 100%)',
  lightAndDarkBlueLinearGradient:
    'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), linear-gradient(90deg, #00A3E0 0%, #002081 100%)',
  orangeGradient: 'linear-gradient( to right, #f2860c, #ffb405 )',
  greenGradient: 'linear-gradient(161.24deg, #72BA39 0%, #00A3E0 100%)',
  textBlue: '#002081',
  red: '#DB3268',
  grey: 'grey',
  blueLight: '#F3F7FF',
  orangeLight:
    'linear-gradient(0deg, rgba(242, 134, 12, 0.2), rgba(242, 134, 12, 0.2)), #FFFFFF',
  blueSky: '#00A3E0',
  darkBlue: '#00459B',
  blueIcon: '#7389be',
  textBlack: '#4A4A4A',
  blueDark: '#6E84BC',
  darkWhite: '#FCFDFF',
  gray: '#9B9B9B',
  grayLight: '#B6B6B6',
  grayLightBubble: '#F0F0F0',
  grayLightText: '#C9C9C9',
  textBlueRgba: 'rgba(0,32,129,0.5)',
  grayBackground: '#F4F4F4',
  backgroundGallery: '#EBEBEA',
  darkOrange: '#F18622',
  orange: '#FF9900',
  selectedItemColor: 'rgba(0,32,129,0.66)',
  green: '#3FAF82',
  greenApprove: '#75B943',
  dropzoneBorderColor: 'rgba(110, 132, 188, 0.6)',
  darkGreyTopic: '#3c454d',
  transparent: 'transparent',
  greyDivider: '#4b4b4b',
  topicsColumnGrey: '#bababa',
  lightAqua: '#e6ffff',
  darkOrangeGradient: 'linear-gradient( to right, #8c4107, #ab5808)',
  greyVersionInfo: '#898989',
  blueUltraLight: '#E5EDF9',
  blueWhite: '#4b8dc1',
  greyLightTopic: '#b0bac4',
  greyLightIcon: '#939392',
  darkGrey: '#373737',
  versionGrey: '#8b8b8b',
  versionGradientOrange: 'linear-gradient(126.68deg, #F76B1C 0%, #FAD961 100%)',
  versionLockedGradientOrange:
    'linear-gradient(0deg, rgba(242, 134, 12, 0.4), rgba(242, 134, 12, 0.4)), #FFFFFF',
  versionGradientGreen: 'linear-gradient(161.24deg, #72BA39 0%, #00A3E0 100%)',
  darkBlueSkyGradient:
    'linear-gradient(90deg, #002081 -60.21%, #00A3E0 96.98%)',
  blueSkyGradient: 'linear-gradient(to bottom, #e6f2fa 0%,#e0e9f6 100%)',
  bgblueSky: '#e6f2fa',
  bgFolderListDark: '#465057',
  colorLabelPlace: '#c3c3c3',
  colorIconPlace: '#fdf3fe',
  bgCurrentFolderColor: '#6e6e6e',
  folderItemColor: '#9EA2A8',
  folderItemColorLight: '#8297C4',
  greyDiscussion: '#454d55',
  greyMessageBox: '#57616a',
  greyEditor: '#7b838e',
  greyUltraDark: '#3f474c',
  textBubbleGreyDark: '#353f46',
  textBubbleGreyDarkNotMine: '#566069',
  buttonDarkGrey: '#282c30',
  timeGrey: '#818181',
  bubbleGrey: '#6d6f6e',
  bgIconFileActions: '#465057',
  darkGreenGradient: 'linear-gradient( to right, #00441e, #17633c)',
  bgPlacesList: '#465057',
  darkSelectedPlace: '#3c454d',
  placeItemColor: '#fffbff',
  darkBlueLinearGradient: 'linear-gradient( to right, #03113a , #002081 )',
  selectedTopicLinearGradientLight:
    'linear-gradient(135.21deg, #002081 -110%, #00A3E0 64%)',
  selectedTopicLinearGradientDark:
    'linear-gradient(135.21deg, #002350, #00517b 64%)',
  selectedTopicColorArrowLight: 'transparent transparent transparent #00a3e0',
  selectedTopicColorArrowDark: 'transparent transparent transparent #00517b',
  lightBlue: '#99A6CD',
  inputBlue: '#CBD2E5',
  scrollbarThumb: 'rgba(0,0,0,0.2)',
  allInputBlue: '#022580',
  lightGreen: '#d5f0df',
  darkGreen: '#2f5c3f',
  blueLink: '#94b9e8',
  blueFromGradient: '#00a3e0',
  blocTopicColor: '#D8D8D8',
  loadablePrimaryColor: '#fffbff',
  loadableSecondaryColor: '#ECEBEB',
  newBlue: '#018ACD',
  whiteLink: '#063886', // B4E6FF Before
  newLightBlue: '#F2F6F9',
  newTextBlack: '#2F2F2F',
  zirconLightBlue: '#fcfdff',
  doveGray: '#6A6A6A',
  chambrayDarkBlue: '#364992',
  casperBlueLight: '#B0C4D6',
  casperBlueHighLight: '#b0c4d64a',
  brightGray: '#3b444d',
  shuttleGray: '#575F69',
  cornflowerBlue: 'rgb(0, 97, 144)',
  mailPattensBlue: '#d8e8ff',
  mailShark: '#2d2f31',
  mailIron: '#d1d2d4',
  mailDanube: '#629cc6',
  mailFountainBlue: '#5a98c3',
  mailZumthor: '#eaf3ff',
  tutuLightPink: '#fffbff29',
  shipCoveLightBlue: '#7389be60',
  osloGray: '#8e9499',
  resolutionBlue: '#012580',
  paleSkyGrey: '#6C747D',
  heatherLightViolet: '#B2B6CE',
  emailBubbleHeader: '#353f46',
  shuttleGrayEmailBubble: '#566069',
  linkColorEmailBubble: 'rgba(125, 188, 255, 0.6)',
  silverCCEmailBubble: '#C9C9C9',
  sanMarinoDarkBlue: '#4666B3',
  whisper: '#f7f8fb',
  black: '#000000',
  ceriseRed: '#DA3568',
  siren: '#77003A',
  crustaOrange: '#F88531',
  catalinaBlue: '#0B2989',
  whiteLight: 'rgba(255, 255, 255, 0.3)',
  skyBlueLight: 'rgba(255, 252, 252, 0.3)',
  timeLineGreen: '#2AC774',
  tahitiOrange: '#F2860C',
  blueLinkWater: '#F1F6FC',
  blueShipCove: '#7389be',
  poloBlue: '#93a5ce',
  tropicalBlue: '#cdecfa',
  wildSand: '#f5f5f5',
  slateGrey: '#6c7d8c',
  whiteGallery: '#efefef',
  silverChalice: '#afafaf',
  lowOpLinkWater: 'rgba(247, 248, 253, 0.2)',
  orangeSeaBuckthorn: '#F69D35',
  regentGray: '#8997A3',
  orangeCalifornia: '#F99D09',
  blueBlackSqueeze: '#e7f0f7',
  lowWhite: 'rgba(0, 0, 0, 0.05)',
  blueLinkWaterOP1: '#F7F8FD',
  doveGrayBis: '#696969',
  leatherBlue: '#c5d1db',
  lightBlueSky: 'rgb(252, 253, 255, 0.5)',
  lightGray: 'rgb(60, 69, 77, 0.5)',
  chathamsBlue: '#163788',
  frenchPassBlue: '#c4e5fd',
  faluRed: '#882518',
  mandysPink: '#f8c9c3',
  crustaOrangeBis: '#F7832F',
  yellowOrange: '#F9A041',
  blackSqueeze: '#E3ECF5',
  jacksonsPurple: '#19358e',
  silver: '#BDBDBD',
  outerSpace: '#31373e',
  pinOrange: '#F99D09',
  mineShaftBlack: '#333333',
  graySpindle: '#C2CCEB',
  transparentGray: '#0000006b',
  greenShushi: '#72BA39',
  activeGray: 'rgba(0, 32, 129, 0.5)',
  ceriseRedBis: '#DB3367',
  azaleaPink: '#F7CDCD',
  customButtonColor: 'linear-gradient(90deg, #F2860C 0%, #FFB405 100%)',
  customBorderTopGray: 'rgba(0, 32, 129, 0.1)',
  customBorderTopOrange: 'rgba(242, 134, 12, 0.3)',
  operationIconDarkGreyColor: '#666',
  linkWater: '#E0E9F6',
  sanguineBrown: '#923d3b',
  desertStorm: '#ebebe9',
  grayPorcelain: '#F2F3F4',
  malibuLightBlue: '#7bd2f9',
  silverChaliceMenu: 'a9a9a9',
  riverBed: '#444D55',
  gableGreen: '#142932',
  cornflowerLilac: '#ffaaa2',
  nevada: '#677277',
  sulu: '#a4f392',
  ebonyClay: '#23333d',
  hawkesBlue: '#E8F3FD',
  zircon: '#f3f7ff',
  california: '#F99D09',
  slateGray: '#6C7D8C',
};

export default color;
