import gql from 'graphql-tag';

export const GET_TOPIC_PARTICIPATION = gql`
  query GET_TOPIC_PARTICIPATION($id: Int!) {
    topicParticipation(id: $id) @client {
      id
      isParticipating
    }
  }
`;
