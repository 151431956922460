export const introspectionQueryResultData = {
  __schema: {
    types: [
      {
        kind: 'UNION',
        name: 'MessageContent',
        possibleTypes: [
          { name: 'MessageText' },
          { name: 'MessageFile' },
          { name: 'SharedEmail' },
          { name: 'TopicOperation' },
        ],
      },
    ],
  },
};
