/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum DiscussionSortedKey {
  date = "date",
  name = "name",
  priority = "priority",
  unread = "unread",
}

export enum DiscussionStartType {
  attachment = "attachment",
  file = "file",
  message = "message",
}

export enum DiscussionType {
  file = "file",
  fileThread = "fileThread",
  thread = "thread",
  wiki = "wiki",
}

export enum FileActions {
  add = "add",
  approved = "approved",
  attachment = "attachment",
  changeFileType = "changeFileType",
  delete = "delete",
  lock = "lock",
  release = "release",
  unapproved = "unapproved",
  unlock = "unlock",
}

export enum FolderTopicsFilter {
  createByMe = "createByMe",
  mentionOnly = "mentionOnly",
  unreadOnly = "unreadOnly",
}

export enum LanguageType {
  EN = "EN",
  FR = "FR",
}

export enum MessageType {
  approved = "approved",
  approvedAttachment = "approvedAttachment",
  attachment = "attachment",
  attachmentRemove = "attachmentRemove",
  downloadDocument = "downloadDocument",
  email = "email",
  file = "file",
  lock = "lock",
  message = "message",
  operation = "operation",
  release = "release",
  removeTopic = "removeTopic",
  removed = "removed",
  restoreTopic = "restoreTopic",
  unapproved = "unapproved",
  unapprovedAttachment = "unapprovedAttachment",
  unlock = "unlock",
  userRemove = "userRemove",
}

export enum OrderByType {
  ASC = "ASC",
  DESC = "DESC",
}

export enum PermissionType {
  admin = "admin",
  delete = "delete",
  owner = "owner",
  reader = "reader",
  writer = "writer",
}

export enum PlaceTopicsFilterType {
  assigned = "assigned",
  finished = "finished",
  inProgress = "inProgress",
  mine = "mine",
}

export enum SectionType {
  activity = "activity",
  favorite = "favorite",
  folder = "folder",
  mention = "mention",
  private = "private",
}

export enum SortValue {
  asc = "asc",
  desc = "desc",
  empty = "empty",
}

export enum TopicFilteringMethods {
  all = "all",
  byMe = "byMe",
  file = "file",
}

export enum UpdateHeaderType {
  follow_unread = "follow_unread",
  mention_priority = "mention_priority",
  mention_unread = "mention_unread",
  private_unread = "private_unread",
  recent_priority = "recent_priority",
  recent_unread = "recent_unread",
  todos = "todos",
}

export enum UrlShortenerType {
  FILE = "FILE",
  FOLDER = "FOLDER",
  ORPHAN_TOPIC = "ORPHAN_TOPIC",
  PLACE = "PLACE",
  TOPIC = "TOPIC",
}

export enum UserAccountStatus {
  blocked = "blocked",
  pendingValidation = "pendingValidation",
  validated = "validated",
}

export enum UserAccountType {
  student = "student",
  teacher = "teacher",
}

export enum VideoCallParticipantStatus {
  MISSING = "MISSING",
  READY = "READY",
  REJECTED = "REJECTED",
  WAITING = "WAITING",
}

export enum VideoCallStatus {
  ACTIVE = "ACTIVE",
  ENDED = "ENDED",
  RINGING = "RINGING",
}

export interface AddOrphanDiscussionInput {
  placeId: string;
  title: string;
  discussionStartType?: DiscussionStartType | null;
  fileName?: string | null;
  fileType?: string | null;
  fileSize?: number | null;
  fileId?: string | null;
  boxFileId?: string | null;
  checksum?: string | null;
  dbxUrlPath?: string | null;
  startDueDate?: any | null;
  endDueDate?: any | null;
  responsible?: string | null;
  mentioned?: string[] | null;
  storageProvider?: string | null;
  storageFileId?: string | null;
  rawData?: any | null;
  description?: string | null;
  discussionType: DiscussionType;
  isCoeditionEnable?: number | null;
  quoteMessageSenderId?: string | null;
  invitees?: InviteInput[] | null;
  message?: string | null;
  noMailing?: boolean | null;
}

export interface AddTypingUserInput {
  topicId: string;
}

export interface AddUserInPlaceInput {
  placeId: string;
  email: string;
}

export interface DuplicateFolderInput {
  folderId: string;
  language: LanguageType;
}

export interface EditTodoInput {
  seen?: boolean | null;
  done?: boolean | null;
}

export interface GetTodoOptions {
  seens?: boolean | null;
  dones?: boolean | null;
  limit?: number | null;
  offset?: number | null;
  since?: number | null;
}

export interface InviteInput {
  userId?: string | null;
  email: string;
  role: PermissionType;
  inheritFolder?: number | null;
}

export interface ParticipantToInviteInput {
  id?: string | null;
  email: string;
  role: PermissionType;
  inheritFolder?: number | null;
}

export interface PlaceOrder {
  id: string;
  orderPlace: number;
}

export interface RemoveUserFromTopicInput {
  topicId: string;
  userId: string;
}

export interface RemoveUserInPlaceInput {
  placeId: string;
  userId: string;
}

export interface SearchFilterInput {
  placeId: string;
  keywords: string;
  orderBy?: OrderByType | null;
  offset?: number | null;
  limit?: number | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
