import { action, observable } from 'mobx';
import { FilterFolderTopics } from '../types/FilterTopicType';
import discussionStore, { DiscussionStoreInterface } from './discussionStore';

export interface RootStoreInterface {
  discussionStore: DiscussionStoreInterface;
}

class RootStore implements RootStoreInterface {
  discussionStore: DiscussionStoreInterface;

  @observable currentFilter: FilterFolderTopics = 'all';

  @observable connected = true;

  placeSearches: Array<{ placeId: number; value: string }> = [];

  @observable changedUser = false;

  @observable uploadExternalState: {
    directoryName: string;
    fileUploaded: number;
    fileTotal: number;
  } = {
    directoryName: '',
    fileTotal: 0,
    fileUploaded: 0,
  };

  addPlaceSearches(placeId: number, value: string) {
    const currentPlaceSearchIndex = this.placeSearches.findIndex(
      ({ placeId: id }) => id === placeId
    );
    if (currentPlaceSearchIndex > -1) {
      this.placeSearches[currentPlaceSearchIndex].value = value;
    } else {
      this.placeSearches = [...this.placeSearches, { placeId, value }];
    }
  }

  removePlaceSearches(placeId: number) {
    this.addPlaceSearches(placeId, '');
  }

  resetPlaceSearches() {
    this.placeSearches = [];
  }

  @action setCurrentFilter(currentFilter: FilterFolderTopics) {
    this.currentFilter = currentFilter;
  }

  constructor() {
    this.discussionStore = discussionStore;
  }
}

export const rootStore = new RootStore();
