import isElectron from 'is-electron';
import { config } from './electronUtils/electronConfig';

const { electronUrl, frontUrl } = config;

if (!isElectron()) {
  if (window.location.origin.startsWith(electronUrl)) {
    window.location.href = frontUrl + window.location.pathname;
  }
}
