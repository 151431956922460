import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import color from './color';

export default (isDarkMode = false, defaultFontFamily = 'Avenir') =>
  createMuiTheme({
    isDarkMode,
    usedTheme: isDarkMode ? 'dark' : 'light',
    color,
    containerStyle: {
      flexColumn: {
        display: 'flex',
        flexDirection: 'column',
      },
      flexRow: {
        display: 'flex',
        flexDirection: 'row',
      },
      flexGrid: {
        display: 'grid',
      },
    },
    dynamicStyle: {
      emailBubble: {
        container: {
          backgroundColor: isDarkMode
            ? color.shuttleGrayEmailBubble
            : color.mailPattensBlue,
          border: `2px solid ${
            isDarkMode ? color.shuttleGrayEmailBubble : color.mailPattensBlue
          }`,
        },
        header: {
          backgroundColor: isDarkMode
            ? color.emailBubbleHeader
            : color.mailZumthor,
        },
        subject: {
          color: isDarkMode ? color.white : color.mailShark,
        },
        cc: {
          color: isDarkMode ? color.silverCCEmailBubble : color.mailIron,
        },
        iconContainer: {
          color: isDarkMode ? color.white : 'inherit',
        },
        content: {
          color: isDarkMode ? color.white : 'inherit',
        },
        link: {
          color: isDarkMode ? color.linkColorEmailBubble : color.mailDanube,
        },
      },
      header: {
        appBar: {
          backgroundColor: isDarkMode ? color.darkGrey : color.white,
        },
        titlePlace: {
          color: isDarkMode ? color.white : color.textBlue,
        },
        searchIcon: {
          color: isDarkMode ? color.white : color.blueIcon,
        },
        infoBoardIcon: {
          color: isDarkMode ? color.white : color.blueIcon,
        },
        notificationIcon: {
          filter: isDarkMode ? 'brightness(0) invert(1) contrast(1)' : '',
        },
        editIcon: {
          color: isDarkMode ? color.white : color.blueShipCove,
        },
        callSessionIcon: {
          color: isDarkMode ? color.white : color.blueShipCove,
        },
        h3: {
          color: isDarkMode ? color.white : color.textBlue,
        },
        loader: {
          backgroundColor: isDarkMode ? color.transparent : color.white,
        },
      },
      border: {
        borderColor: isDarkMode ? color.paleSkyGrey : color.resolutionBlue,
      },
      chevronRight: {
        color: isDarkMode ? color.paleSkyGrey : color.resolutionBlue,
      },
      topic: {
        container: {
          backgroundColor: isDarkMode ? color.darkGreyTopic : color.darkWhite,
        },
        divider: {
          backgroundColor: isDarkMode
            ? color.greyDivider
            : color.blueUltraLight,
        },
        topicsColumnTitle: {
          color: isDarkMode ? color.topicsColumnGrey : color.textBlueRgba,
        },
        folderName: {
          color: isDarkMode ? color.white : color.textBlue,
        },
        folderDescription: {
          color: isDarkMode ? color.white : color.textBlue,
        },
        whiteToBlue: {
          color: isDarkMode ? color.white : color.textBlue,
        },
        topicCreateButton: {
          background: isDarkMode
            ? color.darkOrangeGradient
            : color.orangeGradient,
        },
        filterButton: {
          color: isDarkMode ? color.white : color.textBlue,
          border: isDarkMode ? '1px solid #fff' : '1px solid #002081',
        },
        time: {
          color: isDarkMode ? color.greyVersionInfo : color.grayLight,
        },
        timeSelected: {
          color: isDarkMode ? color.blueWhite : color.white,
        },
        titleItem: {
          color: isDarkMode ? color.darkWhite : color.textBlue,
        },
        headerTitleLabel: {
          color: isDarkMode ? color.greyLightTopic : color.blueDark,
        },
        dragAndDrop: {
          color: isDarkMode ? color.darkWhite : color.textBlue,
        },
        topicFilterContainer: {
          backgroundColor: isDarkMode ? color.bgFolderListDark : color.white,
        },
        topicFilterMenuSelected: {
          backgroundColor: isDarkMode
            ? `${color.darkGreyTopic}!important`
            : 'inherit',
        },
        topicFilterMenuGutter: {
          color: isDarkMode ? `${color.darkWhite}!important` : color.textBlue,
        },
        topicContainerBackground: {
          backgroundColor: isDarkMode
            ? color.brightGray
            : color.zirconLightBlue,
        },
        topicContainerColor: {
          color: isDarkMode
            ? color.casperBlueHighLight
            : color.chambrayDarkBlue,
        },
        pin: {
          color: color.pinOrange,
          transform: 'matrix(-1, 0, 0, 1, 0, 0)',
        },
      },
      notificationIcon: {
        filter: isDarkMode ? 'brightness(0) invert(1) contrast(1)' : '',
      },
      h3: {
        color: isDarkMode ? color.white : color.textBlue,
      },
      places: {
        drawerPaper: {
          backgroundColor: isDarkMode ? color.bgPlacesList : color.white,
        },
        iconPlace: {
          color: isDarkMode ? color.white : color.black,
        },
        selectedPlace: {
          backgroundColor: isDarkMode ? color.darkSelectedPlace : '#E0E9F6',
        },
        placeItem: {
          color: isDarkMode ? color.placeItemColor : color.textBlue,
        },
        buttonAddPlace: {
          background: isDarkMode
            ? color.darkBlueLinearGradient
            : color.blueLinearGradient,
        },
      },
      place: {
        titlePlace: {
          color: isDarkMode ? color.white : color.textBlue,
        },
        titlePlaceOver: {
          color: isDarkMode ? color.paleSkyGrey : color.heatherLightViolet,
        },
        prefixPlace: {
          color: isDarkMode ? color.paleSkyGrey : color.heatherLightViolet,
        },
        prefixPlaceOver: {
          color: isDarkMode ? color.white : color.allInputBlue,
        },
        labelPlace: {
          color: isDarkMode ? color.colorLabelPlace : 'inherit',
        },
        iconPlace: {
          color: isDarkMode ? color.white : 'inherit',
        },
        placeName: {
          color: isDarkMode ? color.white : 'inherit',
        },
        settingIcon: {
          color: isDarkMode ? color.white : color.textBlue,
          backgroundColor: isDarkMode
            ? color.bgIconFileActions
            : color.darkWhite,
          '&:hover': {
            color: isDarkMode ? color.bgIconFileActions : color.white,
            backgroundColor: isDarkMode ? color.white : color.textBlue,
          },
        },
      },
      folders: {
        foldersContainer: {
          background: isDarkMode
            ? color.bgFolderListDark
            : color.blueSkyGradient,
        },
        titleFolderColor: {
          color: isDarkMode ? color.colorLabelPlace : 'inherit',
        },
        folderItemContainer: {
          backgroundColor: isDarkMode ? color.darkGreyTopic : color.darkWhite,
        },
        folderItemColor: {
          color: isDarkMode
            ? color.folderItemColor
            : color.folderItemColorLight,
        },
        folderItemHoverBackground: {
          backgroundColor: isDarkMode ? color.lightGray : color.lightBlueSky,
        },
        unauthorisedStyle: {
          color: isDarkMode ? color.osloGray : color.poloBlue,
        },
        folderEditItemBorderColor: {
          borderColor: isDarkMode ? color.folderItemColor : color.textBlue,
        },
        folderParentOpenStyle: {
          fontWeight: 'bold',
          color: `${
            isDarkMode ? color.folderItemColor : color.sanMarinoDarkBlue
          }!important`,
        },
        menuIcon: {
          color: isDarkMode ? color.folderItemColor : color.sanMarinoDarkBlue,
        },
        icon: {
          color: isDarkMode ? color.white : color.blueIcon,
        },
        iconNew: {
          color: isDarkMode ? undefined : color.blueIcon,
        },
        bookmarks: {
          color: isDarkMode ? color.colorLabelPlace : '#666666',
        },
        pin: {
          color: isDarkMode ? color.folderItemColor : color.blueIcon,
        },
      },
      archives: {
        icon: {
          color: isDarkMode ? color.folderItemColor : color.blueIcon,
        },
        text: {
          color: isDarkMode ? color.folderItemColor : color.textBlue,
        },
      },
      files: {
        title: {
          color: isDarkMode ? color.white : color.textBlue,
        },
        description: {
          // lastVersion: {
          //   background: color.versionGradientGreen,
          // },
          // notLastVersion: {
          //   background: color.versionGradientOrange,
          // },
          time: {
            color: isDarkMode ? color.grayLight : color.textBlack,
          },
        },
        actions: {
          icon: {
            color: isDarkMode ? color.white : color.textBlue,
            backgroundColor: isDarkMode ? color.bgIconFileActions : color.white,
            '&:hover': {
              color: isDarkMode ? color.bgIconFileActions : color.white,
              backgroundColor: isDarkMode ? color.white : color.textBlue,
            },
          },
          iconMenu: {
            color: isDarkMode ? color.white : color.textBlue,
          },
          approve: {
            color: color.white,
            // isDarkMode ? color.white : 'inherit',
          },
        },
        titleNonAttachement: {
          color: isDarkMode ? color.grey : color.textBlue,
        },
      },
      chat: {
        discussionPrincipalContainer: {
          backgroundColor: isDarkMode ? color.greyDiscussion : color.white,
        },
        discussionSubContainer: {
          backgroundColor: isDarkMode
            ? color.shuttleGray
            : color.zirconLightBlue,
        },
        discussionContainer: {
          backgroundColor: isDarkMode ? color.greyDiscussion : color.white,
        },
        inputContainer: {
          backgroundColor: isDarkMode ? color.greyMessageBox : color.blueLight,
        },
        editorContainer: {
          backgroundColor: isDarkMode ? color.greyEditor : color.white,
          color: isDarkMode ? color.white : color.allInputBlue,
          border: isDarkMode ? '' : '1px solid rgb(2 37 128 / 0.7)',
        },
        fileBubble: {
          backgroundColor: isDarkMode ? color.greyMessageBox : color.blueLight,
        },
        approvedFile: {
          backgroundColor: isDarkMode ? color.darkGreen : color.lightGreen,
        },
        fileBubbleBoxShadow: {
          boxShadow: isDarkMode
            ? '0px -20px 20px 0px rgba(68, 68, 68, 0.32)'
            : '0px -20px 20px 0px rgba(117, 117, 117, 0.11)',
        },
        textBubble: {
          backgroundColor: isDarkMode
            ? color.textBubbleGreyDark
            : color.newBlue,
          color: color.white,
        },
        textBubbleIsNotMine: {
          backgroundColor: isDarkMode
            ? color.textBubbleGreyDarkNotMine
            : color.newLightBlue,
          color: isDarkMode ? color.white : color.newTextBlack,
        },
        operationBubbleTime: {
          color: isDarkMode ? color.timeGrey : color.textBlack,
        },
        operationBubble: {
          color: isDarkMode ? color.bubbleGrey : color.grayLightText,
        },
        chatIconTopic: {
          color: isDarkMode ? color.darkWhite : color.white,
        },
        emailBubbleIsMine: {
          backgroundColor: isDarkMode ? color.textBubbleGreyDark : color.blue,
          color: isDarkMode ? color.darkWhite : color.white,
        },
        emailBubbleIsNotMine: {
          backgroundColor: isDarkMode
            ? color.textBubbleGreyDarkNotMine
            : color.grayLightBubble,
          color: isDarkMode ? color.grayLight : color.textBlack,
        },
        bubble: {
          background: isDarkMode
            ? color.greyMessageBox
            : color.blueFromGradient,
          color: isDarkMode ? color.greyEditor : color.blue,
          '&:hover': {
            color: isDarkMode ? color.white : color.white,
            background: isDarkMode ? color.textBubbleGreyDark : color.blue,
          },
        },
        attachementBubble: {
          border: isDarkMode
            ? `solid 1px ${color.white}`
            : `solid 1px ${color.gray}`,
          backgroundColor: isDarkMode ? color.greyMessageBox : color.white,
        },
        link: {
          color: color.white,
        },
        linkNotMine: {
          color: isDarkMode ? color.blueLink : color.newBlue,
        },
        stickyFileLocked: {
          background: isDarkMode
            ? color.darkOrangeGradient
            : color.versionLockedGradientOrange,
        },
        containerFileLockedRelease: {
          background: isDarkMode ? color.greyMessageBox : color.orangeLight,
          color: isDarkMode ? color.grayLightText : color.textBlue,
        },
        containerMenu: {
          background: isDarkMode ? color.greyMessageBox : '#F3F7FF',
        },
      },
      messageBox: {
        icon: {
          color: isDarkMode ? color.white : color.allInputBlue,
        },
      },
      snackbar: {
        simpleMessage: {
          backgroundColor: isDarkMode ? color.darkGreenGradient : color.white,
          color: isDarkMode ? color.white : color.textBlack,
        },
        iconClose: {
          color: isDarkMode ? color.white : color.textBlack,
        },
      },
      headerDiscussion: {
        topicTitle: {
          color: isDarkMode ? color.white : color.textBlue,
        },
        createTopic: {
          backgroundColor: isDarkMode ? color.greyMessageBox : color.white,
        },
        iconTopic: {
          color: isDarkMode ? color.darkWhite : color.textBlue,
        },
        inputDescription: {
          color: { color: isDarkMode ? color.white : color.cornflowerBlue },
          border: {
            borderBottom: isDarkMode
              ? `1px solid ${color.white}`
              : `1px solid ${color.cornflowerBlue}`,
          },
        },
        inputDescriptionFixed: {
          color: { color: isDarkMode ? color.white : color.whiteLight },
          border: {
            borderBottom: isDarkMode
              ? `1px solid ${color.white}`
              : `1px solid ${color.whiteLight}`,
          },
        },
        buttonDescription: {
          color: {
            color: isDarkMode ? color.white : color.cornflowerBlue,
          },
          border: {
            border: isDarkMode
              ? `1px solid ${color.white}`
              : `1px solid ${color.cornflowerBlue}`,
          },
        },
        menu: {
          menuItem: {
            color: {
              color: isDarkMode ? color.catalinaBlue : color.catalinaBlue,
            },
          },
        },
      },
      appVersion: {
        color: isDarkMode ? color.white : color.textBlue,
      },
      selectedTopic: {
        core: {
          background: isDarkMode
            ? color.selectedTopicLinearGradientDark
            : color.selectedTopicLinearGradientLight,
        },
        arrow: {
          borderColor: isDarkMode
            ? color.selectedTopicColorArrowDark
            : color.selectedTopicColorArrowLight,
        },
      },

      popoverOption: {
        optionContainerStyle: {
          paddingBottom: 0,
          paddingTop: 0,
        },
        itemHoverStyle: {
          color: isDarkMode ? color.white : color.white,
          backgroundColor: isDarkMode ? color.crustaOrange : color.crustaOrange,
        },
        itemHoverColor: {
          color: isDarkMode ? color.white : color.white,
        },
        menuIcon: {
          color: isDarkMode ? color.folderItemColor : color.white,
        },
      },
      file: {
        item: {
          border: {
            borderBottom: isDarkMode
              ? `1px solid ${color.bgFolderListDark}`
              : `1px solid ${color.blueLinkWater}`,
          },
          details: { color: isDarkMode ? color.darkWhite : color.blueShipCove },
          name: { color: isDarkMode ? color.darkWhite : color.textBlue },
        },
        header: {
          border: {
            borderBottom: isDarkMode
              ? `1px solid ${color.silverCCEmailBubble}`
              : `1px solid ${color.poloBlue}`,
          },
          title: {
            color: isDarkMode ? color.silverCCEmailBubble : color.poloBlue,
          },
        },
        selectedItem: {
          backgroundColor: isDarkMode
            ? `${color.bgFolderListDark} !important`
            : `${color.tropicalBlue} !important`,
        },
      },
      modal: {
        background: {
          backgroundColor: isDarkMode
            ? `${color.darkGreyTopic}`
            : `${color.white}`,
        },

        filterIconRightBorderLeftColor: {
          borderLeft: isDarkMode
            ? `1px solid ${color.white}`
            : `1px solid ${color.catalinaBlue}`,
        },

        iconStrokeColor: {
          stroke: isDarkMode ? `${color.white}` : `${color.textBlue}`,
        },

        textField: {
          backgroundColor: isDarkMode
            ? `${color.regentGray}`
            : `${color.blueBlackSqueeze}`,
          borderRadius: 4,
        },

        notificationItem: {
          borderBottom: isDarkMode
            ? `solid 2px rgba(0, 0, 0, 0.1)`
            : `solid 2px ${color.grayLightBubble}`,
        },

        activityLogItem: {
          borderBottom: isDarkMode
            ? `solid 1px rgba(0, 0, 0, 0.1)`
            : `solid 1px ${color.grayLightBubble}`,
        },
        activityLogItemsHead: {
          borderBottom: isDarkMode
            ? `solid 2px rgba(0, 0, 0, 0.1)`
            : `solid 2px ${color.linkWater}`,
        },
        placeholder: {
          color: isDarkMode ? `${color.white} !important` : ``,
        },

        header: {
          color: isDarkMode ? `${color.white}` : `${color.textBlue}`,
        },
        closeButton: {
          color: isDarkMode ? `${color.blueBlackSqueeze}` : `${color.textBlue}`,
        },
        helpBorder: {
          border: isDarkMode
            ? `1px solid ${color.blueBlackSqueeze}`
            : `1px solid ${color.textBlue}`,
        },
        helpHover: {
          backgroundColor: isDarkMode ? color.blueBlackSqueeze : color.textBlue,
          color: isDarkMode ? color.textBlue : color.white,
        },
        inputBorder: {
          border: isDarkMode
            ? `1px solid ${color.white}`
            : `1px solid ${color.textBlue}`,
        },
        filterBorder: {
          border: isDarkMode
            ? `1px solid ${color.white}`
            : `1px solid ${color.catalinaBlue}`,
        },
        selectHoverBackground: {
          backgroundColor: isDarkMode
            ? `${color.regentGray}`
            : `${color.blueBlackSqueeze}`,
        },
        buttonBack: {
          border: isDarkMode
            ? `1px solid ${color.white}`
            : `1px solid ${color.textBlue}`,
        },
        subTitleModal: {
          color: isDarkMode ? color.white : color.textBlue,
        },
        backgroundModal: {
          backgroundColor: isDarkMode ? color.white : color.textBlue,
        },
        iconClose: {
          color: isDarkMode ? color.white : color.darkBlue,
        },
        fileInputBorder: {
          border: isDarkMode
            ? `2px dotted ${color.white}`
            : `2px dotted ${color.dropzoneBorderColor}`,
          backgroundColor: isDarkMode ? color.lowOpLinkWater : color.whisper,
        },
        listItemBackground: {
          backgroundColor: isDarkMode
            ? color.regentGray
            : color.blueBlackSqueeze,
        },
        listBackground: {
          backgroundColor: isDarkMode ? color.slateGrey : color.white,
        },
        backgroundPlace: {
          backgroundColor: isDarkMode ? color.slateGrey : color.lowWhite,
        },
        iconCloseDrawer: {
          color: isDarkMode ? color.blueLinkWaterOP1 : color.silverChalice,
        },
        backgroundSetting: {
          backgroundColor: isDarkMode
            ? color.slateGrey
            : color.blueBlackSqueeze,
        },
        selectedSetting: {
          backgroundColor: isDarkMode
            ? `${color.regentGray} !important`
            : color.blueLight,
        },

        borderSetting: {
          borderRight: isDarkMode
            ? `1px solid ${color.doveGrayBis}`
            : `1px solid ${color.leatherBlue}`,
        },
        disconnect: {
          color: isDarkMode ? color.white : color.blue,
        },
        themeSelected: {
          border: isDarkMode
            ? `2px solid ${color.orangeCalifornia}`
            : `2px solid ${color.textBlue}`,
        },
        borderLeftInput: {
          borderLeft: isDarkMode
            ? `1px solid ${color.white}`
            : `1px solid ${color.blue}`,
        },
        icon: {
          color: isDarkMode ? color.white : '#002081',
        },
        backgroundPopUp: {
          background: isDarkMode ? '#6C7D8C' : color.white,
        },
        divider: {
          background: isDarkMode ? 'rgb(229, 237, 249, 0.16)' : undefined,
        },
        iconStroke: {
          stroke: isDarkMode ? color.white : '#002081',
        },
        itemHover: {
          backgroundColor: isDarkMode ? '#8997A3' : '#E7F0F7',
        },
        forTheLast: {
          color: isDarkMode
            ? `${color.white} !important`
            : 'rgba(0, 32, 129, 0.5) !important',
        },
        containerDivider: {
          borderTop: isDarkMode
            ? `1px solid rgba(0, 0, 0, 0.1)`
            : '1px solid rgba(0, 0, 0, 0.1)',
        },
        listItem: {
          color: isDarkMode ? color.white : '#8c8787',
        },
        selectedItem: {
          color: isDarkMode ? color.white : '#388fcc',
        },
        menuLeft: {
          backgroundColor: isDarkMode ? `${color.darkGreyTopic}` : '#e3edf6',
        },
        contentText: {
          color: isDarkMode ? color.white : '#566068',
        },
        subTitle: {
          color: isDarkMode ? color.white : color.grey,
        },
        input: {
          border: isDarkMode ? 'solid 2px white' : 'transparent',
        },
        buttonDelete: {
          border: isDarkMode ? '2px solid white' : '2px solid red',
        },
        buttonText: {
          color: isDarkMode ? color.white : 'red',
        },
      },
      help: {
        background: {
          backgroundColor: isDarkMode ? color.slateGrey : color.whiteGallery,
        },
        close: {
          color: isDarkMode ? color.blueBlackSqueeze : color.silverChalice,
        },
      },
      froala: {
        buttons: {
          color: isDarkMode ? color.white : color.mineShaftBlack,
        },
      },
      participantModal: {
        name: {
          color: isDarkMode ? color.white : color.catalinaBlue,
        },
        userInfo: {
          color: isDarkMode ? color.white : color.activeGray,
        },
        border: {
          borderTop: isDarkMode
            ? `1px solid ${color.customBorderTopOrange}`
            : `1px solid ${color.customBorderTopGray}`,
        },
      },
    },
    metrics: {
      calendar: {
        dayWidth: 21,
        dayInWeekWidth: 94,
        topicHeight: 56,
        timelineRoundSize: 17,
        monthWidth: 98,
        weekWidth: 182,
        yearWidth: 210,
        step: 72,
        firstDayMargin: 29,
      },
      folder: {
        treeMargin: 14,
      },
      tableFolderUser: {
        rowHeight: 48,
        columnWidth: 70,
        FolderNameCellWidth: 383,
        userNameCellHeight: 130,
      },
      place: {
        placeItemHeight: 58,
      },
    },
    fontFamily: {
      avenir: 'Avenir',
      avenirDemi: 'Avenir Demi',
      avenirLight: 'Avenir Light',
      avenirRoman: 'Avenir Roman',
      avenirBold: 'Avenir Bold',
      avenirLTStd: 'Avenir LT Std',
    },
    textStyle: {
      inlineStyle: {
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      titlePlace: {
        color: isDarkMode ? color.white : color.textBlue,
        fontSize: 16,
        fontFamily: 'Avenir Demi',
        fontWeight: 500,
        letterSpacing: 0.42,
        textAlign: 'center',
        textTransform: 'uppercase',
      },

      h3: {
        color: color.textBlue,
        fontSize: 18,
        fontWeight: 500,
        letterSpacing: 0.45,
        textAlign: 'right',
      },
      titleFolder: {
        color: color.textBlue,
        fontSize: 20,
        fontFamily: 'Avenir Demi',
        fontWeight: 500,
      },
    },
    boxShadows: {
      main: '0 2px 7px 0 rgba(0,0,0,0.14)',
      log: '0px 0px 6px rgba(0, 0, 0, 0.25)',
      mini: '0 1px 2px 0 rgba(0,0,0,0.5)',
    },
    palette: {
      primary: { main: color.blue as string },
      secondary: { main: '#000625' },
    },
    typography: {
      fontFamily: [defaultFontFamily, 'Arial', 'sans-serif'].join(','),
    },
    overrides: {
      MuiDivider: {
        root: {
          backgroundColor: '#E5EDF9;',
        },
      },
      MuiListItem: {
        root: {
          color: color.textBlue,
          '&$selected': {
            '&>div>span': {
              fontFamily: 'Avenir Demi',
              fontWeight: 500,
            },
            backgroundColor: color.blueLight,
          },
        },
      },
    },
  });
