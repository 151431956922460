import gql from 'graphql-tag';

export const DUE_DATE_FRAGMENT = gql`
  fragment dueDateInfos on DueDate {
    startDate
    endDate
    percentage
    confirmedAt
  }
`;
