export const findInParentsByClassName = (
  node: HTMLElement,
  className: string
): HTMLElement | null => {
  const parent = node.parentElement;

  if (parent) {
    if (parent.classList.contains(className)) {
      return parent;
    }
    return findInParentsByClassName(parent, className);
  }

  return null;
};

export const findInChildrenByClassName = (
  node: HTMLElement,
  className: string
): HTMLElement | null => {
  if (node.classList.contains(className)) {
    return node;
  }

  const { children } = node;
  const queue = Array.from(children) as HTMLElement[];

  while (queue.length) {
    const child = queue.shift();

    if (child) {
      if (child.classList.contains(className)) {
        return child;
      }

      if (child.children) {
        queue.push(...(Array.from(child.children) as HTMLElement[]));
      }
    }
  }

  return null;
};
