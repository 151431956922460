import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles((theme: Theme) => ({
  container: {
    textAlign: 'center',
    textTransform: 'initial',
    height: 47,
    minWidth: 124,
    border: 'none',
    borderRadius: 23.5,
    background: theme.color.orangeGradient,
    color: theme.color.white,
    fontFamily: 'Avenir Demi',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '20px',
    paddingTop: 7,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
  },
}));
