import { OnSubscriptionDataOptions } from '@apollo/react-common';
import ApolloClient from 'apollo-client';
import { maxBy, uniqBy } from 'lodash';
import { action, extendObservable, observable } from 'mobx';
import { sortMessageByDate } from '../consumers/DiscussionConsumer/discussionConsumerLogic';
import { SubscribeToFileChangedToAttachment } from '../gql/file/types/SubscribeToFileChangedToAttachment';
import { GET_FOLDER_PARTICIPANTS } from '../gql/folder/queries';
import { messageInfos } from '../gql/message/types/messageInfos';
import {
  GET_ORPHAN_TOPIC_PARTICIPANTS,
  GET_TOPIC_MESSAGES,
} from '../gql/topic/query';
import {
  GetTopicMessages,
  GetTopicMessagesVariables,
} from '../gql/topic/types/GetTopicMessages';
import { topicInfos } from '../gql/topic/types/topicInfos';
import { userBasicInfos } from '../gql/User/types/userBasicInfos';
import { getTopicParticipants } from '../services/topicUtils';
import { FilterFolderTopics } from '../types/FilterTopicType';
import { UserBasicInfos } from '../types/UserInterface';

type MessageInterface = messageInfos & { optimisticId?: string | null };

type DataType = {
  topic: topicInfos & {
    messages: MessageInterface[];
    nbMessages: number;
    hasTextMessage: boolean;
  };
};

interface MessageDataInterface {
  data: DataType | null;
  loading: boolean;
  error: string;
}
export interface DiscussionStoreInterface {
  isFetchingMore: boolean;
  currTopic: number | null;
  messages: MessageDataInterface;
  topicParticipants: UserBasicInfos[] | null;
  topicParticipantsMap: Map<string, UserBasicInfos>;
  queryMessages: (client: ApolloClient<any>, topicId: number) => void;
  fetchParticipants: (
    client: ApolloClient<any>,
    topicId: number,
    topicFolderId: string | number | null,
    noResetBeforeFetch?: boolean
  ) => void;
  receiveNewMessages: (messagesAdded: MessageInterface[]) => void;
  fetchMoreMessage: (
    client: ApolloClient<any>,
    since: string,
    offset?: number,
    limit?: number
  ) => void;
  fetchedMessages?: number;
  messagesNumber?: number;
  lastMessage?: MessageInterface;
  lastMessageVersion?: MessageInterface;
}

class DiscussionStore implements DiscussionStoreInterface {
  @observable isFetchingMore = false;

  @observable currTopic: number | null = null;

  @observable topicParticipants: UserBasicInfos[] | null = null;

  @observable topicParticipantsMap: Map<string, UserBasicInfos> = new Map<
    string,
    UserBasicInfos
  >();

  @observable showLastVersionOnly = true;

  @observable messages: MessageDataInterface = {
    data: null,
    loading: false,
    error: '',
  };

  @observable currentFilter: FilterFolderTopics = 'all';

  fetchedMessages = 0;

  messagesNumber = 0;

  lastMessage: MessageInterface | undefined = undefined;

  lastMessageVersion: MessageInterface | undefined = undefined;

  hasOldMessages = false;

  constructor() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    extendObservable(this, {
      // computed values
      get fetchedMessages(): number {
        return that.messages.data?.topic.messages.length || 0;
      },
      get messagesNumber(): number {
        return that.messages.data?.topic.nbMessages || 0;
      },
      get lastMessage(): MessageInterface | undefined {
        const last = maxBy(that.messages.data?.topic.messages, 'date');
        return last || undefined;
      },
      get lastMessageVersion(): MessageInterface | undefined {
        const last = maxBy(
          that.messages.data?.topic.messages.filter((x) => x.type === 'file'),
          'version'
        );
        return last || undefined;
      },
      get hasOldMessages(): boolean {
        const lastMessage = maxBy(
          that.messages.data?.topic.messages,
          'version'
        );
        return that.showLastVersionOnly && (lastMessage?.version || 0) > 1;
      },
    });
  }

  @action queryMessages(
    client: ApolloClient<any>,
    topicId: number,
    isShowLastVersionOnlyNotChanged?: boolean
  ) {
    if (!isShowLastVersionOnlyNotChanged) {
      this.showLastVersionOnly = true;
    }
    this.currTopic = +topicId;
    this.messages = { ...this.messages, loading: true, error: '' };
    client
      .query<GetTopicMessages, GetTopicMessagesVariables>({
        query: GET_TOPIC_MESSAGES,
        variables: {
          topicId: topicId.toString(),
          limit: 12,
          offset: 0,
          lastVersionOnly: !isShowLastVersionOnlyNotChanged,
        },
        fetchPolicy: 'no-cache',
      })
      .then(({ data }) => {
        this.messages = {
          ...this.messages,
          data: data as any,
          loading: false,
          error: '',
        };
      })
      .catch((e) => {
        this.messages = { ...this.messages, loading: false, error: e.message };
      });
  }

  @action fetchMoreMessage(
    client: ApolloClient<any>,
    since: string,
    offsetArg?: number,
    limit?: number
  ) {
    const { fetchedMessages, messagesNumber } = this;
    const { data } = this.messages;
    const sortedMessages = data?.topic.messages.slice().sort(sortMessageByDate);
    const offset =
      sortedMessages && sortedMessages[0].type === 'file' && offsetArg
        ? offsetArg - 1
        : offsetArg;

    if (fetchedMessages >= messagesNumber) {
      return Promise.resolve();
    }
    const topicId = this.currTopic || 0;
    if (this.isFetchingMore || !this.messages.data) {
      return Promise.resolve();
    }
    this.isFetchingMore = true;
    return client
      .query<GetTopicMessages, GetTopicMessagesVariables>({
        query: GET_TOPIC_MESSAGES,
        variables: {
          topicId: topicId.toString(),
          limit,
          offset,
          since: since || undefined,
        },
        fetchPolicy: 'no-cache',
      })
      .then(({ data: receivedData }) => {
        if (data) {
          if (data.topic.messages[0]?.discussionId !== topicId.toString()) {
            return;
          }

          if (!receivedData?.topic) {
            return;
          }
          const { messages, nbMessages } = receivedData.topic;
          const newMessages = uniqBy(
            [...data.topic.messages, ...messages],
            (message) => message.id
          );
          const newData: DataType = {
            ...data,
            topic: {
              ...data.topic,
              messages: newMessages,
              nbMessages:
                nbMessages !== null ? nbMessages : data.topic.nbMessages,
            },
          };

          this.messages = { ...this.messages, data: newData };
        }
      })
      .catch((e) => {
        this.messages = { ...this.messages, loading: false, error: e.message };
      })
      .finally(() => {
        this.isFetchingMore = false;
      });
  }

  @action removeOptimisticMessage = (optimisticId: number) => {
    const { data } = this.messages;
    if (data) {
      const {
        topic: { messages },
      } = data;
      if (messages.some((m) => +m.id === +optimisticId)) {
        const newMessages = data.topic.messages.filter(
          (message) => +message.id !== +optimisticId
        );
        const newData = {
          ...data,
          topic: { ...data.topic, messages: newMessages },
        };

        this.messages = { ...this.messages, data: newData };
      }
    }
  };

  @action receiveNewMessages(messagesAdded: MessageInterface[]) {
    this.performAddMessage(messagesAdded[0]);
  }

  @action receivedUpdateMessage(updated: MessageInterface) {
    const { data } = this.messages;
    if (data) {
      const index = data.topic.messages.findIndex(
        (msg) => msg.id === updated.id
      );
      const { messages } = data.topic;
      messages[index] = updated;
      const newData = {
        ...data,
        topic: {
          ...data.topic,
          messages,
        },
      };
      this.messages = { ...this.messages, data: newData };
    }
  }

  @action deletedMessage(
    deleted: Pick<MessageInterface, 'id' | 'discussionId'>
  ) {
    if (!deleted) return;
    const { data } = this.messages;
    if (+deleted.discussionId === this.currTopic && data) {
      const { messages, nbMessages } = data.topic;
      if (messages.some((m) => m.id === deleted.id)) {
        const newMessages = data.topic.messages.filter(
          (msg) => msg.id !== deleted.id
        );
        const newData: DataType = {
          ...data,
          topic: {
            ...data.topic,
            nbMessages: nbMessages - 1,
            messages: newMessages,
          },
        };
        this.messages = { ...this.messages, data: newData };
      }
    }
  }

  @action changeLastVersionOnlyAndRefetch(client: ApolloClient<any>) {
    this.showLastVersionOnly = !this.showLastVersionOnly;
    if (this.currTopic) {
      this.queryMessages(client, this.currTopic, true);
    }
  }

  @action receiveFileSubscriptionData(topicId: number) {
    return ({ subscriptionData }: { subscriptionData: any }) => {
      const { data } = this.messages;
      if (+topicId === this.currTopic && data && data.topic) {
        const messages = data.topic.messages.map((message) => {
          if (
            (message.type === 'file' || message.type === 'attachment') &&
            (message.content as any).id ===
              (
                subscriptionData.data.fileApproved ||
                subscriptionData.data.fileUnapproved
              ).id
          ) {
            return {
              ...message,
              content: {
                ...message.content,
                approved: subscriptionData.data.fileApproved || 0,
              },
            };
          }
          return message;
        });
        const newData = {
          ...data,
          topic: {
            ...data.topic,
            messages,
          },
        };
        this.messages = { ...this.messages, data: newData as any };
      }
    };
  }

  @action receiveFileAttachmentChange(topicId: number) {
    return ({
      subscriptionData,
    }: OnSubscriptionDataOptions<SubscribeToFileChangedToAttachment>) => {
      const { data } = this.messages;
      if (
        subscriptionData.data?.fileAttached &&
        +topicId === this.currTopic &&
        data?.topic
      ) {
        const sortedMessages = data.topic.messages
          .slice()
          .sort(sortMessageByDate);

        const messages = sortedMessages.map((message, i) => {
          if (
            (message.type === 'file' || message.type === 'attachment') &&
            (message.content as any).id ===
              subscriptionData.data?.fileAttached?.id
          ) {
            const messageType = message.type === 'file' ? 'attachment' : 'file';
            let indexBefore = 1;
            let nonOperationBeforeMessage = sortedMessages[i - indexBefore];
            let stopLoop = false;
            while (!stopLoop) {
              if (nonOperationBeforeMessage) {
                if (nonOperationBeforeMessage.type === 'operation') {
                  indexBefore += 1;
                  nonOperationBeforeMessage = sortedMessages[i - indexBefore];
                } else {
                  stopLoop = true;
                }
              } else {
                stopLoop = true;
              }
            }
            const currentVersion = nonOperationBeforeMessage?.version || 0;
            const newMessage = {
              ...message,
              content: {
                ...message.content,
                type: subscriptionData.data?.fileAttached?.type,
                isAttachedFile: messageType === 'attachment' ? 1 : 0,
              },
              type: messageType,
              version:
                messageType === 'attachment'
                  ? currentVersion
                  : subscriptionData.data?.fileAttached?.version,
            };
            return newMessage;
          }
          return message;
        });
        const newData = {
          ...data,
          topic: {
            ...data.topic,
            messages,
          },
        };
        this.messages = { ...this.messages, data: newData as any };
      }
    };
  }

  @action setParticipants(participants: userBasicInfos[]) {
    this.topicParticipants = null;
    this.topicParticipants = participants;
    if (this.topicParticipants && this.topicParticipants.length) {
      this.topicParticipants.forEach((participant) => {
        this.topicParticipantsMap.set(participant.id, participant);
      });
    }
  }

  @action fetchParticipants(
    client: ApolloClient<any>,
    topicId: number,
    topicFolderId: string | number | null,
    noResetBeforeFetch?: boolean
  ) {
    if (!noResetBeforeFetch) {
      this.topicParticipants = null;
    }

    if (topicFolderId) {
      client
        .query({
          query: GET_FOLDER_PARTICIPANTS,
          variables: {
            folderId: topicFolderId.toString(),
          },
          fetchPolicy: 'no-cache',
        })
        .then(({ data }) => {
          this.topicParticipants = data.folder?.participants || null;
          this.topicParticipantsMap = new Map();
          if (this.topicParticipants && this.topicParticipants.length) {
            this.topicParticipants.forEach((participant) => {
              this.topicParticipantsMap.set(participant.id, participant);
            });
          }
        });
    } else {
      client
        .query({
          query: GET_ORPHAN_TOPIC_PARTICIPANTS,
          variables: {
            topicId: topicId.toString(),
          },
          fetchPolicy: 'no-cache',
        })
        .then(({ data }) => {
          const permissions = data.topic?.topicPermissions;
          this.topicParticipants = permissions
            ? getTopicParticipants(permissions)
            : null;
          this.topicParticipantsMap = new Map();
          if (this.topicParticipants && this.topicParticipants.length) {
            this.topicParticipants.forEach((participant) => {
              this.topicParticipantsMap.set(participant.id, participant);
            });
          }
        });
    }
  }

  performAddMessage(message: MessageInterface) {
    const { data } = this.messages;
    if (+message.discussionId === this.currTopic && data && data.topic) {
      const {
        topic: { messages, nbMessages },
      } = data;

      if (messages.some((m) => +m.id === +message.id)) {
        return;
      }

      const optimisticIndex = !message.optimisticId
        ? -1
        : messages.findIndex((m) => m.optimisticId === message.optimisticId);

      let newMessages;

      if (optimisticIndex >= 0) {
        newMessages = messages;
        newMessages[optimisticIndex] = message;
      } else {
        newMessages = [...messages, message];
      }

      const newData = {
        ...data,
        topic: {
          ...data.topic,
          nbMessages: nbMessages + (newMessages.length - messages.length),
          messages: newMessages,
          hasTextMessage: true,
        },
      };
      this.messages = { ...this.messages, data: newData };
    }
  }

  @action updateCurrentFilter(currentFilter: FilterFolderTopics) {
    this.currentFilter = currentFilter;
  }
}

const discussionStore = new DiscussionStore();

export default discussionStore;
