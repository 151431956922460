import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import ButtonSubmit from '../../commons/ButtonSubmit';
import appErrorImage from '../../images/app-error-image.png';
import { myTheme } from '../../Theme/myTheme';
import useStyles from './styles';

export enum AppErrors {
  SERVER_UNREACHABLE = 'SERVER_UNREACHABLE',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
}

interface AppErrorProps {
  error: AppErrors;
}

const AppError: FC<AppErrorProps> = ({ error }) => {
  const classes = useStyles();

  const handleReload = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    window.location.reload();
  };

  const errorMessage =
    error === AppErrors.SERVER_UNREACHABLE ? (
      <FormattedMessage
        id="app-error.server-unreachable"
        defaultMessage="The GE-IT Space server is not reachable.{lineBreak}Check your Internet connection and reload this page."
        values={{ lineBreak: <br /> }}
      />
    ) : (
      'Internal Server Error'
    );

  return (
    <div className={classes.container}>
      <img src={appErrorImage} alt="error-img" className={classes.errorImage} />
      <div className={classes.textElement}>
        <FormattedMessage id="app-error.oops" defaultMessage="Oops..." />
      </div>
      <div className={classes.textElement}>{errorMessage}</div>
      <ButtonSubmit className={classes.reloadButton} onClick={handleReload}>
        <FormattedMessage
          id="all.reload-page"
          defaultMessage="Reload the page"
        />
      </ButtonSubmit>
      <div className={classes.textElement}>
        <FormattedMessage
          id="app-error.contact-support"
          defaultMessage="If this problem persists please contact us"
        />
        : <br />
        <a href="mailto:contact@grande-ecole-it.com ">
          contact@grande-ecole-it.com
        </a>
      </div>
    </div>
  );
};

const WrappedComponent: FC<AppErrorProps> = (props) => (
  <MuiThemeProvider theme={myTheme()}>
    <AppError {...props} />
  </MuiThemeProvider>
);

export default WrappedComponent;
