import isElectron from 'is-electron';

const LAST_PATH_VERSION = 24;
const PROD_NAME = 'SharePlace';
const STAGING_NAME = 'SharePlaceStaging';

export const LAST_VERSION = `2.0.${LAST_PATH_VERSION}`;

export const isLowVersion = (lastVersionParams?: number) => {
  const lastVersion = lastVersionParams || LAST_PATH_VERSION;
  const appVersion = isElectron()
    ? window.require('electron').remote.app.getVersion()
    : null;
  const splitResult = appVersion ? appVersion.split('.') : null;
  const patchVersion = splitResult && splitResult[2] ? +splitResult[2] : null;
  return patchVersion ? patchVersion < lastVersion : undefined;
};

export const APP_VERSION = isElectron()
  ? window.require('electron').remote.app.getVersion()
  : undefined;

export const sendMessageToIpcMain = (event: string, ...message: any[]) => {
  if (isElectron()) {
    const { ipcRenderer } = window.require('electron');
    ipcRenderer.send(event, message);
  }
};

export const VERSION_NUMBER = isElectron()
  ? +window
      .require('electron')
      .remote.app.getVersion()
      .split('.')
      .join('')
      .toString()
  : 0;

export const APP_NAME = isElectron()
  ? window.require('electron').remote.app.getName()
  : '';

export const isSupportedVersion = () => {
  return (
    (APP_NAME === PROD_NAME && VERSION_NUMBER >= 2024) ||
    (APP_NAME === STAGING_NAME && VERSION_NUMBER >= 2025)
  );
};
