import '@formatjs/intl-relativetimeformat/dist/locale-data/en';
import '@formatjs/intl-relativetimeformat/dist/locale-data/fr';
import '@formatjs/intl-relativetimeformat/polyfill';
import './fonts/fonts.css';
import './style.scss';
// Fix some navigator compatibility issues
import 'core-js/stable';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';
import isElectron from 'is-electron';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import useClient from './apolloClient';
import { ReactourProvider } from './commons/Reactour';
import MainApp from './MainApp';
import {
  findInChildrenByClassName,
  findInParentsByClassName,
} from './services/reactNodes';
import {
  RIGHT_CLICK_ACTION_TARGET,
  RIGHT_CLICK_ACTION_WRAPPER,
  RIGHT_CLICK_PREVENT,
} from './services/rightClick';
import { showSnackbarMessage } from './services/utils';
import { rootStore } from './store';

interface AppContentProps {
  history: any;
}

export const AppContent: FC<AppContentProps> = observer(() => {
  const { client, wsLink } = useClient(isElectron(), rootStore.connected);

  useEffect(() => {
    if (wsLink && client) {
      // @ts-ignore
      wsLink.subscriptionClient.on('disconnected', () => {
        showSnackbarMessage(client, 'ERROR', 'Trying to reconnect', 5000);
        client.writeData({
          data: { onlineStatus: { online: false, __typename: 'onlineStatus' } },
        });
      });
      // @ts-ignore
      wsLink.subscriptionClient.on('connected', () => {
        showSnackbarMessage(client, 'SUCCESS', 'You are online', 5000);
        client.writeData({
          data: { onlineStatus: { online: true, __typename: 'onlineStatus' } },
        });
      });
      // @ts-ignore
      wsLink.subscriptionClient.on('reconnected', () => {
        showSnackbarMessage(client, 'SIMPLE', 'Reconnected', 5000);
        client.writeData({
          data: { onlineStatus: { online: true, __typename: 'onlineStatus' } },
        });
      });

      window.addEventListener('redirect-from-electron', (e) => {
        // eslint-disable-next-line no-console
        console.log('redirect-from-electron', e);
      });
      window.addEventListener('offline', () => {
        client.writeData({
          data: { onlineStatus: { online: false, __typename: 'onlineStatus' } },
        });
        showSnackbarMessage(client, 'ERROR', 'You are offline', 5000);
      });
      window.addEventListener('online', () => {
        client.writeData({
          data: { onlineStatus: { online: true, __typename: 'onlineStatus' } },
        });
        showSnackbarMessage(client, 'SUCCESS', 'You are online', 5000);
        // setTimeout(() => {
        //   location.reload();
        // }, 5000);
      });
    }

    return () => {
      window.removeEventListener('offline', () => null);
      window.removeEventListener('online', () => null);
    };
  }, [wsLink, client]);

  return client && wsLink ? (
    <ApolloHooksProvider client={client}>
      <ReactourProvider>
        <MainApp />
      </ReactourProvider>
    </ApolloHooksProvider>
  ) : null;
});

document.addEventListener('contextmenu', (event) => {
  const target = event.target as HTMLElement;
  // Don't disable context menu for hyperlinks (=> to have open in new tab option for example)
  if (
    target.tagName.toLowerCase() !== 'a' ||
    target.classList.contains(RIGHT_CLICK_PREVENT)
  ) {
    const wrapper = findInParentsByClassName(
      target,
      RIGHT_CLICK_ACTION_WRAPPER
    );
    if (wrapper) {
      const button = findInChildrenByClassName(
        wrapper,
        RIGHT_CLICK_ACTION_TARGET
      );

      if (button) {
        event.preventDefault();

        const clickEvent = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
        });

        button.dispatchEvent(clickEvent);
      }
    }
  }
});
