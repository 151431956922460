/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-var-requires */
const homedir = require('os').homedir();
const { sep } = require('path');
const { config } = require('../common/config');

const { electronDevPort, frontDomain, electronDomain } = config;

const FOLDER_PATH = `${homedir}${sep}shareplace${sep}`;
const LOCK_FOLDER_PATH = `${FOLDER_PATH}lock${sep}`;
const LOG_FOLDER_PATH = `${FOLDER_PATH}logs${sep}`;
const TMP_FOLDER_PATH = `${FOLDER_PATH}tmp${sep}`;
const isDev = process.env.NODE_ENV !== 'production';

const frontPort = process.env.FRONT_PORT;

const electronPort = isDev ? electronDevPort : frontPort;

const electronUrl = `${electronDomain}:${electronPort}${process.env.ELECTRON_APP_PATH}`;

const frontUrl = `${frontDomain}:${frontPort}`;

module.exports.config = {
  paths: {
    FOLDER_PATH,
    LOCK_FOLDER_PATH,
    LOG_FOLDER_PATH,
    TMP_FOLDER_PATH,
  },
  electronUrl,
  frontUrl,
  isDev,
};
