/* eslint-disable import/no-extraneous-dependencies */
import { DataProxy } from 'apollo-cache';
import { History, Location } from 'history';
import queryString from 'query-string';
import { matchPath } from 'react-router-dom';
import { LocationStateInterface } from '../../types/LocationStateInterface';
import RouteParamsInterface from '../../types/RouteParamsInterface';
import { safeParseJson } from '../utils';

/**
 * Get matching path with global routes
 */
interface PathPlace {
  idPlace: number;
  location: Location[];
  currentPath: number;
}

const pastLocations: PathPlace[] = [];
let currentIndex = -1;
let currentPath = 0;

export const getBackLocation = () => {
  const i = currentPath - 1;
  if (i > -1) {
    currentPath = i;
    pastLocations[currentIndex].currentPath = currentPath;
    return pastLocations[currentIndex].location[currentPath];
  }
  currentPath = 0;
  pastLocations[currentIndex].currentPath = currentPath;
  return null;
};

export const goForwardLocation = () => {
  const i = currentPath + 1;
  if (i < pastLocations[currentIndex].location.length) {
    currentPath = i;
    pastLocations[currentIndex].currentPath = currentPath;
    return pastLocations[currentIndex].location[currentPath];
  }
  currentPath = pastLocations[currentIndex].location.length - 1;
  pastLocations[currentIndex].currentPath = currentPath;
  return null;
};

export const isFirstLocation = () => {
  return currentIndex !== -1
    ? pastLocations[currentIndex].currentPath === 0
    : currentPath === 0;
};

export const isLastLocation = () => {
  return currentIndex !== -1 &&
    pastLocations[currentIndex].location.length - 1 > 0
    ? pastLocations[currentIndex].currentPath ===
        pastLocations[currentIndex].location.length - 1
    : true;
};

export const getMatchingPath = (locationParam?: Location) => {
  const location: string = locationParam
    ? locationParam.pathname
    : window.location.pathname;
  return matchPath<RouteParamsInterface>(location, {
    path: [
      '/place/:place?/folder/:folder?/log',
      '/place/:place?/favorites/(topics)?/:discussion?',
      '/place/:place?/privates/(topics)?/:discussion?',
      '/place/:place?/activity/topics/:discussion?',
      '/place/:place?/activity/mention/:discussion?',
      '/place/:place?/following/(topics)?/:discussion?',
      '/place/:place?/topic/:discussion?',
      '/place/:place?/folder/:folder?/(topics)?/:discussion?',
      '/place/:place?/folder/:folder?',
      '/place/:place?/todo/:todo?',
      '/place/:place?/files/all',
      '/place/:place?',
      '',
    ],
  });
};

export const updatePastLocations = (location: any, action: any) => {
  const matchingPathNew = getMatchingPath(location);
  // eslint-disable-next-line no-param-reassign
  location.pathname = location.pathname.endsWith('/')
    ? location.pathname.substring(0, location.pathname.length - 1)
    : location.pathname;
  if (matchingPathNew) {
    const {
      params: { place: placeParamNew },
    } = matchingPathNew;
    // check if change place
    if (placeParamNew) {
      currentIndex = pastLocations.findIndex((v) => {
        return v.idPlace === +placeParamNew;
      });
      if (currentIndex === -1 || pastLocations.length === 0) {
        const lstEmpty: Location[] = [];
        currentIndex =
          pastLocations.push({
            idPlace: +placeParamNew,
            location: lstEmpty,
            currentPath: 0,
          }) - 1;
      }
      currentPath = pastLocations[currentIndex].currentPath;
      const currentPlaceLocation = pastLocations[currentIndex];
      // dont listen when I navigate
      if (action === 'PUSH' || action === 'REPLACE') {
        const locationLenght = pastLocations[currentIndex].location.length;
        if (
          locationLenght > 0 &&
          location.pathname !==
            pastLocations[currentIndex].location[locationLenght - 1].pathname &&
          location.pathname !==
            pastLocations[currentIndex].location[currentPath].pathname
        ) {
          if (pastLocations[currentIndex].currentPath !== locationLenght - 1) {
            const leftLocation = pastLocations[currentIndex].location.slice(
              0,
              pastLocations[currentIndex].currentPath
            );
            const rightLocation = pastLocations[currentIndex].location
              .slice(pastLocations[currentIndex].currentPath, locationLenght)
              .reverse();
            const joinLocation: Location[] = [];
            joinLocation.push(...leftLocation);
            joinLocation.push(...rightLocation);
            joinLocation.push(location);
            pastLocations[currentIndex].location = joinLocation;
            currentPath = pastLocations[currentIndex].location.length - 1;
            pastLocations[currentIndex].currentPath = currentPath;
          } else {
            // verify if last location already exist
            const lastLocation =
              currentPlaceLocation.location[locationLenght - 1];
            const pathnameLastLocations = lastLocation.pathname;
            if (location.pathname.includes(pathnameLastLocations)) {
              currentPlaceLocation.location[locationLenght - 1] = location;
            } else {
              currentPlaceLocation.location.push(location);
            }
            currentPath = currentPlaceLocation.location.length - 1;
            currentPlaceLocation.currentPath = currentPath;
          }
        } else if (pastLocations[currentIndex].location.length === 0) {
          pastLocations[currentIndex].location.push(location);
          currentPath = pastLocations[currentIndex].location.length - 1;
          pastLocations[currentIndex].currentPath = currentPath;
        }
      }
    }
  }
};

export const changePathInPastLocation = (
  newfolderId: number,
  dissccussionId: number
) => {
  pastLocations[currentIndex].location.forEach(
    (l: Location<LocationStateInterface>, index: number) => {
      const matchingPathNew = getMatchingPath(l);
      if (
        matchingPathNew &&
        matchingPathNew.params.place &&
        matchingPathNew.params.discussion &&
        +matchingPathNew.params.discussion === dissccussionId
      ) {
        pastLocations[currentIndex].location[
          index
        ].pathname = `/place/${matchingPathNew.params.place}/folder/${newfolderId}/topics/${dissccussionId}`;
      }
    }
  );
};

/**
 * Get location url to go
 *
 * @param location
 */
export const handleGetLocationToGo = (location: {
  placeId?: number | string;
  folderId?: number | string;
  topicId?: number | string;
  isInActivity?: boolean;
  isInActivityTopics?: boolean;
  isInActivityMention?: boolean;
  isInRecycle?: boolean;
  isInFollowing?: boolean;
  isInTodo?: boolean;
  isInPrivateTopics?: boolean;
  isInFavoriteTopics?: boolean;
  isInFiles?: boolean;
}) => {
  const {
    placeId,
    folderId,
    topicId,
    isInRecycle,
    isInActivityTopics,
    isInActivityMention,
    isInFollowing,
    isInTodo,
    isInPrivateTopics,
    isInFavoriteTopics,
    isInFiles,
  } = location;
  const recycleParam = isInRecycle ? '?recycle=1' : '';
  if (!placeId) {
    return `${recycleParam}`;
  }
  if (!folderId && !topicId) {
    if (isInActivityTopics) return `/place/${placeId}/activity/topics`;
    if (isInActivityMention) return `/place/${placeId}/activity/mention`;
    if (isInFollowing) return `/place/${placeId}/following/topics`;
    if (isInTodo) return `/place/${placeId}/todo`;
    if (isInPrivateTopics)
      return `/place/${placeId}/privates/topics${recycleParam}`;
    if (isInFavoriteTopics) return `/place/${placeId}/favorites`;
    if (isInFiles) return `/place/${placeId}/files/all`;
    return `/place/${placeId}${recycleParam}`;
  }
  if (topicId && !folderId) {
    if (isInActivityTopics)
      return `/place/${placeId}/activity/topics/${topicId}`;
    if (isInActivityMention)
      return `/place/${placeId}/activity/mention/${topicId}`;
    if (isInFollowing) return `/place/${placeId}/following/topics/${topicId}`;
    if (isInTodo) return `/place/${placeId}/todo`;
    if (isInFavoriteTopics)
      return `/place/${placeId}/favorites/topics/${topicId}`;
    return `/place/${placeId}/privates/topics/${topicId}${recycleParam}`;
  }
  if (!topicId) {
    return `/place/${placeId}/folder/${folderId}${recycleParam}`;
  }

  return `/place/${placeId}/folder/${folderId}/topics/${topicId}${recycleParam}`;
};

/**
 * Get last folder/topic visited
 */
export const getLastPersistedFolderTopicVisitedFn = (
  placeId: number | null = null
): {
  url?: string | null;
  locations: {
    placeId?: number;
    folderId?: number;
    topicId?: number;
    isInRecycle?: boolean;
  } | null;
} => {
  const userId = localStorage.getItem('@userId');
  const key = !placeId
    ? `user:${userId}:lastFolderTopicVisited`
    : `user:${userId}:place:${placeId}:placeLastFolderTopicVisited`;
  const lastFolderTopic = localStorage.getItem(key);
  if (lastFolderTopic) {
    const lastFolderTopicPath: {
      placeId?: number;
      folderId?: number;
      topicId?: number;
      isInRecycle?: boolean;
      isInTodo?: boolean;
      isInActivityTopics?: boolean;
      isInPrivate?: boolean;
    } | null = safeParseJson(lastFolderTopic);
    if (lastFolderTopicPath) {
      return {
        url: handleGetLocationToGo({
          ...lastFolderTopicPath,
          isInPrivateTopics: lastFolderTopicPath.isInPrivate,
        }),
        locations: lastFolderTopicPath,
      };
    }
  }
  return {
    url: null,
    locations: null,
  };
};

/**
 * Get location path
 *
 * @returns object of locations
 */
export const getLocationPath = (): {
  placeId?: number;
  folderId?: number;
  topicId?: number;
  todoId?: number;
  isInRecycle?: boolean;
  isPrivate?: boolean;
  isFavorite?: boolean;
  isFolderLog?: boolean;
  isInActivity?: boolean;
  isInActivityTopics?: boolean;
  isInActivityMention?: boolean;
  isInFollowing?: boolean;
  isInFavoriteTopics?: boolean;
  isInFiles?: boolean;
  isInTodo?: boolean;
} => {
  const matchingPath = getMatchingPath();
  if (matchingPath) {
    const {
      params: {
        discussion: discussionParam,
        place: placeParam,
        folder: folderParam,
        todo: todoParam,
      },
    } = matchingPath;
    const { url, locations } = getLastPersistedFolderTopicVisitedFn();
    if (!discussionParam && !placeParam && !folderParam && url && locations) {
      return {
        folderId: locations.folderId,
        placeId: locations.placeId,
        topicId: locations.topicId,
        isInRecycle: locations.isInRecycle,
        isFavorite: url.includes('/favorites'),
        isPrivate: url.includes('/privates'),
        isFolderLog: url.includes('/log'),
        isInActivity: url.includes('/activity/'),
        isInActivityTopics: url.includes('/activity/topics'),
        isInActivityMention: url.includes('/activity/mention'),
        isInFollowing: url.includes('/following'),
        isInFavoriteTopics: url.includes('/favorites/'),
        isInFiles: url.includes('/files/all'),
        isInTodo: url.includes('/todo'),
      };
    }
    return {
      folderId: folderParam && !isNaN(+folderParam) ? +folderParam : undefined,
      placeId: placeParam && !isNaN(+placeParam) ? +placeParam : undefined,
      topicId:
        discussionParam && !isNaN(+discussionParam)
          ? +discussionParam
          : undefined,
      todoId: todoParam && !isNaN(+todoParam) ? +todoParam : undefined,
      isInRecycle: queryString.parse(window.location.search).recycle === '1',
      isFavorite: matchingPath.path.includes('/favorites'),
      isPrivate: matchingPath.path.includes('/privates'),
      isFolderLog: matchingPath.path.includes('/log'),
      isInActivity: matchingPath.path.includes('/activity/'),
      isInActivityTopics: matchingPath.path.includes('/activity/topics'),
      isInActivityMention: matchingPath.path.includes('/activity/mention'),
      isInFollowing: matchingPath.path.includes('/following'),
      isInFavoriteTopics: matchingPath.path.includes('/favorites/'),
      isInFiles: matchingPath.path.includes('/files/all'),
      isInTodo: matchingPath.path.includes('/todo'),
    };
  }
  return {};
};

/**
 * Set first selected topic in folder
 *
 * @param client apolloClient
 * @param folderId current folder Id
 * @param topicId first loaded topic in folder
 */
export const setFirstSelectedTopicFn = (
  client: DataProxy,
  folderId: number,
  topicId: number | null,
  isInRecycle: boolean
) => {
  client.writeData({
    data: {
      firstSelectedTopic: {
        topicId,
        folderId,
        isInRecycle,
        __typename: 'firstSelectedTopic',
      },
    },
  });
};

/**
 * Go to location
 *
 * @param history
 * @param location
 */
export const handleGoToLocation = (
  history: History<LocationStateInterface>,
  location: {
    placeId?: number | string;
    folderId?: number | string;
    topicId?: number | string;
    isInActivity?: boolean;
    isInActivityTopics?: boolean;
    isInActivityMention?: boolean;
    isInRecycle?: boolean;
    isInTodo?: boolean;
    isInPrivateTopics?: boolean;
  },
  isReplace = false,
  stateToConcat: LocationStateInterface = {},
  disableScroll?: boolean
) => {
  const { push, replace } = history;
  const historyAction = isReplace ? replace : push;
  const { placeId, folderId, topicId, isInPrivateTopics } = location;
  const url = handleGetLocationToGo(location);
  if (!placeId) {
    return historyAction(url, stateToConcat);
  }
  if (placeId && isInPrivateTopics) {
    return historyAction(url, {
      scrollToTopic: true,
      fetchMoreTopics: true,
      ...stateToConcat,
    });
  }
  if (!folderId && !topicId) {
    return historyAction(url, {
      fetchMoreFolders: true,
      forceFolder: true,
      scrollToFolder: true,
      ...stateToConcat,
    });
  }
  if (!topicId) {
    return historyAction(url, {
      fetchMoreTopics: true,
      forceTopic: true,
      scrollToFolder: disableScroll ? false : undefined,
      ...stateToConcat,
    });
  }
  const scrollToFolder = !url.includes(
    '/privates' || '/activity' || '/following' || '/todo'
  );

  return historyAction(url, {
    fetchMoreMessages: true,
    scrollToTopic: true,
    ...stateToConcat,
    scrollToFolder: disableScroll ? false : scrollToFolder,
    forceFolderToShown: scrollToFolder,
  });
};

export const handleReplaceStateLocation = (
  history: History<LocationStateInterface>,
  state: LocationStateInterface
) => {
  (history as any).replace({
    ...history.location,
    state: {
      ...(history.location.state || {}),
      ...state,
    } as LocationStateInterface,
  });
};

/**
 * Check if is in recycle
 * @param search location.search
 */
export const isInRecycleFn = (search: string) =>
  queryString.parse(search).recycle === '1';
/**
 * Check if is in ActivityTopics
 * @param search location.pathname
 */
export const isInActivityTopics = (pathname: string) =>
  pathname.includes('/activity/topics');
/**
 * Check if is in Activity
 * @param search location.pathname
 */
export const isInActivity = (pathname: string) =>
  pathname.includes('/activity');
/**
 * Check if is in ActivityMention
 * @param search location.pathname
 */
export const isInActivityMention = (pathname: string) =>
  pathname.includes('/activity/mention');
/**
 * Check if is in Following
 * @param search location.pathname
 */
export const isInFollowingFn = (pathname: string) =>
  pathname.includes('/following');

/**
 * Check if is in recycle
 *
 * @param pathname location.pathname
 */
export const isInFavoriteFn = (pathname: string) =>
  pathname.includes('/favorites');
/**
 * Check if is in Private
 *
 * @param pathname location.pathname
 */
export const isInPrivateFn = (pathname: string) =>
  pathname.includes('/privates');

export const isInTodoFn = (pathname: string) => pathname.includes('/todo');

export const isInFilesFn = (pathname: string) => pathname.includes('/files');

export const isInFolderFn = (pathname: string) => pathname.includes('/folder');

export const getLocationDataBool = (pathname: string) => {
  const isInActivities = isInActivityTopics(pathname);
  const isInMentions = isInActivityMention(pathname);
  const isInFavorites = isInFavoriteFn(pathname);
  const isInPrivates = isInPrivateFn(pathname);
  const isInFolder = isInFolderFn(pathname);

  return {
    isInActivities,
    isInMentions,
    isInFavorites,
    isInPrivates,
    isInFolder,
  };
};

/**
 * Parse location param
 */
export const getIntLocationParamFn = (param: string | undefined | null) =>
  param && !isNaN(+param) ? +param : null;

/**
 * Get last folder/topic visited
 */
export const getLastPersistedFolderTopicVisitedLocationFn = (
  placeId: number | null = null
) => {
  const userId = localStorage.getItem('@userId');
  const key = !placeId
    ? `user:${userId}:lastFolderTopicVisited`
    : `user:${userId}:place:${placeId}:placeLastFolderTopicVisited`;
  const lastFolderTopic = localStorage.getItem(key);
  if (lastFolderTopic) {
    const lastFolderTopicPath: {
      placeId?: number;
      folderId?: number;
      topicId?: number;
      isInRecycle?: boolean;
      isInTodo?: boolean;
      isInActivityTopics?: boolean;
      isInPrivate?: boolean;
    } | null = safeParseJson(lastFolderTopic);
    if (lastFolderTopicPath) {
      return lastFolderTopicPath;
    }
  }
  return null;
};

/**
 * Persiste in localStorage last folder topic visited
 */
export const persistLastFolderTopicVisitedFn = (
  {
    placeId,
    folderId,
    topicId,
    isInRecycle,
    isInTodo,
    isInActivityTopic,
    isInActivityMentions,
    isInFollowing,
    isInPrivate,
    isInFavoriteTopics,
    isInFiles,
  }: {
    placeId: number;
    folderId?: number | null;
    topicId?: number | null;
    isInRecycle?: boolean;
    isInTodo?: boolean;
    isInActivityTopic?: boolean;
    isInActivityMentions?: boolean;
    isInFollowing?: boolean;
    isInPrivate?: boolean;
    isInFavoriteTopics?: boolean;
    isInFiles?: boolean;
  },
  currentPlaceId: number,
  customUserId?: string
) => {
  const userId = customUserId || localStorage.getItem('@userId');
  const lastFolderTopicVisitedKey = `user:${userId}:lastFolderTopicVisited`;
  const placeLastFolderTopicVisitedKey = `user:${userId}:place:${currentPlaceId}:placeLastFolderTopicVisited`;
  localStorage.setItem(
    placeLastFolderTopicVisitedKey,
    JSON.stringify({
      placeId,
      folderId: folderId && !isNaN(folderId) ? +folderId : null,
      topicId: topicId && !isNaN(topicId) ? +topicId : null,
      isInRecycle: isInRecycle || false,
      isInTodo: isInTodo || false,
      isInActivityTopics: isInActivityTopic || false,
      isInActivityMention: isInActivityMentions || false,
      isInFollowing: isInFollowing || false,
      isInPrivate: isInPrivate || false,
      isInFavoriteTopics: isInFavoriteTopics || false,
      isInFiles: isInFiles || false,
    })
  );
  localStorage.setItem(
    lastFolderTopicVisitedKey,
    JSON.stringify({
      placeId,
      folderId: folderId && !isNaN(folderId) ? +folderId : null,
      topicId: topicId && !isNaN(topicId) ? +topicId : null,
      isInRecycle: isInRecycle || false,
      isInTodo: isInTodo || false,
      isInActivityTopics: isInActivityTopic || false,
      isInActivityMention: isInActivityMentions || false,
      isInFollowing: isInFollowing || false,
      isInPrivate: isInPrivate || false,
      isInFavoriteTopics: isInFavoriteTopics || false,
      isInFiles: isInFiles || false,
    })
  );
};
export const cleartLastFolderTopicVisitedFn = (placeId?: number) => {
  const userId = localStorage.getItem('@userId');
  const lastFolderTopicVisitedKey = `user:${userId}:lastFolderTopicVisited`;
  const placeLastFolderTopicVisitedKey = `user:${userId}:place:${placeId}:placeLastFolderTopicVisited`;
  localStorage.removeItem(lastFolderTopicVisitedKey);
  localStorage.removeItem(placeLastFolderTopicVisitedKey);
};

/**
 * Util for generating GDrive paths
 * @param parameters
 */
export const gDrivePath = (parameters: RouteParamsInterface) => {
  const { place, gdrive, gdrivefile } = parameters;
  if (place && gdrive && gdrivefile) {
    return `/place/${place}/gdrive/${gdrive}/file/${gdrivefile}`;
  }
  if (place && gdrive) {
    return `/place/${place}/gdrive/${gdrive}`;
  }
  return `/place/${place}`;
};
