import gql from 'graphql-tag';

export const USER_FRAGMENT = gql`
  fragment userInfos on User {
    id
    firstName
    lastName
    avatar
    email
    skype
    phone
    timezone
    lastConnection
    createdAt
    updatedAt
    onBordingDiscussion
    onBordingInvite
    onBordingComment
    onBoardingStep
    isAfterSignup
    neverShowTutorialAgain
    language
    theme
    registered
    useSimplifiedView
    showAllFiles
    company
    showFavorites
    showSearchFolders
    showPictoFolders
    useOnlineEditor
    isGoogleDriveActive
    isNewButtonActive
    isWikiActive
    isFlat
    magicLinkAccountCompletionDisplayed
    notificationsInRedis
    hasDoneHelpTour
    showFolderInTopicsColumn
    useNotificationSounds
    status
    isAdmin
    userAccountType
  }
`;

export const USER_BASIC_FRAGMENT = gql`
  fragment userBasicInfos on User {
    id
    firstName
    lastName
    avatar
    email
    skype
    phone
    timezone
    lastConnection
    createdAt
    updatedAt
    registered
    isLive
    status
  }
`;

export const USER_NAME_BASIC_FRAGMENT = gql`
  fragment userNameBasicInfos on User {
    id
    firstName
    lastName
    avatar
    email
    lastConnection
    isLive
    registered
    status
  }
`;
