import gql from 'graphql-tag';

export const GET_FOLDERS_LOADING = gql`
  {
    foldersLoading @client {
      folderMovesLoading
    }
  }
`;

export const GET_FETCHING_MORE_FOLDER_LOADING = gql`
  {
    fetchingMoreFolders @client {
      folderIds
    }
  }
`;

export const GET_FETCHING_MORE_REMOVED_FOLDER_LOADING = gql`
  {
    fetchingMoreRemovedFolders @client {
      folderIds
    }
  }
`;
