import { createContext, useContext } from 'react';
import { StepTourType } from '../../types/StepTourType';

interface ReactourContextInterface {
  paused: boolean;
  finished: boolean;
  currentHelpTourStep: StepTourType;
  doneSteps: number[];
  helpTourTopicId: string | null;
  remainingSteps: StepTourType[];
  /** Value to listen if forced help tour update is needed */
  listeningUpdate: string;
  later: boolean;
  replayHelpTour: boolean;
  replayHelpTourFunction: () => void;
  /** Used to refresh help tour position */
  refreshHelpTour: () => void;
  setHelpTourStep: (
    step: StepTourType,
    done?: number[] | undefined,
    topicId?: string | null | undefined,
    pausedParam?: any
  ) => void;
  pauseHelpTour: () => void;
  skipHelpTour: (value?: boolean) => void;
}

export const ReactourContext = createContext({} as ReactourContextInterface);

export const useHelpTour = () => useContext(ReactourContext);
