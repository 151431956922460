import React, { FC, lazy } from 'react';
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';
import { config } from '../common/config';
import { ProtectedRoute } from './ProtectedRoute';

const {
  loginPageEn,
  loginPageFr,
  signupPageEn,
  signupPageFr,
  forgotPasswordPageEn,
  forgotPasswordPageFr,
  resetPasswordPage,
  setNewPasswordPage,
  magicLinkPage,
  invitationPage,
  allTodoSection,
  activityMentionDiscussionSection,
  activityTopicsDiscussionSection,
  followingTopicsDiscussionSection,
  placeFavoritesTopicsDiscussionPage,
  placeFilesPage,
  placeFolderPage,
  placeFolderTopicsDiscussionPage,
  placeGdriveFilePage,
  placeGdrivePage,
  placePage,
  placePrivatesOptionnalTopicsDiscussionPage,
  placePrivatesTopicsDiscussionPage,
  placeTopicDiscussionPage,
  todoPage,
  placeFolderWithoutTopic,
} = config.routes;

const PrivateMainContainer = lazy(
  () => import('../containers/Private/PrivateContainer')
);

const Authentication = lazy(
  () => import('../components/Authentication/Authentication')
);
const Invitation = lazy(() => import('../components/Invitation/Invitation'));
const MagicLink = lazy(() => import('../components/MagicLink'));

const AppRouter: FC = () => {
  return (
    <Router>
      <Switch>
        {/* GDrive folders/files ids aren't numbers */}
        <ProtectedRoute
          exact={true}
          path={allTodoSection}
          component={PrivateMainContainer}
        />
        <ProtectedRoute
          exact={true}
          path={todoPage}
          component={PrivateMainContainer}
        />
        <ProtectedRoute
          exact={true}
          path={activityTopicsDiscussionSection}
          component={PrivateMainContainer}
        />
        <ProtectedRoute
          exact={true}
          path={activityMentionDiscussionSection}
          component={PrivateMainContainer}
        />
        <ProtectedRoute
          exact={true}
          path={followingTopicsDiscussionSection}
          component={PrivateMainContainer}
        />
        <ProtectedRoute
          exact={true}
          path={placeGdrivePage}
          component={PrivateMainContainer}
        />
        <ProtectedRoute
          exact={true}
          path={placeGdriveFilePage}
          component={PrivateMainContainer}
        />
        <ProtectedRoute
          exact={true}
          path={placeFavoritesTopicsDiscussionPage}
          component={PrivateMainContainer}
        />
        <ProtectedRoute
          exact={true}
          path={placePrivatesOptionnalTopicsDiscussionPage}
          component={PrivateMainContainer}
        />
        <ProtectedRoute
          exact={true}
          path={placeFilesPage}
          component={PrivateMainContainer}
        />
        <ProtectedRoute
          exact={true}
          path={placeFolderPage}
          component={PrivateMainContainer}
        />
        <ProtectedRoute
          exact={true}
          path={placePage}
          component={PrivateMainContainer}
        />
        <ProtectedRoute
          exact={true}
          path={placeFolderTopicsDiscussionPage}
          component={PrivateMainContainer}
        />
        <ProtectedRoute
          exact={true}
          strict={true}
          path={loginPageFr}
          component={Authentication}
        />
        <ProtectedRoute
          exact={true}
          strict={true}
          path={signupPageEn}
          component={Authentication}
        />
        <ProtectedRoute
          exact={true}
          strict={true}
          path={signupPageFr}
          component={Authentication}
        />
        <ProtectedRoute
          exact={true}
          strict={true}
          path={loginPageEn}
          component={Authentication}
        />
        <ProtectedRoute
          exact={true}
          strict={true}
          path={forgotPasswordPageFr}
          component={Authentication}
        />
        <ProtectedRoute
          exact={true}
          strict={true}
          path={forgotPasswordPageEn}
          component={Authentication}
        />
        <ProtectedRoute
          exact={true}
          path={resetPasswordPage}
          component={Authentication}
        />
        <ProtectedRoute
          exact={true}
          path="/"
          component={PrivateMainContainer}
        />
        <ProtectedRoute
          exact={true}
          path={setNewPasswordPage}
          component={Authentication}
        />
        <ProtectedRoute
          exact={true}
          path={invitationPage}
          component={Invitation}
        />
        <ProtectedRoute
          exact={true}
          path={magicLinkPage}
          component={MagicLink}
        />
        <Redirect
          exact={true}
          path={placeTopicDiscussionPage}
          to={placePrivatesTopicsDiscussionPage}
          strict={true}
        />
        <Redirect
          exact={true}
          path={placeTopicDiscussionPage}
          to={PrivateMainContainer}
          strict={true}
        />
        <ProtectedRoute
          exact={true}
          path={placeFolderWithoutTopic}
          component={PrivateMainContainer}
        />
        <Redirect to="/" />
      </Switch>
    </Router>
  );
};

export default AppRouter;
