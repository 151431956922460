import ApolloClient, { Resolvers } from 'apollo-client';
import gql from 'graphql-tag';
import { FETCH_MORE_FOLDER_LOADING } from '../gql/local/fetchMoreFolderLoading/query';
import { GET_FOLDERS_LOADING } from '../gql/local/foldersLoading/query';
import { GET_TOPIC_PARTICIPATION } from '../gql/local/topicParticipation/query';
import { GET_TOPICS_LOADING } from '../gql/local/topicsLoading/query';
import { GET_UPLOAD_DISCUSSIONS } from '../gql/local/topicUpload/query';
import {
  FetchMoreFolderLoading,
  FetchMoreFolderLoadingVariable,
  SetFetchMoreFolderLoadingVariable,
} from '../types/FetchMoreFolderLoadingInterface';
import SnackBarVariableInterface from '../types/SnackBarVariableInterface';
import {
  TopicParticipant,
  TopicParticipationVariable,
} from '../types/TopicInterface';

export const resolvers: Resolvers = {
  Mutation: {
    openPopin: (_, __, { cache }) => {
      const data = {
        popin: {
          isOpen: true,
          __typename: 'popin',
        },
      };
      cache.writeData({ data });
      return null;
    },
    setFolderInviteParticipantDialog: (_, { open, parentId }, { cache }) => {
      const data = {
        folderInviteParticipantDialog: {
          open,
          parentId: parentId || null,
          __typename: 'folderInviteParticipantDialog',
        },
      };
      cache.writeData({ data });
      return null;
    },
    addOrRemoveTopicsLoading: (
      _,
      { topicId, isAdd }: { topicId: number; isAdd: boolean },
      { cache }: ApolloClient<any>
    ) => {
      const topicLoadingData = cache.readQuery<{
        topicsLoading: { topicMovesLoading: number[] };
      }>({
        query: GET_TOPICS_LOADING,
      });

      if (topicLoadingData) {
        const {
          topicsLoading: { topicMovesLoading },
        } = topicLoadingData;
        const topicMovesLoadingData = isAdd
          ? [topicId, ...topicMovesLoading]
          : (topicLoadingData.topicsLoading.topicMovesLoading =
              topicMovesLoading.filter((value) => value !== topicId));
        const data = {
          topicsLoading: {
            topicMovesLoading: topicMovesLoadingData,
            __typename: 'topicsLoading',
          },
        };
        cache.writeData({ data });
      }

      return null;
    },
    addOrRemoveFoldersLoading: (
      _,
      { folderId, isAdd }: { folderId: number; isAdd: boolean },
      { cache }: ApolloClient<any>
    ) => {
      const folderLoadingData = cache.readQuery<{
        foldersLoading: { folderMovesLoading: number[] };
      }>({
        query: GET_FOLDERS_LOADING,
      });

      if (folderLoadingData) {
        const {
          foldersLoading: { folderMovesLoading },
        } = folderLoadingData;
        const folderMovesLoadingData = isAdd
          ? [folderId, ...folderMovesLoading]
          : (folderLoadingData.foldersLoading.folderMovesLoading =
              folderMovesLoading.filter((value) => value !== folderId));
        const data = {
          foldersLoading: {
            folderMovesLoading: folderMovesLoadingData,
            __typename: 'foldersLoading',
          },
        };
        cache.writeData({ data });
      }

      return null;
    },
    setSnackbar: (
      _,
      {
        open,
        duration,
        message,
        type,
        vertical,
        horizontal,
      }: SnackBarVariableInterface,
      { cache }: ApolloClient<any>
    ) => {
      const data = {
        snackBar: {
          open,
          duration: duration || 5000,
          message,
          type: type || 'SIMPLE',
          vertical,
          horizontal,
          __typename: 'snackBar',
        },
      };
      cache.writeData({ data });
    },
    showSidebar: (_, { open }, { cache }: ApolloClient<any>) => {
      const data = {
        sidebar: {
          open,
          __typename: 'Sidebar',
        },
      };
      cache.writeData({ data });
    },
    addUploadChatLoading: (_, { id, action, progress }, { cache }) => {
      const newUpload = {
        id,
        progress,
        __typename: 'UploadChatLoadingItem',
      };
      const query = gql`
        query GetCurrentChatUpload {
          currentChatUploads @client {
            id
            progress
          }
        }
      `;
      const previous = cache.readQuery({ query });
      const indexTopic = previous.currentChatUploads
        ? previous.currentChatUploads.findIndex((t: any) => t.id === id)
        : -1;

      switch (action) {
        case 'add':
          if (indexTopic === -1) {
            const data = {
              currentChatUploads: previous.currentChatUploads.concat([
                newUpload,
              ]),
            };
            cache.writeData({ data });
          }

          break;
        case 'edit':
          if (indexTopic !== -1) {
            const prevClone = Array.from(previous.currentChatUploads);
            const previousValue = prevClone[indexTopic] as any;
            prevClone[indexTopic] = {
              ...previousValue,
              progress,
            };
            cache.writeData({ data: { currentChatUploads: prevClone } });
          }

          break;
        case 'remove':
          if (indexTopic !== -1) {
            const modifiedCopy = Array.from(previous.currentChatUploads);
            modifiedCopy.splice(indexTopic, 1);
            cache.writeData({ data: { currentChatUploads: modifiedCopy } });
            return null;
          }

          break;

        default:
          break;
      }
      return newUpload;
    },

    addUploadTopicLoading: (
      _,
      {
        id,
        action,
        progress,
        filesToSendCount,
        filesSentCount,
        decrementationValue,
        fileName,
      },
      { cache }
    ) => {
      const newUpload = {
        id,
        progress,
        filesToSendCount,
        filesSentCount,
        fileName,
        __typename: 'UploadTopicLoadingItem',
      };
      const query = gql`
        query GetCurrentTopicUpload {
          currentTopicUploads @client {
            id
            progress
            filesToSendCount
            filesSentCount
          }
        }
      `;
      const previous = cache.readQuery({ query });
      const indexFolder = previous.currentTopicUploads
        ? previous.currentTopicUploads.findIndex((t: any) => t.id === id)
        : -1;

      switch (action) {
        case 'add':
          if (indexFolder === -1) {
            const data = {
              currentTopicUploads: [...previous.currentTopicUploads, newUpload], // .concat([newUpload]),
            };

            cache.writeData({ data });
          }

          break;
        case 'increment':
          if (indexFolder !== -1) {
            const prevClone: any = Array.from(previous.currentTopicUploads);
            const previousValue = prevClone[indexFolder];
            const filesSent = previousValue.filesSentCount + 1;
            prevClone[indexFolder] = {
              ...previousValue,
              filesSentCount: filesSent,
            };
            if (filesSent === previousValue.filesToSendCount) {
              prevClone.splice(indexFolder, 1);
              cache.writeData({ data: { currentTopicUploads: prevClone } });
              return null;
            }
            cache.writeData({ data: { currentTopicUploads: prevClone } });
          }
          break;
        case 'incrementByte':
          if (indexFolder !== -1) {
            const prevClone = Array.from(previous.currentTopicUploads);
            const previousValue = prevClone[indexFolder] as any;
            prevClone[indexFolder] = {
              ...previousValue,
              progress,
              fileName,
            };
            cache.writeData({ data: { currentTopicUploads: prevClone } });
          }
          break;
        case 'editName':
          if (indexFolder !== -1) {
            const prevClone = Array.from(previous.currentTopicUploads);
            const previousValue = prevClone[indexFolder] as any;
            prevClone[indexFolder] = {
              ...previousValue,
              fileName,
            };
            cache.writeData({ data: { currentTopicUploads: prevClone } });
          }
          break;
        case 'decrement':
          if (indexFolder !== -1) {
            const prevClone = Array.from(previous.currentTopicUploads);
            const previousValue: any = prevClone[indexFolder];
            // eslint-disable-next-line no-shadow
            const filesToSendCount =
              previousValue.filesToSendCount - (decrementationValue || 1);
            // eslint-disable-next-line no-shadow
            const progress = parseInt(
              (
                (previousValue.filesSentCount * 100) /
                filesToSendCount
              ).toString(),
              10
            );
            prevClone[indexFolder] = {
              ...previousValue,
              filesToSendCount,
              progress,
            };
            if (
              progress === 100 ||
              filesToSendCount <= previousValue.filesSentCount
            ) {
              prevClone.splice(indexFolder, 1);
              cache.writeData({ data: { currentTopicUploads: prevClone } });
              return null;
            }
            cache.writeData({ data: { currentTopicUploads: prevClone } });
          }
          break;
        case 'edit':
          if (indexFolder !== -1) {
            const prevClone = Array.from(previous.currentTopicUploads);
            const previousValue = prevClone[indexFolder] as any;
            prevClone[indexFolder] = {
              ...previousValue,
              progress,
              filesSentCount,
            };
            cache.writeData({ data: { currentTopicUploads: prevClone } });
          }
          break;
        case 'remove':
          if (indexFolder !== -1) {
            const modifiedCopy = Array.from(previous.currentTopicUploads);
            modifiedCopy.splice(indexFolder, 1);
            cache.writeData({ data: { currentTopicUploads: modifiedCopy } });
            return null;
          }

          break;

        default:
          break;
      }
      return newUpload;
    },
    addTopicParticipation: (
      _: any,
      { id, isParticipating }: TopicParticipationVariable,
      { cache }: ApolloClient<any>
    ) => {
      cache.writeQuery<
        { topicParticipation: TopicParticipant },
        TopicParticipationVariable
      >({
        query: GET_TOPIC_PARTICIPATION,
        data: {
          topicParticipation: {
            id,
            isParticipating: Boolean(isParticipating),
            __typename: 'topicParticipantItem',
          },
        },
        variables: {
          id,
        },
      });
    },
    setFetchMoreFolderLoading: (
      _: any,
      { id, isInRecycle, isLoading }: SetFetchMoreFolderLoadingVariable,
      { cache }: ApolloClient<any>
    ) => {
      cache.writeQuery<FetchMoreFolderLoading, FetchMoreFolderLoadingVariable>({
        query: FETCH_MORE_FOLDER_LOADING,
        data: {
          fetchMoreFolderLoading: {
            id,
            isLoading: Boolean(isLoading),
            __typename: 'fetchMoreFolderLoading',
          },
        },
        variables: {
          id,
          isInRecycle,
        },
      });
    },
  },
  Query: {
    UploadDiscussions: (_: any, __: any, { cache }: ApolloClient<any>) => {
      const data = cache.readQuery({
        query: GET_UPLOAD_DISCUSSIONS,
      });
      return data;
    },
  },
  Folder: {
    unread: () => 0,
    hasFolderLocked: () => false,
    hasMention: () => false,
  },
  Place: {
    unread: () => 0,
  },
};
