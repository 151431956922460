import gql from 'graphql-tag';

export const GET_FIRST_SELECTED_TOPIC = gql`
  {
    firstSelectedTopic @client {
      folderTopics {
        topicId
        folderId
        isInRecycle
      }
    }
  }
`;

export const GET_CURRENT_LOCATION_PATH = gql`
  {
    currentLocation @client {
      placeId
      folderId
      topicId
      isInRecycle
    }
  }
`;

export const GET_CURRENT_PLACE_ID = gql`
  {
    currentLocation @client {
      placeId
    }
  }
`;

export const GET_CURRENT_FOLDER_ID = gql`
  {
    currentLocation @client {
      folderId
    }
  }
`;

export const GET_CURRENT_TOPIC_ID = gql`
  {
    currentLocation @client {
      topicId
    }
  }
`;

export const GET_RECENT_VISITED_FOLDERS = gql`
  {
    recentVisitedFolders @client {
      folderIds
    }
  }
`;

export const GET_RECENT_VISITED_TOPICS = gql`
  {
    recentVisitedTopics @client {
      topicIds
    }
  }
`;

export const GET_RECENT_VISITED_PLACES = gql`
  {
    recentVisitedPlaces @client {
      placeIds
    }
  }
`;
