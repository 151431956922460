import gql from 'graphql-tag';
import {
  USER_BASIC_FRAGMENT,
  USER_NAME_BASIC_FRAGMENT,
} from '../User/fragment';

export const MESSAGE_FILE_CONTENT_FRAGMENT = gql`
  fragment messageFileInfos on MessageFile {
    id
    name
    path
    type
    extension
    size
    url
    approved
    version
    boxFileId
    checksum
    storageProvider
    storageFileId
    isAttachedFile
    discussionId
    dbxUrlPath
    # hashId
    storageExtraInfo {
      revisionId
    }
  }
`;

export const TOPIC_OPERATION_FRAGMENT = gql`
  fragment topicOperationInfos on TopicOperation {
    agent {
      id
    }
    from {
      id
      name
    }
    target {
      id
      name
    }
    responsibleEdit {
      oldResponsible {
        ...userBasicInfos
      }
      newResponsible {
        ...userBasicInfos
      }
    }
    dueDateEdit {
      startDate
      oldStartDate
      endDate
      oldEndDate
    }
    createdAt
    type
    extraField
  }
  ${USER_BASIC_FRAGMENT}
`;

export const MESSAGE_CONTENT_FRAGMENT = gql`
  fragment contentInfos on MessageContent {
    ... on MessageFile {
      ...messageFileInfos
    }
    ... on MessageText {
      text
    }
    ... on SharedEmail {
      id
      sender
      subject
      recipient
      content
    }
    ... on TopicOperation {
      ...topicOperationInfos
    }
  }
  ${MESSAGE_FILE_CONTENT_FRAGMENT}
  ${TOPIC_OPERATION_FRAGMENT}
`;

export const MESSAGE_FRAGMENT = gql`
  fragment messageInfos on Message {
    id
    discussionId
    placeId
    user {
      ...userBasicInfos
    }
    type
    version
    date
    updatedAt
    associatedFileId
    associatedFileName
    draftRawData
    withRelease
    content {
      ...contentInfos
    }
    text
    quoteMessageText
  }
  ${MESSAGE_CONTENT_FRAGMENT}
  ${USER_BASIC_FRAGMENT}
`;
export const QUOTE_MESSAGE_FRAGMENT = gql`
  fragment quoteMessageInfos on Message {
    quoteMessage {
      id
      type
      user {
        ...userBasicInfos
      }
      content {
        ...contentInfos
      }
      placeId
    }
  }
  ${MESSAGE_CONTENT_FRAGMENT}
  ${USER_BASIC_FRAGMENT}
`;

export const MESSAGE_BASIC_FRAGMENT = gql`
  fragment messageBasicInfos on Message {
    id
    user {
      ...userNameBasicInfos
    }
    type
    version
    date
    updatedAt
    content {
      ...contentInfos
    }
  }
  ${MESSAGE_CONTENT_FRAGMENT}
  ${USER_NAME_BASIC_FRAGMENT}
`;
