import createStyles from '@material-ui/styles/createStyles';
import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
      padding: '4%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },

    errorImage: {
      height: 300,
      position: 'relative',
      left: -12,
      marginBottom: 20,
    },

    textElement: {
      marginTop: 16,
      marginBottom: 16,
      textAlign: 'center',
      fontSize: '20px',
      fontFamily: 'Avenir Roman',
      lineHeight: '27px',
      fontWeight: 500,
      color: '#002081',
      letterSpacing: '-0.2px',

      '& a': {
        color: '#002081',
      },
    },

    reloadButton: {
      marginTop: 24,
      marginBottom: 24,
      height: 38,
      fontSize: '15px',
      fontFamily: 'Avenir Roman',
      letterSpacing: '1px',
    },
  })
);
