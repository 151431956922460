/* eslint-disable no-underscore-dangle */
import { SnackbarOrigin } from '@material-ui/core/Snackbar';
import ApolloClient from 'apollo-client';
import { History } from 'history';
import { isEqual, isObject, transform } from 'lodash/fp';
import { IntlShape } from 'react-intl';
import stringSimilarity from 'string-similarity';
import isEmail from 'validator/lib/isEmail';
import { config } from '../common/config';
import { GetAllTopicName_topicNameInFolder } from '../gql/folder/types/GetAllTopicName';
import { GetFolderActivityLogs_folderActivityLogs_data } from '../gql/notificationCenter/types/GetFolderActivityLogs';
import { userBasicInfos } from '../gql/User/types/userBasicInfos';
import { userInfos } from '../gql/User/types/userInfos';
import accessIcon from '../images/icon/access.svg';
import excelIcon from '../images/icon/excel-icon.svg';
import outlookIcon from '../images/icon/outlook.svg';
import pptIcon from '../images/icon/powerpoint-icon.svg';
import wordIcon from '../images/icon/word-icon.svg';
import { LocationStateInterface } from '../types/LocationStateInterface';
import { PlaceInterface } from '../types/PlaceInterface';
import SnackBarVariableInterface, {
  SnackBarType,
} from '../types/SnackBarVariableInterface';
import TopicInterface from '../types/TopicInterface';
import UserInterface, { UserBasicInfos } from '../types/UserInterface';
import { getMatchingPath } from './location/locationUtils';

export const regexPeriod = /Due in ((1 day)|(\d+ (days)))/gim;

export const DUE_IN = /Due in /gim;

export const OPEN_MARK = `<b style="color: #3697ff">`;

export const CLOSE_MARK = `</b>`;

export const getIcon = (extension: string) => {
  switch (extension) {
    case 'doc':
    case 'docx':
    case 'asd':
    case 'docm':
    case 'dotx':
    case 'dotm':
    case 'docb':
    case 'dot':
    case 'wbk':
    case 'svd':
    case 'wll':
    case 'cnv':
      return wordIcon;
    case 'ppt':
    case 'pptx':
    case 'pptm':
    case 'potx':
    case 'potm':
    case 'ppam':
    case 'ppsx':
    case 'ppsm':
    case 'sldx':
    case 'sldm':
      return pptIcon;
    case 'xls':
    case 'xlsx':
    case 'xlm':
    case 'xlsm':
    case 'xlt':
    case 'xltx':
    case 'xltm':
      return excelIcon;
    case 'accdb':
    case 'accde':
    case 'accdt':
    case 'accdr':
      return accessIcon;
    case 'pst':
    case 'ost':
    case 'olm':
    case 'oft':
    case 'obi':
    case 'oab':
    case 'iaf':
    case 'prf':
      return outlookIcon;

    default:
      return null;
  }
};

export const getFirstName = (fullFirstName: string | null) => {
  if (!fullFirstName) {
    return null;
  }
  const listFirstName = fullFirstName.split(' ');
  return listFirstName.length > 1 ? listFirstName[0] : fullFirstName;
};

export const getUserNameMention = (user: UserInterface | UserBasicInfos) => {
  const { firstName, lastName, email } = user;
  if (
    firstName &&
    ((lastName && lastName.trimEnd().length === 0) || !lastName)
  ) {
    return getFirstName(firstName);
  }
  if (
    lastName &&
    ((firstName && firstName.trimEnd().length === 0) || !firstName)
  ) {
    return lastName;
  }
  if (firstName && lastName) {
    return `${getFirstName(firstName) || ''} ${lastName.substring(0, 1)}.`;
  }
  return email.trim();
};

export const getFullMentionedUsersByName = (
  mentionedUsers: userBasicInfos[],
  userName: string
) => mentionedUsers.find((user) => getUserNameMention(user) === userName);

export const getAvatarUrl = (avatar?: string | null) => {
  // const avatarParse = avatar ? JSON.parse(avatar) : null;
  // return avatarParse && avatarParse.secure_url ? avatarParse.secure_url : null;

  const avatarParse = avatar ? JSON.parse(avatar) : null;

  if (avatarParse && avatarParse.secure_url) {
    let splitUrl = avatarParse.secure_url.split('/');
    const imageName = splitUrl[splitUrl.length - 1];

    const optimizationUrl = ['w_100', imageName];

    splitUrl = splitUrl.slice(0, -2);

    return splitUrl.concat(optimizationUrl).join('/');
  }

  return null;
};

export const createFullMentionPattern = (participants: userBasicInfos[]) => {
  let regex = '@';
  const participantWithoutAll = participants.filter(
    (participant) => +participant.id !== -1
  );
  const pLength = participantWithoutAll.length;
  if (pLength) {
    participantWithoutAll.forEach((p, key) => {
      regex = `${regex}${getUserNameMention(p)}${
        pLength === key + 1 ? '' : '|@'
      }`;
    });
  }
  return regex;
};

export const customSuggestionsFilter = (
  searchValue: string,
  suggestions: any[]
) => {
  const value = searchValue.toLowerCase();
  const filteredSuggestions = suggestions.filter((suggestion) => {
    const { firstName, lastName, email } = suggestion;
    return (
      !value ||
      (firstName || lastName
        ? firstName?.toLowerCase().startsWith(value) ||
          lastName?.toLowerCase().startsWith(value)
        : email.toLowerCase().startsWith(value))
    );
  });
  return filteredSuggestions.slice(0, filteredSuggestions.length);
};

export const highlightMention = (
  input: string,
  regex: string,
  participants: Map<string, userBasicInfos>,
  externalUserMessage: string,
  withStrongTag = false,
  withStyle?: boolean,
  id?: number,
  formatMessage?: IntlShape['formatMessage'],
  isReplyInOtherTopic?: boolean
) => {
  if (!regex || !participants.size) {
    return input;
  }

  const regexWithAll = `${regex}|@all|@tous|@mention:\\-1`;
  const result = input.replace(new RegExp(regexWithAll, 'g'), (user) => {
    const userSplitted = user.split(':');
    if (userSplitted && userSplitted.length >= 2) {
      const userId = userSplitted[1];

      if (+userId === -1) {
        const allMessage = formatMessage
          ? formatMessage({
              id: 'common.all',
              defaultMessage: 'all',
            })
          : 'all';
        if (isReplyInOtherTopic) {
          return `@${allMessage}`;
        }
        return `<span class='userMentionMe'>@${allMessage}</span>`;
      }

      const participant = participants.get(userId);

      let isMe;
      if (id) {
        isMe = +userId === +id;
      }
      if (participant) {
        if (withStrongTag) {
          return `<strong>@${getUserNameMention(participant)}</strong>`;
        }
        if (withStyle) {
          if (isMe) {
            return `<span class='userMentionMe'>@${getUserNameMention(
              participant
            )}</span>`;
          }
          return `<span class='userMention'>@${getUserNameMention(
            participant
          )}</span>`;
        }
        return `@${getUserNameMention(participant)}`;
      }
    } else if (
      userSplitted &&
      userSplitted.length === 1 &&
      (userSplitted[0] === '@all' || userSplitted[0] === '@tous') &&
      formatMessage
    ) {
      if (isReplyInOtherTopic) {
        return `@${formatMessage({
          id: 'common.all',
          defaultMessage: 'all',
        })}`;
      }
      return `<span class='userMentionMe'>@${formatMessage({
        id: 'common.all',
        defaultMessage: 'all',
      })}</span>`;
    }

    return '';
  });

  return result.replace(new RegExp('@mention:([0-9]+)', 'g'), () => {
    return externalUserMessage;
  });
};

export const renderTextWithMentionFn = (
  text: string,
  regex: string,
  participants: Map<string, userBasicInfos>,
  externalUserMessage: string,
  withStrongTag = true,
  withStyle?: boolean,
  id?: number,
  formatMessage?: any,
  isReplyInOtherTopic?: boolean
) => {
  let highlightedMarkup = text
    .trim()
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;');
  if (!regex) {
    return highlightedMarkup;
  }
  highlightedMarkup = withStrongTag
    ? highlightMention(
        highlightedMarkup,
        regex,
        participants,
        externalUserMessage,
        false,
        withStyle,
        id,
        formatMessage,
        isReplyInOtherTopic
      )
    : highlightMention(
        highlightedMarkup,
        regex,
        participants,
        externalUserMessage,
        false,
        withStyle,
        id,
        formatMessage,
        isReplyInOtherTopic
      );
  highlightedMarkup = highlightedMarkup.replace(
    new RegExp(`\\n(${CLOSE_MARK})?$`),
    '\n\n'
  );
  return highlightedMarkup;
};

export const replaceMention = (
  input: string,
  regex: string,
  participants: Map<string, userBasicInfos>,
  formatMessage: IntlShape['formatMessage']
) => {
  if (!regex || !participants.size) {
    return input;
  }

  const externalUserMessage = formatMessage({
    id: 'user.extern',
    defaultMessage: '@external-user',
  });

  const regexWithAll = `${regex}|@all|@tous|@mention:\\-1`;

  const result = input.replace(new RegExp(regexWithAll, 'g'), (user) => {
    const userSplitted = user.split(':');

    if (userSplitted && userSplitted.length >= 2) {
      const userId = userSplitted[1];

      if (+userId === -1) {
        const allMessage = formatMessage({
          id: 'common.all',
          defaultMessage: 'all',
        });
        return `@${allMessage}`;
      }

      const participant = participants.get(userId);
      if (participant) {
        return getUserNameMention(participant) || '';
      }
    } else if (
      userSplitted &&
      userSplitted.length === 1 &&
      (userSplitted[0] === '@all' || userSplitted[0] === '@tous') &&
      formatMessage
    ) {
      return `@${formatMessage({
        id: 'common.all',
        defaultMessage: 'all',
      })}`;
    }
    return '';
  });

  return result.replace(new RegExp('@mention:([0-9]+)', 'g'), () => {
    return externalUserMessage;
  });
};

export const handleParticipantMap = (participants: UserBasicInfos[]) => {
  const topicParticipantsMap: Map<string, UserBasicInfos> = new Map<
    string,
    UserBasicInfos
  >();
  participants.forEach((participant) => {
    topicParticipantsMap.set(participant.id, participant);
  });

  return topicParticipantsMap;
};

export const createRegexToParsingMentionWithId = (
  participants: Pick<UserBasicInfos, 'id'>[]
) => {
  if (participants && participants.length > 0) {
    let regex = '@mention:';
    const pLength = participants.length;
    for (let i = 0; i < pLength; i++) {
      const participant = participants[i];
      regex = `${regex}${participant.id}${
        pLength === i + 1 ? '' : '|@mention:'
      }`;
    }
    return regex;
  }
};

export const isEmailValid = (email: string) => isEmail(email);

// export const isEmailHairun = (email: string): boolean => {
//   const emailHairun = 'hairun-technology.com';
//   const splitEmail = email.split('@');
//   const maxLength = splitEmail.length - 1;
//   if (isEmailValid(email) && splitEmail[maxLength] === emailHairun) {
//     return true;
//   }
//   return false;
// };

export const userSorter =
  (isDesc?: boolean) => (user1: userBasicInfos, user2: userBasicInfos) => {
    const { firstName, email, lastName } = user1;
    const { firstName: firstName2, email: email2, lastName: lastName2 } = user2;
    const name = firstName || lastName ? `${firstName}${lastName}` : null;
    const name2 = firstName2 || lastName2 ? `${firstName2}${lastName2}` : null;

    const resp =
      name === name2
        ? email < email2
          ? -1
          : email2 < email
          ? 1
          : 0
        : name === null
        ? -1
        : name2 === null
        ? 1
        : name < name2
        ? -1
        : 1;

    return isDesc ? -resp : resp;
  };

export const showSnackbarMessage = (
  client: ApolloClient<any>,
  type: SnackBarType,
  message: string,
  duration?: number,
  customOrigin?: SnackbarOrigin
) => {
  client.writeData({
    data: {
      snackBar: {
        __typename: 'SnackBar',
        open: true,
        type,
        duration: duration || 2000,
        message,
        horizontal: customOrigin?.horizontal || 'right',
        vertical: customOrigin?.vertical || 'top',
      } as SnackBarVariableInterface,
    },
  });
};

export const getUserName = (
  user: Pick<userInfos, 'lastName' | 'email' | 'firstName'>
) => {
  const { firstName, lastName, email } = user;
  return firstName || lastName ? `${firstName || ''} ${lastName || ''}` : email;
};

export const getFileName = ({ name }: File) =>
  name && name.substr(0, name.lastIndexOf('.'));

export const isSharePlaceDomain = (url: any) => {
  if (
    !url ||
    typeof url !== 'string' ||
    !window ||
    !window.location ||
    !window.location.href
  ) {
    return false;
  }
  const { frontDomain, electronDomain } = config;
  return url.startsWith(frontDomain) || url.startsWith(electronDomain);
};

export const goToUrl = (
  url: URL,
  history: History<any>,
  places: PlaceInterface[]
) => {
  // @ts-ignore
  const matchingPath = getMatchingPath(url);

  if (!matchingPath) {
    return;
  }

  const {
    place: placeId,
    folder: folderId,
    discussion: topicId,
  } = matchingPath.params;

  if (
    !placeId ||
    !places.some((place: PlaceInterface) => +place.id === +placeId)
  ) {
    return;
  }

  if (placeId && folderId && topicId) {
    history.push(`/place/${placeId}/folder/${folderId}/topics/${topicId}`, {
      fetchMoreFolders: true,
      fetchMoreTopics: true,
      fetchMoreMessages: true,
      firstDiscussionLoad: true,
    } as LocationStateInterface);
  } else if (placeId && folderId) {
    history.push(`/place/${placeId}/folder/${folderId}`, {
      fetchMoreFolders: true,
      fetchMoreTopics: true,
    } as LocationStateInterface);
  } else if (placeId && topicId) {
    history.push(`/place/${placeId}/privates/topics/${topicId}`, {
      fetchMoreMessages: true,
      firstDiscussionLoad: true,
    } as LocationStateInterface);
  }
};

export const getFileSize = (
  size: number,
  formatMessage: IntlShape['formatMessage']
) => {
  if (size < 1000) {
    const convertedSize = Number(size);
    return `${convertedSize.toFixed(2)} ${formatMessage({
      id: 'byte.file',
      defaultMessage: 'bytes',
    })}`;
  }
  if (size > 999 && size < 1000000) {
    const newSize = size / 1000;
    const convertedSize = Number(newSize);
    return `${convertedSize.toFixed(2)} ${formatMessage({
      id: 'kb.file',
      defaultMessage: 'Kb',
    })}`;
  }
  if (size > 999999 && size < 1000000000) {
    const newSize = size / 1000000;
    const convertedSize = Number(newSize);
    return `${convertedSize.toFixed(2)} ${formatMessage({
      id: 'mb.file',
      defaultMessage: 'Mb',
    })}`;
  }
  const newSize = size / 1000000000;
  const convertedSize = Number(newSize);
  return `${convertedSize.toFixed(2)} ${formatMessage({
    id: 'gb.file',
    defaultMessage: 'Gb',
  })}`;
};

export const checkIfDuplicatedThread = (
  inFolder: GetAllTopicName_topicNameInFolder,
  title: string,
  threshold: number
): Array<{ percent: number; topicId: number | string; topicTitle: string }> => {
  const { topics } = inFolder;

  return topics.reduce<
    Array<{
      percent: number;
      topicId: number | string;
      topicTitle: string;
    }>
  >((acc, topic) => {
    if (!topic.removed) {
      const percent = stringSimilarity.compareTwoStrings(
        topic.title.toLowerCase(),
        title.toLowerCase()
      );
      if (percent >= threshold) {
        return [
          ...acc,
          { percent, topicId: topic.id, topicTitle: topic.title },
        ];
      }
    }
    return acc;
  }, []);
};

export const checkIfDuplicatedOrphanThread = (
  inFolder: TopicInterface[],
  title: string,
  threshold: number
): Array<{ percent: number; topicId: number | string; topicTitle: string }> => {
  const result: Array<{
    percent: number;
    topicId: number | string;
    topicTitle: string;
  }> = [];
  inFolder.forEach((topic) => {
    // if (!topic.removed) {
    const percent = stringSimilarity.compareTwoStrings(
      topic.title.toLowerCase(),
      title.toLowerCase()
    );
    if (percent >= threshold) {
      result.push({ percent, topicId: topic.id, topicTitle: topic.title });
    }
    // }
  });
  return result;
};

/**
 * Please fix me
 * This is actually a very very bad function
 * I will sort it out later
 */
export const isProd = () => config.isProd;

export const extractMailInUrlSearch = (urlSearch: string): string => {
  const splittedSearch = urlSearch.split('&');

  return splittedSearch.reduce<string>((acc, splitted) => {
    if (splitted.includes('email')) {
      const mailIn = splitted.split('email=')[1];
      if (isEmailValid(mailIn)) {
        return mailIn;
      }
    }
    return acc;
  }, '');
};

export const getFullName = (
  firstName: string | null | undefined,
  lastName: string | null | undefined,
  email: string
) => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  if (firstName && !lastName) {
    return firstName;
  }
  if (lastName && !firstName) {
    return lastName;
  }
  return email;
};

export const convertPercentage = (total: number, simpleNumber: number) => {
  return (simpleNumber * 100) / total;
};

// Safe parse json
export const safeParseJson = (json: string) => {
  try {
    return JSON.parse(json);
  } catch (error) {
    return null;
  }
};

export const isUserHasLastTopicLocked = (
  userId: any,
  placeId: any,
  topicId: any
) => {
  const topicLocked = localStorage.getItem(`user:${userId}:lastTopicLocked`);
  const lastTopicLocked =
    userId && topicLocked ? JSON.parse(topicLocked) : null;

  if (lastTopicLocked) {
    return (
      +lastTopicLocked.placeId !== +placeId ||
      +lastTopicLocked.topicId !== +topicId
    );
  }
  return false;
};

export const setLastLockedFile = (topicId: any, placeId: any) => {
  const userId = localStorage.getItem('@userId');
  localStorage.setItem(
    `user:${userId}:lastTopicLocked`,
    JSON.stringify({
      placeId,
      topicId,
    })
  );
};

export const removeLocalLockedFile = (topicId: any) => {
  const userId = localStorage.getItem('@userId');
  const key = `user:${userId}:lastTopicLocked`;
  const lastTopicLocked = localStorage.getItem(key);
  const topicLocked = lastTopicLocked ? JSON.parse(lastTopicLocked) : null;
  if (topicLocked && +topicLocked.topicId === +topicId) {
    localStorage.removeItem(key);
  }
};

export const removeDuplicatedItemsById = (
  items: GetFolderActivityLogs_folderActivityLogs_data[]
) => {
  let filteredIds: number[] = [];
  return items.filter((item) => {
    if (filteredIds.indexOf(+item.id) < 0) {
      filteredIds = [+item.id, ...filteredIds];
      return true;
    }
    return false;
  });
};

// @ts-ignore
const _transform = transform.convert({
  cap: false,
});

export const differenceObject = (targetObj: any, baseObj: any) => {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return _transform(iteratee(baseObj), null, targetObj);
};

const iteratee = (baseObj: any) => (result: any, value: any, key: any) => {
  if (!isEqual(value, baseObj[key])) {
    const valIsObj = isObject(value) && isObject(baseObj[key]);
    // eslint-disable-next-line no-param-reassign
    result[key] =
      valIsObj === true ? differenceObject(value, baseObj[key]) : value;
  }
};

/**
 * Transform first letter of text to uppercase
 */
export const toFirstLetterUppercaseFn = (text: string) => {
  return `${text.substr(0, 1).toUpperCase()}${text.substr(1).toLowerCase()}`;
};

export function findLastIndexOfArrayFn<T>(
  array: Array<T>,
  predicate: (value: T, index: number, obj: T[]) => boolean
): number {
  let l = array.length;
  while (l--) {
    if (predicate(array[l], l, array)) return l;
  }
  return -1;
}
