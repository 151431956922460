import { BaseQueryOptions } from '@apollo/react-common';
import { useQuery } from '@apollo/react-hooks';
import { useMediaQuery } from '@material-ui/core';
import { GET_ME_QUERY } from '../../gql/User/query';
import { GetMeQuery } from '../../gql/User/types/GetMeQuery';

const useMeConsumer = (
  notifyOnNetworkStatusChange?: boolean,
  fetchPolicy: BaseQueryOptions['fetchPolicy'] = 'cache-only',
  skip = false
) => {
  const queryMe = useQuery<GetMeQuery>(GET_ME_QUERY, {
    fetchPolicy,
    notifyOnNetworkStatusChange,
    skip,
  });
  const isMini = useMediaQuery('@media (max-width: 791px)');
  const { data: meData } = queryMe;
  const user = meData?.me || null;
  const currentUser = user
    ? {
        ...user,
        useSimplifiedView: isMini ? 1 : user.useSimplifiedView,
        isSimplifiedView: Boolean(user.useSimplifiedView),
      }
    : null;
  return {
    isMini,
    ...queryMe,
    currentUser,
    data: queryMe.data
      ? {
          me: currentUser,
        }
      : null,
  };
};

export default useMeConsumer;
