import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import { difference } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { GET_HELP_TOUR } from '../../gql/local/helpTour/query';
import { MUTATION_SET_HELP_TOUR_DONE } from '../../gql/User/mutation';
import { MutationSetHelpTourDone } from '../../gql/User/types/MutationSetHelpTourDone';
import {
  stepTourIntToStepFn,
  stepTourToIntFn,
} from '../../services/HelpTourUtils';
import { StepTourType } from '../../types/StepTourType';
import { ReactourContext } from './context';
import { HelpTourDataInterface } from './interfaces';
import { getHelpTourData, persitToLocalStorage } from './useHelpTourLogic';

const ReactourProvider: FC = ({ children }) => {
  const [listeningUpdate, setListeningUpdate] = useState('0');

  const client = useApolloClient();

  const { data: helpTourData } = useQuery<HelpTourDataInterface>(
    GET_HELP_TOUR,
    {
      fetchPolicy: 'cache-only',
    }
  );

  const [mutationDoneHelpTour] = useMutation<MutationSetHelpTourDone>(
    MUTATION_SET_HELP_TOUR_DONE,
    {
      optimisticResponse: {
        setHelpTourDone: {
          __typename: 'User',
          id: localStorage.getItem('@userId') || '0',
          hasDoneHelpTour: true,
        },
      },
    }
  );

  const helpTourStep = helpTourData?.currentHelpTourStep;
  const later = !!helpTourData?.currentHelpTourStep.later;
  const replayHelpTour = !!helpTourData?.currentHelpTourStep.replayHelpTour;
  const currentHelpTourStep = !later ? helpTourStep?.currentStep || null : null;
  const doneSteps = useMemo(
    () => helpTourStep?.doneSteps || [],
    [helpTourStep?.doneSteps]
  );
  const helpTourTopicId = helpTourStep?.helpTourTopicId || null;
  const paused = !!helpTourStep?.paused;

  const remainingSteps = difference(
    [
      stepTourToIntFn('fileImported'),
      stepTourToIntFn('fileShared'),
      stepTourToIntFn('fileDiscussion'),
    ],
    doneSteps
  )
    .map<StepTourType | null>((stepIndex) => {
      // Take the entry of each stage
      const step = stepTourIntToStepFn(stepIndex);
      switch (step) {
        case 'fileImported':
          return 'importFile1';
        case 'fileShared':
          return 'shareFile1';
        case 'fileDiscussion':
          return 'discussFile';
        default:
          return null;
      }
    })
    .filter((step) => step);

  const finished =
    doneSteps.includes(stepTourToIntFn('fileImported')) &&
    doneSteps.includes(stepTourToIntFn('fileShared')) &&
    doneSteps.includes(stepTourToIntFn('fileDiscussion'));

  useEffect(() => {
    const data = getHelpTourData();
    if (data) {
      client.writeData<HelpTourDataInterface>({ data });
    }
  }, [client]);

  useEffect(() => {
    if (finished) {
      mutationDoneHelpTour();
    }
  }, [finished, mutationDoneHelpTour]);

  const setHelpTourStep = useCallback(
    (
      step: StepTourType,
      done?: number[],
      topicId?: string | null,
      pausedParam = false,
      laterParam = false
    ) => {
      let data: HelpTourDataInterface = {
        currentHelpTourStep: {
          currentStep: null,
          doneSteps: [],
          helpTourTopicId: null,
          paused: pausedParam,
          later: laterParam,
          replayHelpTour,
          __typename: 'currentHelpTourStep',
        },
      };

      // If all steps done, pause the help tour
      if (step && step !== 'end' && remainingSteps.length === 0) {
        data = {
          currentHelpTourStep: {
            currentStep: currentHelpTourStep,
            doneSteps,
            helpTourTopicId,
            paused: true,
            later: laterParam,
            replayHelpTour: false,
            __typename: 'currentHelpTourStep',
          },
        };
      } else if (step !== null) {
        data = {
          currentHelpTourStep: {
            currentStep: step,
            doneSteps: Array.from(new Set([...doneSteps, ...(done || [])])),
            helpTourTopicId:
              topicId ||
              helpTourData?.currentHelpTourStep.helpTourTopicId ||
              null,
            paused: pausedParam,
            later: laterParam,
            replayHelpTour,
            __typename: 'currentHelpTourStep',
          },
        };
      }

      client.writeData<HelpTourDataInterface>({ data });

      persitToLocalStorage(data);
    },
    [
      client,
      currentHelpTourStep,
      doneSteps,
      helpTourData?.currentHelpTourStep.helpTourTopicId,
      helpTourTopicId,
      remainingSteps.length,
      replayHelpTour,
    ]
  );

  /**
   * Pause help tour until other step is setted
   */
  const pauseHelpTour = useCallback(() => {
    client.writeData<HelpTourDataInterface>({
      data: {
        currentHelpTourStep: {
          currentStep: currentHelpTourStep,
          doneSteps,
          helpTourTopicId,
          paused: true,
          later: false,
          replayHelpTour,
          __typename: 'currentHelpTourStep',
        },
      },
    });
  }, [client, currentHelpTourStep, doneSteps, helpTourTopicId, replayHelpTour]);

  /**
   * Skip help tour until other step is setted
   */
  const skipHelpTour = useCallback(
    (value = true) => {
      if (helpTourStep) {
        const data: HelpTourDataInterface = {
          currentHelpTourStep: {
            ...helpTourStep,
            later: value,
            __typename: 'currentHelpTourStep',
          },
        };
        client.writeData<HelpTourDataInterface>({
          data: {
            ...data,
          },
        });
        persitToLocalStorage(data, true);
      }
    },
    [client, helpTourStep]
  );

  const replayHelpTourFunction = useCallback(() => {
    const data: HelpTourDataInterface = {
      currentHelpTourStep: {
        currentStep: 'start',
        doneSteps: [],
        helpTourTopicId: null,
        paused: false,
        later: false,
        replayHelpTour,
        __typename: 'currentHelpTourStep',
      },
    };
    client.writeData<HelpTourDataInterface>({ data });
    persitToLocalStorage(data, true);
  }, [client, replayHelpTour]);

  const refreshHelpTour = useCallback(
    () => setListeningUpdate((current) => (+current + 1).toString()),
    []
  );

  return (
    <ReactourContext.Provider
      value={{
        setHelpTourStep,
        pauseHelpTour,
        refreshHelpTour,
        skipHelpTour,
        replayHelpTourFunction,
        paused,
        finished,
        currentHelpTourStep,
        doneSteps,
        helpTourTopicId,
        remainingSteps,
        listeningUpdate,
        later,
        replayHelpTour,
      }}
    >
      {children}
    </ReactourContext.Provider>
  );
};

export default ReactourProvider;
