import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(() => ({
  containerSharePlaceLoading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    backgroundColor: '#FFFFFF',
  },
  containerDarkLoading: {
    backgroundColor: '#465057',
  },
}));
