/* eslint-disable import/no-extraneous-dependencies */
import { ApolloLink, FetchResult, Observable, Operation } from 'apollo-link';
import { IpcRenderer, ipcRenderer } from 'electron';
import { deserializeError } from 'serialize-error';
import { ZenObservable } from 'zen-observable-ts';

export interface ApolloIpcLinkOptions {
  authToken: string | null;
  channel?: string;
  ipc?: IpcRenderer;
}

export interface SerializableGraphQLRequest {
  query: any;
  variables?: Record<string, any>;
  operationName?: string;
}

const RANDOM_ID = Math.random().toString(36);

export class IpcLink extends ApolloLink {
  private ipc: IpcRenderer;

  private counter = 0;

  private channel = 'graphql';

  private authToken: string | null;

  private observers: Map<
    string,
    ZenObservable.SubscriptionObserver<FetchResult>
  > = new Map();

  constructor(options: ApolloIpcLinkOptions) {
    super();

    this.ipc = options.ipc || ipcRenderer;
    this.authToken = options.authToken;
    if (typeof options.channel !== 'undefined') {
      this.channel = options.channel;
    }

    this.ipc.on(`graphql-from-worker`, this.onResponse);
  }

  public request(
    operation: Operation
    // forward?: NextLink
  ): Observable<FetchResult> | null {
    return new Observable(
      (observer: ZenObservable.SubscriptionObserver<FetchResult>) => {
        this.counter += 1;
        const { authToken } = this;
        const ipcId = `${RANDOM_ID}-${this.counter}`;
        const { operationName, query, variables } = operation;
        const request: SerializableGraphQLRequest = {
          operationName,
          variables,
          query,
        };
        this.observers.set(ipcId, observer);

        this.ipc.send(`graphql-to-worker`, {
          ipcId,
          request,
          authToken,
        });
      }
    );
  }

  protected onResponse = (_: any, response: any) => {
    const { ipcId, data, error } = response;

    const observer = this.observers.get(ipcId);

    if (!observer) {
      return;
    }
    if (data) {
      observer.next({ data });
    }
    if (error) {
      observer.error(deserializeError(error));
    } else {
      observer.complete();
    }
    this.observers.delete(ipcId);
  };

  public dispose() {
    this.ipc.removeListener(this.channel, this.onResponse);
  }
}

export default IpcLink;
