import '@formatjs/intl-relativetimeformat/dist/locale-data/en';
import '@formatjs/intl-relativetimeformat/dist/locale-data/fr';
import '@formatjs/intl-relativetimeformat/polyfill';
import './fonts/fonts.css';
import './style.scss';
import { useQuery } from '@apollo/react-hooks';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { IntlProvider } from 'react-intl';
import { SharePlaceLoading } from './commons/SharePlaceLoading';
import { useMeConsumer } from './commons/UseMeConsumer';
import AppError, { AppErrors } from './components/AppError';
import { GET_USER_LANG_WHILE_NOT_CONNECTED } from './gql/local/userLangIfNotConnected/query';
import localeData from './locales/data.json';
import { AppRouter } from './routers';
import { sendMessageToIpcMain } from './services/electronUtils';
import { isProd } from './services/utils';
import { rootStore } from './store';

declare let window: Window & { Intercom: any };
declare let navigator: Navigator & { userLanguage: string };

const token = localStorage.getItem('access_token');

let error: AppErrors | null = null;

const MainApp = observer(() => {
  const { data: notConnectedUserData } = useQuery(
    GET_USER_LANG_WHILE_NOT_CONNECTED
  );
  const {
    data: meData,
    loading: meLoading,
    error: meError,
  } = useMeConsumer(true, 'cache-first', !token);

  const [firstSession, setFirstSession] = useState(true);
  const isProdEnv = isProd();
  const isLogin = window.location.pathname.match(/(login\/|connexion\/)$/);

  useEffect(() => {
    const tag = process.env.TAG_BUILD as string;
    if (tag && tag !== '') {
      sendMessageToIpcMain('tag-build', tag);
    }
    if (isProdEnv) {
      TagManager.initialize({
        gtmId: 'GTM-PKQF6PL',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let language = 'en-US';
  let messages = null;

  useEffect(() => {
    if (meData && meData.me && firstSession) {
      const { id: userId, email: userEmail } = meData.me;

      // fix because some of the app still uses @userID instead of apollo cache
      localStorage.setItem('@userId', userId.toString());

      if (isProdEnv) {
        window.Intercom('boot', {
          app_id: 'zocofwq7',
          vertical_padding: 20,
          user_id: meData.me.id,
          alignment: 'right',
          name: `${meData.me.firstName} ${meData.me.lastName}`, // Full name
          email: userEmail, // Email address
          created_at: meData.me.createdAt, // Signup date
        });
      }

      if ('Notification' in window && Notification.permission !== 'denied') {
        Notification.requestPermission(() => null);
      }
      setFirstSession(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meData, language]);
  if (meLoading) {
    return (
      <SharePlaceLoading
        style={{
          width: '100vw',
          height: '100vh',
        }}
      />
    );
  }

  // Handle error
  if (meError) {
    if (meError.graphQLErrors && meError.graphQLErrors.length) {
      const firstError = meError.graphQLErrors[0].message;
      if (firstError === 'NOT_AUTHENTICATED' && rootStore.connected) {
        localStorage.clear();
        location.reload();
        return null;
      }
    } else if (meError.networkError) {
      error = AppErrors.SERVER_UNREACHABLE;
    }
  } else if (
    meData &&
    token &&
    (!meData.me || !Array.isArray(meData.me.places)) &&
    !isLogin
  ) {
    error = AppErrors.INTERNAL_SERVER_ERROR;
  }

  if (meData?.me) {
    const { language: userLangage } = meData.me;
    language =
      userLangage ||
      (navigator.languages ? navigator.languages[0] : navigator.language) ||
      navigator.userLanguage;
  } else {
    const userLanguage =
      notConnectedUserData &&
      notConnectedUserData.notConnectedUserLang &&
      notConnectedUserData.notConnectedUserLang.language;

    if (location && location.pathname) {
      const { pathname } = location;
      if (pathname.includes('signup')) {
        language = 'en-US';
      } else if (pathname.includes('inscription')) {
        language = 'fr-FR';
      }

      language = notConnectedUserData.notConnectedUserLang.isChanged
        ? userLanguage
        : (navigator.languages ? navigator.languages[0] : navigator.language) ||
          navigator.userLanguage ||
          userLanguage;
    }
  }

  // Split locales with a region code
  const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

  // Try full locale, try locale without region code, fallback to 'en'
  messages =
    localeData[languageWithoutRegionCode] ||
    localeData[language] ||
    localeData.en;

  const localLanguage = languageWithoutRegionCode === 'fr' ? 'fr-FR' : 'en-US';

  return (
    <>
      <IntlProvider locale={localLanguage} messages={messages as any}>
        {error ? <AppError error={error} /> : <AppRouter />}
      </IntlProvider>
    </>
  );
});

export default MainApp;
