import gql from 'graphql-tag';

export const FETCH_MORE_FOLDER_LOADING = gql`
  query FETCH_MORE_FOLDER_LOADING($id: Int!, $isInRecycle: Boolean!) {
    fetchMoreFolderLoading(id: $id, isInRecycle: $isInRecycle) @client {
      id
      isLoading
    }
  }
`;
