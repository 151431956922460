import gql from 'graphql-tag';

export const GET_USER_LANG_WHILE_NOT_CONNECTED = gql`
  {
    notConnectedUserLang @client {
      language
      isChanged
    }
  }
`;
