/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-underscore-dangle */
import {
  defaultDataIdFromObject,
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { DefaultOptions } from 'apollo-client/ApolloClient';
import { ApolloLink, split } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
// eslint-disable-next-line import/no-extraneous-dependencies
import { getMainDefinition } from 'apollo-utilities';
import { useEffect, useState } from 'react';
import { defaults } from './apollo/defaults';
import { resolvers } from './apollo/resolvers';
import { typeDefs } from './apollo/typeDefs';
import { config } from './common/config';
import { introspectionQueryResultData } from './fragmentTypes';
import { APP_VERSION, isSupportedVersion } from './services/electronUtils';

const { isDev } = config;
const noSubVersion = process.env.NO_WS_ELECTRON_VERSION;
const predevApiUri = process.env.DUMMY_WS_ENDPOINT;

export const useClient = (isElectron = false, loggedIn = false) => {
  const [client, setClient] = useState<ApolloClient<any> | undefined>(
    undefined
  );
  const [wsLink, setWsLink] = useState<WebSocketLink | undefined>(undefined);

  useEffect(() => {
    const fragmentMatcher = new IntrospectionFragmentMatcher({
      introspectionQueryResultData: { ...introspectionQueryResultData },
    });

    const cache: InMemoryCache & { readQueryClone?: any } = new InMemoryCache({
      fragmentMatcher,
      dataIdFromObject: (object: any) => {
        // eslint-disable-next-line no-underscore-dangle
        switch (object.__typename) {
          case 'TopicParticipant':
            return `topicId:${object.topicId}:userId:${object.userId}`;
          case 'MessageVersion':
            return `topicId:${object.topicId}:version:${object.version}`;
          case 'Discussion':
            return object.id;
          default:
            return defaultDataIdFromObject(object); // fall back to default handling
        }
      },
      cacheRedirects: {
        Query: {
          topic: (_, { id }, { getCacheKey }) =>
            getCacheKey({ __typename: 'Discussion', id }),
          place: (_, { id }, { getCacheKey }) =>
            getCacheKey({ __typename: 'Place', id }),
          user: (_, { id }, { getCacheKey }) =>
            getCacheKey({ __typename: 'User', id }),
          folder: (_, { id }, { getCacheKey }) =>
            getCacheKey({ __typename: 'Folder', id }),
        },
      },
      freezeResults: true,
    });

    cache.readQueryClone = cache.readQuery;

    cache.readQuery = (...args) => {
      try {
        return cache.readQueryClone(...args);
      } catch (err) {
        return undefined;
      }
    };

    cache.writeData({
      data: {
        ...defaults,
      },
    });

    const token = localStorage.getItem('access_token');

    const { graphqlUrl, subscriptionUrl } = config.servers;

    const httpLink = new HttpLink({
      uri: graphqlUrl,
    });

    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : '',
        },
      };
    });

    const wsUri =
      isElectron && APP_VERSION === noSubVersion
        ? predevApiUri
        : subscriptionUrl;

    const webSocketLink = new WebSocketLink({
      uri: wsUri || subscriptionUrl,
      options: {
        reconnect: true,
        connectionParams: {
          authToken: token || '',
        },
        timeout: 90000,
      },
    });

    let link: ApolloLink;

    if (isElectron && isSupportedVersion() && !isDev) {
      // eslint-disable-next-line no-console
      console.info('You use the version worker');
      const { IpcLink } = require('./apolloUtils');
      link = ApolloLink.from([
        split(
          ({ query }) => {
            const { kind, operation } = getMainDefinition(query) as {
              kind: string;
              operation: string;
            };
            return (
              kind === 'OperationDefinition' && operation === 'subscription'
            );
          },
          webSocketLink,
          new IpcLink({ authToken: token })
        ),
      ]);
    } else {
      link = ApolloLink.from([
        split(
          ({ query }) => {
            const { kind, operation } = getMainDefinition(query) as {
              kind: string;
              operation: string;
            };
            return (
              kind === 'OperationDefinition' && operation === 'subscription'
            );
          },
          webSocketLink,
          authLink.concat(httpLink)
        ),
      ]);
    }

    // TODO: ENLEVER 'defaultOptions' ET UTILISER LE CACHE APOLLO
    const defaultOptions: DefaultOptions = {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
      },
      query: {
        // @ts-ignore
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
    };

    const clientApollo = new ApolloClient({
      link,
      cache,
      defaultOptions,
      queryDeduplication: true,
      assumeImmutableResults: false,
      resolvers: resolvers as any,
      typeDefs,
    });

    setClient(clientApollo);
    setWsLink(webSocketLink);
  }, [isElectron, loggedIn]);

  return {
    client,
    wsLink,
  };
};

export default useClient;
