import gql from 'graphql-tag';

export const PLACE_FRAGMENT = gql`
  fragment placeInfos on Place {
    id
    name
    icon
    icon_color
    icon_id
    header_color
    header_image
    header_image_id
    boxStorage
    boxFolderId
    created_at
    isNotifTopicEnable
    isNotifCommentEnable
    isNotifFileEnable
    isNotifOperationEnable
    isNotifOnInviteToFolderEnable
    isFavoritePlace
    filterFollowUnread
    filterPrivateUnread
    hasDueDate
    boxAccessToken
    unread
    hasMention
    isNewUser
    ownerId
    adminId
    onBoardingStep
    isTraceable
    isFileExpanded
    isFolderMuteHidden
    isFavoriteFolderHidden
    isOwner
    isDoneTodoHidden
    filterMentionUnread
    filterMentionPriority
    filterRecentUnread
    filterRecentPriority
    email
    dbxUrlPath
    admin {
      id
      firstName
      lastName
      email
      avatar
    }
    ownerId
    adminId
    sortDiscussionType {
      key
      value
    }
    orderPlace
    myFolderId
    inviteeFolder
    removed
    inviteUser {
      id
      firstName
      lastName
      email
      avatar
    }
  }
`;

export const MESSAGE_FILE_FRAGMENT = gql`
  fragment MessageFile on MessageFile {
    id
    name
    type
    extension
    updateAt
    path
    url
    discussion {
      id
      title
      lockedAt
      folderId
      folder {
        id
        name
        myRole
        permissions {
          userId
          role {
            id
            name
          }
        }
      }
      fileLockedId
      lockedBy {
        id
        avatar
      }
    }
  }
`;

export const PLACE_NOTIFICATIONS_FRAGMENT = gql`
  fragment PlaceNotificationsFragment on Place {
    id
    unread
    hasMention
  }
`;
